import React, {useState, useEffect, useMemo} from 'react'
import "./manageProject.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import api from '../../redux/APIS/api';
import DynamicTable from '../../components/dynamic-table/DynamicTable';
import moment from 'moment';
import dataService from '../../redux/services/data.service';
import { getGridSingleSelectOperators } from '@mui/x-data-grid';
import Filter from '../../components/Filter';
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const { REACT_APP_ENV } = process.env;

const MyProjects = () => {

  const navigate=useNavigate()
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const [projectRows, setProjectRows] = useState([])
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})

  const [resourcesOptions, setResourcesOptions] = useState([])
   // custom filter
   const [setFieldName] = useState("")
   const [defaultValue, setDefaultValue] = useState({})

  const projectsColumns= 
    [
      { 
        field: "id", 
        headerName: "ID", 
        width: 70,
        filterable: false,
      },
      {
        field: "project_name",
        headerName: "Project",
        hideable: false,
        // filterable:false,
        // sortable:false,

        width: 200,
          renderCell: (params) => {
            return (
              <div className="cellWithImg">
                {params.row.project_name}
              </div>
              );
          },
      },
      {
        field: "project_key",
        headerName: "Project Key",
        filterable:false,
        width: 150,
        // filterable:false,
        // sortable:false,
      },
    
      {
        field: "is_jira_project_text",
        headerName: "Is Jira Project",
        filterable:false,
        width: 150,
        // sortable:false,
      },
    
      {
        field: "is_jira_project",
        headerName: "is_jira_project",
        filterable:false,
        // sortable:false,
        width: 0,
      },
      {
        field: "resource",
        headerName: "Asignees",
        width: 380,
        type: 'singleSelect',
        filterOperator:[],
        valueOptions: resourcesOptions,
          renderCell: (params) => {
            
            return (
              <div className="cellWithImg">   
                  { params.row.projectsResourcesNames && params.row.projectsResourcesNames.length ?
                    params.row.projectsResourcesNames.map((resource, index)=>{
                      return(
                          <p key={index} style={{ margin: 0 }}> 
                            {index ===0 && resource} {index ===0 && params.row.projectsResourcesNames.length >1 && ","}
                            {params.row.projectsResourcesNames.length >1 && index ===1 && resource} {index ===1 && params.row.projectsResourcesNames.length >2 && ","}
                          </p> 
                      )
                    })
                    :""
                  }
                  {(params.row.projectsResourcesNames && params.row.projectsResourcesNames.length >2 )? `..[+${params.row.projectsResources.length-2}]` : ""} 
    
              </div>
              );
            },
      },
    
      {
        field: "created_on",
        headerName: "Created On",
        filterable:false,
        // sortable:false,
        width: 120,
        renderCell: (params) => {
          return (
            <div className="cellWithImg">
              {moment(params.row.created_on).format('DD-MM-YYYY')}
            </div>
            );
          },
      },
      {
        field: "status_value",
        headerName: "Status",
        filterable:false,
        width: 100,
        renderCell: (params) => {
          return (
            <div className={`cellWithStatus ${params.row.status}`}>
              {params.row.status === 3 ? "Archive" :params.row.status === true ? "Active" : "Inactive"}
            </div>
          );
        },
      },
    ];


  // Load All Projects
  const loadProjects= async () => { 
    setLoading(true);
    const resourcesList= await loadProjectResources();
      const data={...params, filter:{...params.filter, "status":{"value":"True","operation":"eq"},"resource":{"value":userId,"operation":"eq"}},"skip":skip,  no_limit:"True"}
      if(resourcesList){
      const res=await api.get(`/resources/resource-project?where=${JSON.stringify(data)}`)
        if(res && res.data && res.data.data){
          let resData=res.data.data.rows
          setProjectRows(resData.map((row)=> (
            {...row, 
              id:row.project.id,

              is_jira_project_text:row.project.is_jira_project ? "Yes" : "No",
              status_value:row.project.status ? "Active" : "Inactive",
              project_name:row.project.project_name,
              project_key:row.project.project_key,
              project_type_name:row.project.project_type_name,
              is_jira_project:row.project.is_jira_project,
              is_private:row.project.is_private,
              status:row.project.status,
              description:row.project.description,
              projectsResources:resourcesList.filter((element)=>element.project.id===row.project.id ),
              projectsResourcesNames:resourcesList.map((element)=> {return element.project.id===row.project.id && element.resource.name}).filter(e=>e!==false),
            }
              )))
          setRowCount(res.data.data.count)
        }
      }
    setLoading(false);

  }

  // Load Assigned Resources to Projects

  const loadProjectResources =async () => { 
    //  Load all Project Resources
      const qParam={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":"True"};
      const res=await api.get(`/resources/resource-project?where=${JSON.stringify(qParam)}`)
      if(res && res.data && res.data.data && res.data.data.rows){
          const resourceArray=res.data.data.rows
          return resourceArray
      }
      return [];
  
   }

   //  LoadResourceList
   const getResources = async () => {
    const res= await dataService.resources_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray=res.data.data.rows.map(e => { return { value: e.id, label: e.name+"("+e.email+")" } })
      setResourcesOptions(tempArray)
    }
   }

  // View Project
  const viewProject=(data) => { 
     navigate('/view-project', { state: {id:data.id} })
  }


  useEffect(() => {
    loadProjects();
  }, [skip, pageSize, params])

  useEffect(() => {
    getResources();
  }, [])
  
      
  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
        <Filter options={resourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
    );
  }
  const columns = useMemo(() =>
    projectsColumns.map((col) => {
      if (col.field === "resource") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
    }),
    [projectsColumns]
  );
    return (
      <div className="new">
        <Sidebar />

        <div className="newContainer">
            <Navbar />

            <div className="top-headings">
                <h3>My Projects</h3>
                <div>
                <Tooltip title="Back">
                    <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                                   
                </div>
                  
            </div>
            {/* <ToastContainer /> */}
          { 
            <>
              { 
                <DynamicTable 
                    tableColumns={columns} 
                    tableRows={projectRows || []} 
                    paginationMode={"client"} 
                    rowCount={rowCount} 
                    loading={loading}
                    currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                    setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams} 
                    onViewDetail={viewProject} 
                    columnVisibilityModel={{
                      id:false,
                      status:false,
                      is_jira_project:false,
                    }}
                    setFieldName={setFieldName}
                    setDefaultValue={setDefaultValue}
                />
              }
            </>
          }
        </div>
      </div>
    )
}

export default MyProjects
