import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoaderForm from "../../components/common-function/LoaderForm";
import { employTypeList, departmentTypeList, tmsRoleList, resourceCreate, resourceUpdateById, allResourcesListEdit } from "../../redux/features/resourceSlice";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import DataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import CONSTANTS from "../../components/constant/constantComponents";
import { STATUSES } from "../../redux/common-status/constant";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from 'react-select';

const initialFormData = Object.freeze({
    r_name: "",
    display_name: "",
    email: "",
    contact_no: "",
    //date_of_birth: new Date(),
    tms_role: "",
    department: "",
    //jira_account: "",
    alternternate_contact: null,
    //doj: new Date(),
    address: "",
    employment_type: "",
    gender: "",
    emp_code: "",
    password: "",
    reporting_manager: "",
    agent_id: "",
    cug_ph_no: ""
});

const formErrData = Object.freeze({
    r_nameErr: "Name is required",
    display_nameErr: "Display name is required",
    emailErr: 'Email required',
    contact_noErr: "Contact is required",
    tms_roleErr: "Role is required",
    departmentErr: "Department is required",
    addressErr: "Address is required",
    employment_typeErr: "Employ type is required",
    genderErr: "Gender is required",
    emp_codeErr: "Emp code is required",
    passwordErr: "Password is required",
    reporting_managerErr: "Manager is required",
});

const ResourceEdit = ({ resId }) => {

    const dispatch = useDispatch()
    const { resourceid } = useParams()
    const navigate = useNavigate()
    const { view_data, status, error, empType, deptType, tmsRoles, createResource, updateResource, activeResourceList } = useSelector(state => state.resource)
    const [formValues, setFormValues] = useState(initialFormData)
    const [dob, setDobDate] = useState(new Date());
    const [doj, setDojDate] = useState(new Date());
    const [loader, setLoader] = useState(false)
    const [formErr] = useState(formErrData)
    // const resourceid = resId ? resId : (location && location.state && location.state.resourceid) ? location.state.resourceid : "";
    const [errSatus, setErrSatus] = useState(false);
    const [validateErr, setValidateErr] = useState("")

    const loadData = async () => {
        const res = await DataService.resource_view(resourceid)
        if (res && res.data && res.data.data) {
            const editData = res.data.data
            setFormValues({
                r_name: editData && editData.name,
                display_name: editData && editData.display_name,
                email: editData && editData.email,
                contact_no: editData && editData.contact_no,
                // tms_role: editData && editData.tms_role && editData.tms_role.id,
                tms_role: { label: editData.tms_role && editData.tms_role.role_name, value: editData && editData.tms_role_id },
                // department: editData && editData.department && editData.department.id,
                department: { label: editData.department && editData.department.name, value: editData && editData.department_id },

                //jira_account: editData && editData.jira_account,
                alternternate_contact: editData && editData.alternternate_contact,
                address: editData && editData.address,
                // employment_type: editData && editData.employment_type && editData.employment_type.id
                employment_type: { label: editData && editData.employment_type && editData.employment_type.employment_type, value: editData && editData.employment_type_id },
                gender: editData && editData.gender,
                emp_code: editData && editData.emp_code,
                password: editData && editData.password,
                user: editData && editData.user_id,
                // reporting_manager: editData && editData.reporting_manager
                reporting_manager: { label: editData && editData.reporting_manager_name, value: editData && editData.reporting_manager },
                agent_id: editData && editData.agent_id,
                cug_ph_no: editData && editData.cug_ph_no
            })
            let new_dob_date = editData && editData.date_of_birth
            let date_of_birth = new_dob_date ? moment.utc(new_dob_date) : ""
            let new_doj_date = editData && editData.doj
            let date_of_join = new_doj_date ? moment.utc(new_doj_date) : ""
            if (date_of_join) {
                setDobDate(date_of_birth._d)
            }
            if (date_of_join) {
                setDojDate(date_of_join._d)
            }

        }
    }

    useEffect(() => {
        if (resourceid) {
            loadData()
        }
        dispatch(employTypeList())
        dispatch(departmentTypeList())
        dispatch(tmsRoleList())
        dispatch(allResourcesListEdit())
        if (view_data && view_data.data) {
            setFormValues(loadEditResourceData)

        }
    }, [dispatch])

    const handleCalendarClose = () => { }//console.log("Calendar closed");
    const handleCalendarOpen = () => { }//console.log("Calendar opened");

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    }


    const validate = (email, contact, alternternate_contact) => {
        const errors = {}

        if (!email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            errors.email = true
        }

        // if (!jira_account) {
        //     errors.jira_account = true
        // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(jira_account)) {
        //     errors.jira_account = 'Invalid email address'
        // } else {
        //     errors.jira_account = true
        // }

        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10) {
            errors.contact = "Please enter valid  Mobile Number.";
        } else {
            errors.contact = true
        }

        if (!alternternate_contact) {
            errors.alternternate_contact = true
        } else if (!pattern.test(alternternate_contact)) {
            errors.alternternate_contact = "Please Enter Number Only";
        } else if (alternternate_contact.length !== 10) {
            errors.alternternate_contact = "Please enter valid  Mobile Number.";
        } else if (alternternate_contact === contact) {
            errors.alternternate_contact = "Contact and Alternate Number are the Same";
        } else {
            errors.alternternate_contact = true
        }


        return errors
    }

    const submitData = (e) => {
        e.preventDefault()

        if ((formValues.r_name === "" || formValues.display_name === "" || formValues.address === "" || formValues.department === "" ||
            formValues.email === "" || formValues.gender === "" || formValues.emp_code === "" || formValues.tms_role === "" || formValues.password === "" || formValues.reporting_manager === "") && formValues.address === "") {
            setErrSatus(true)
        }

        if (formValues.r_name !== "" && formValues.display_name !== "" && formValues.address !== "" && formValues.department !== "" &&
            formValues.email !== "" && formValues.gender !== "" && formValues.emp_code !== "" && formValues.tms_role !== "" && formValues.password !== "" && formValues.reporting_manager !== "") {
            const isValidate = validate(formValues.email, formValues.contact_no, formValues.alternternate_contact)

            if (isValidate.email === true && isValidate.contact === true) {
                if (formValues.alternternate_contact !== '') {
                    if (isValidate.alternternate_contact !== true) {
                        setErrSatus(true)
                        setValidateErr(isValidate)
                    } else {
                        let addvalues = {
                            ...formValues, doj: moment(doj).format('YYYY-MM-DD'), date_of_birth: moment(dob).format('YYYY-MM-DD'), name: formValues.r_name,
                            tms_role: formValues.tms_role.value ? formValues.tms_role.value : formValues.tms_role,
                            department: formValues.department.value ? formValues.department.value : formValues.department,
                            employment_type: formValues.employment_type.value ? formValues.employment_type.value : formValues.employment_type,
                            reporting_manager: formValues.reporting_manager.value ? formValues.reporting_manager.value : formValues.reporting_manager
                        }
                        delete addvalues.r_name;
                        setErrSatus(false)
                        setValidateErr("")
                        setLoader(true)
                        if (resourceid) {
                            dispatch(resourceUpdateById({ id: resourceid, addvalues: addvalues }))
                        } else {
                            dispatch(resourceCreate(addvalues))
                        }
                    }
                } else {
                    //let addvalues = { ...formValues, doj: moment(doj).format('YYYY-MM-DD'), date_of_birth: moment(dob).format('YYYY-MM-DD'), name: formValues.r_name }
                    let addvalues = {
                        ...formValues, doj: moment(doj).format('YYYY-MM-DD'), date_of_birth: moment(dob).format('YYYY-MM-DD'), name: formValues.r_name,
                        tms_role: formValues.tms_role.value ? formValues.tms_role.value : formValues.tms_role,
                        department: formValues.department.value ? formValues.department.value : formValues.department,
                        employment_type: formValues.employment_type.value ? formValues.employment_type.value : formValues.employment_type,
                        reporting_manager: formValues.reporting_manager.value ? formValues.reporting_manager.value : formValues.reporting_manager
                    }
                    delete addvalues.r_name;
                    setErrSatus(false)
                    setValidateErr("")
                    setLoader(true)
                    if (resourceid) {
                        dispatch(resourceUpdateById({ id: resourceid, addvalues: addvalues }))
                    } else {
                        dispatch(resourceCreate(addvalues))
                    }
                }


            } else {
                setErrSatus(true)
                setValidateErr(isValidate)
            }


        }

    }

    if (loader === true && status === STATUSES.FAILURE && error && error.message) {
        setLoader(false)
        toast.error(error.message);
    }

    if (loader === true && status === STATUSES.SUCCESS && createResource && createResource.message) {

        if (createResource.status === 200) {
            toast.success(createResource.message);
        } else {
            toast.error(createResource.message)
        }
        setLoader(false)
        setTimeout(() => {
            navigate('/all-resource')
        }, 1000)
    }

    if (loader === true && updateResource && updateResource.message) {
        if (updateResource.status === 200) {
            toast.success(updateResource.message);
        } else {
            toast.error(updateResource.message)
        }
        setLoader(false)
        setTimeout(() => {
            // loadData()
            navigate('/all-resource')
        }, 2000)
    }

    const goback = () => {
        if (resId) {
            navigate('/profile')
        } else {
            navigate('/all-resource')
        }
    }

    const loadEditResourceData = () => {
        const data = view_data.data
        const editFormValues = {
            r_name: data && data.name,
            display_name: data && data.display_name,
            email: data && data.email,
            contact_no: data && data.contact_no,
            tms_role: data && data.tms_role && data.tms_role.id,
            department: data && data.department && data.department.id,
            //jira_account: data && data.jira_account,
            alternternate_contact: data && data.alternternate_contact,
            address: data && data.address,
            employment_type: data && data.employment_type && data.employment_type.id,
            gender: data && data.gender,
            emp_code: data && data.emp_code,
            password: data && data.password,
            dob: data && moment(data.date_of_birth),
            doj: data && moment(data.doj),
            reporting_manager: data && data.reporting_manager
        }
        return editFormValues
    }


    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>{resId ? "Edit Profile" : resourceid ? "Edit Resource" : "Add new Resource"}</h3>

                    <Tooltip title="Back">
                        <button onClick={goback} className="btn btn-add-new"><ArrowBackIcon /></button>
                    </Tooltip>
                </div>
                <ToastContainer />
                {loader === true && <LoaderForm />}
                {status === STATUSES.SUCCESS || status === STATUSES.FAILURE ?
                    <div>
                        {(formValues.email || !resourceid) ?
                            <form>
                                <div className="add-new-user-form">
                                    <div className="formInput">
                                        <label >
                                            Name
                                        </label>
                                        <input
                                            name="r_name"
                                            defaultValue={formValues.r_name}
                                            type="text"
                                            placeholder="Name"
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.r_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.r_nameErr}</p>}
                                    </div>

                                    <div className="formInput">
                                        <label >
                                            Diaplay Name
                                        </label>
                                        <input
                                            name="display_name"
                                            defaultValue={formValues.display_name}
                                            type="text"
                                            placeholder="Dispaly Name"
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.display_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.display_nameErr}</p>}
                                    </div>

                                    <div className="formInput">
                                        <label >
                                            Date of Birth
                                        </label>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            name="date_of_birth"
                                            selected={dob}
                                            onChange={(dob) => setDobDate(dob)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            onCalendarClose={handleCalendarClose}
                                            onCalendarOpen={handleCalendarOpen}
                                        />
                                    </div>


                                    <div className="formInput">
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                            <RadioGroup
                                                value={formValues.gender}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="gender"
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="F" control={<Radio />} label="Female" />
                                                <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                <FormControlLabel value="O" control={<Radio />} label="Other" />

                                            </RadioGroup>
                                        </FormControl>
                                        {errSatus === true && formValues && formValues.gender === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.genderErr}</p>}
                                    </div>

                                    <div className="formInput">
                                        <label >
                                            Email
                                        </label>
                                        <input
                                            name="email"
                                            type="email"
                                            defaultValue={formValues.email}
                                            placeholder="Email"
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.email === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.emailErr}</p>}
                                        {errSatus === true && validateErr && validateErr.email !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.email}</p>}
                                    </div>



                                    <div className="formInput">
                                        <label >
                                            Contact No
                                        </label>
                                        <input
                                            name="contact_no"
                                            defaultValue={formValues.contact_no}
                                            type="text"
                                            placeholder="Phone"
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.contact_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.contact_noErr}</p>}
                                        {errSatus === true && validateErr && validateErr.contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.contact}</p>}
                                    </div>
                                    <div className="formInput">
                                        <label >
                                            Alternative Contact No
                                        </label>
                                        <input
                                            name="alternternate_contact"
                                            defaultValue={formValues.alternternate_contact}
                                            type="text"
                                            placeholder="Alt. Phone"
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && validateErr && validateErr.alternternate_contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.alternternate_contact}</p>}

                                    </div>

                                    <div className="formInput">
                                        <label >
                                            TMS Role
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            defaultValue={formValues.tms_role}
                                            isSearchable={true}
                                            name={"tms_role"}
                                            options={
                                                tmsRoles && tmsRoles.data && tmsRoles.data.rows.map((option) => {
                                                    return { label: option.role_name, value: option.id, name: "tms_role" }
                                                })
                                            }
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.tms_role === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.tms_roleErr}</p>}

                                    </div>

                                    <div className="formInput">
                                        <label >
                                            Department
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            defaultValue={formValues.department}
                                            isSearchable={true}
                                            name={"department"}
                                            options={
                                                deptType && deptType.data && deptType.data.rows.map((option) => {
                                                    return { label: option.name, value: option.id, name: "department" }
                                                })
                                            }
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                    </div>


                                    <div className="formInput">
                                        <label >
                                            Date of Joinig
                                        </label>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            name="doj"
                                            selected={doj}
                                            onChange={(doj) => setDojDate(doj)}
                                            onCalendarClose={handleCalendarClose}
                                            onCalendarOpen={handleCalendarOpen}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                        />

                                    </div>
                                    <div className="formInput">
                                        <label >
                                            Employment Type
                                        </label>

                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            defaultValue={formValues.employment_type}
                                            isSearchable={true}
                                            name={"employment_type"}
                                            options={
                                                empType && empType.data && empType.data.rows.map((option) => {
                                                    return { label: option.employment_type, value: option.id, name: "employment_type" }
                                                })
                                            }
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.employment_type === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.employment_typeErr}</p>}

                                    </div>

                                    <div className="formInput">
                                        <label >
                                            Employee Code
                                        </label>

                                        <input
                                            name="emp_code"
                                            type="text"
                                            defaultValue={formValues.emp_code}
                                            placeholder="Emp. Code"
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.emp_code === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.emp_codeErr}</p>}

                                    </div>
                                    <div className="formInput">
                                        <label >
                                            Password
                                        </label>

                                        <input
                                            name="password"
                                            defaultValue={formValues.password}
                                            type="password"
                                            placeholder="password"
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.password === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.passwordErr}</p>}

                                    </div>

                                    <div className="formInput">
                                        <label >
                                            Manager
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            defaultValue={formValues.reporting_manager}
                                            isSearchable={true}
                                            name={"reporting_manager"}
                                            options={
                                                activeResourceList && activeResourceList.data && activeResourceList.data.rows.map((option) => {
                                                    return { label: option.display_name + "(" + option.email + ")", value: option.id, name: "reporting_manager" }
                                                })
                                            }
                                            onChange={handleChange}
                                        />
                                        {errSatus === true && formValues && formValues.reporting_manager === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.reporting_managerErr}</p>}
                                    </div>
                                    <div className="formInput">
                                        <label >
                                            Agent ID
                                        </label>

                                        <input
                                            name="agent_id"
                                            defaultValue={formValues.agent_id}
                                            type="text"
                                            placeholder="Agent ID"
                                            onChange={handleChange}
                                        />

                                    </div>
                                    <div className="formInput">
                                        <label >
                                            Cug Phone Number
                                        </label>

                                        <input
                                            name="cug_ph_no"
                                            defaultValue={formValues.cug_ph_no}
                                            type="text"
                                            placeholder="Cug Phone Number"
                                            onChange={handleChange}
                                        />

                                    </div>

                                    <div className="formInput" style={{ flexBasis: "65%" }}>
                                        <label >
                                            Address
                                        </label>

                                        <textarea defaultValue={formValues.address} name="address" onChange={handleChange} rows="3" placeholder="Full Address here"></textarea>

                                        {errSatus === true && formValues && formValues.address === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.addressErr}</p>}
                                    </div>
                                </div>
                                {error && <p style={{ color: "#96332c", fontSize: 13 }}> {error.message}</p>}
                                <button onClick={submitData} className="btn btn-submit">{resourceid ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                            </form>
                            : ""}

                    </div>
                    :
                    <LoaderForm />
                }
            </div>
        </div>

    )

}

export default ResourceEdit