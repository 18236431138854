// import Navbar from "../../components/navbar/Navbar"
// import Sidebar from "../../components/sidebar/Sidebar"
import './resume.scss'
//import { DataGrid } from "@mui/x-data-grid";
import { ResumeParseColumns } from "../../components/datatable-coulms/tableCoulmns";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
//import CONSTANTS from "../../components/constant/constantComponents";
import { useDispatch, useSelector } from "react-redux";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { DefaultDataGrid } from "../../components/common-function/DefaultDataGrid";
import { getResumeList } from "../../redux/features/resumeSlice";
import { STATUSES } from "../../redux/common-status/constant";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import ResumeEdit from "./ResumeEdit";
import { Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";


const ResumeList = () => {
    // const [list, setList] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { resumeList, status, error } = useSelector(state => state.resume)
    const [editForm, setEditForm] = useState(false)

    useEffect(() => {
        dispatch(getResumeList(`{"filter":{"status":{"value":6, "operation":"eq"}}, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }`))
    }, [])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            errorHandling(error, navigate)
        }
    }, [error])

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            moduleName="resume"
                            rowid={params.row.id}
                            addMoreData={params.row}
                            editLinkUrl={false}
                            //editLinkUrl={`/resume-list/${params.row.id}`}
                            viewLinkUrl={false}
                            isDelete={false}
                            editButton={openEditForm}
                            viewButton={false} />
                    </div>
                );
            },
        },
    ];
    const openEditForm = (data) => {
        setEditForm(data.id)
    }

    return (
        // <div className="resource">
        //     <Sidebar />
        //     <div className="resourceContainer">
        //         <Navbar />
        editForm !== false ?
            <div className="datatable">
                <div className="top-headings">
                    <h3>Update Form</h3>
                    <Tooltip title="Back">
                        <Close onClick={()=>setEditForm(false)}/>
                    </Tooltip>
                </div>
                <ResumeEdit resumeID={editForm} />
            </div>
            :
            <div className="datatable">
                {/* <div className="top-headings">
                    <h3>Resume List</h3>
                </div> */}
                <DefaultDataGrid
                    style={{ marginTop: "20px", height: "unset" }}
                    list={(resumeList && resumeList.data && resumeList.data.rows) || []}
                    count={(resumeList && resumeList.data && resumeList.data.count) || 0}
                    columns={ResumeParseColumns.concat(actionColumn)}
                    defaultApi={getResumeList}
                    loading={status === STATUSES.LOADING ? true : false}
                    defaultFilter={{ "filter": { "status": { "value": 6, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                />
            </div>

        //     </div>
        // </div>
    )
}

export default ResumeList