import React, { useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { InputFields } from '../common-function/InputFields';
import { updateCandidateContactNumber } from '../../redux/features/callingSlice';
import { toast } from 'react-toastify';
import { Close, Phone } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

export default function AddCandidatePhoneNumber({ showPhone, handleClose, storyId, setReload }) {

    const dispatch = useDispatch()
    const [destinationNumber, setDestinationNumber] = useState('')

    const validate = (desination_number) => {
        const errors = {}
        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(desination_number)) {
            errors.desination_number = "Please Enter Number Only";
        } else if (desination_number.length !== 10) {
            errors.desination_number = "Please enter valid  Phone Number.";
        } else {
            errors.desination_number = true
        }
        return errors
    }

    const submitPhoneData = (evt) => {
        evt.preventDefault();
        if (destinationNumber === "") {
            return toast.error("Phone Number is required")
        }
        if (destinationNumber !== "") {
            const isValidate = validate(destinationNumber)
            if (isValidate.desination_number === true) {
                const addvalues = {
                    country_code: "91",
                    phone_number: destinationNumber,
                    story_id: storyId
                }
                setReload('submit')
                // setPhoneNumber(destinationNumber)
                dispatch(updateCandidateContactNumber(addvalues))
            } else {
                return toast.error(isValidate.desination_number)
            }
        }
    }
    return (
        <div>
            {/* form section */}
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"sm"}
                open={showPhone}
                // onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{
                    left: "68%",
                    height: "335px",
                    width: "500px",
                    top: "25px",
                }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'Add Candidate Phone Number'}</DialogTitle>
                <DialogContent>
                    <form>
                        <div className="add-new-user-form">
                            <div className='formInput' style={{ flexBasis: "100%" }}>
                                <InputFields
                                    label={"Enter Phone Number"}
                                    name="phone_number"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    onChange={(evt) => setDestinationNumber(evt.target.value)}

                                />
                            </div>
                        </div>

                        <Tooltip title="Call">
                            <button className="submit-modal"
                                style={{
                                    backgroundColor: "green", width: "70px",
                                    padding: "7px 17px",
                                }}
                                onClick={(e)=>submitPhoneData(e)}
                            >
                                <Phone />
                            </button>
                        </Tooltip>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
