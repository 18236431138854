import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { JobPostColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobPostArchive, jobPostCreate, jobPostEdit, jobPostUpdate, getJobPostList, getProjectMappingDomain } from "../../redux/features/jobPostSlice";
import { getAllIndustryList, getAllQualificationList, getAllSkillList } from "../../redux/features/masterSlice";
import { getResourceWiseProjectList, getTaskList } from "../../redux/features/taskSlice";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Close, RemoveCircleOutline, AddCircleOutline, Add } from "@mui/icons-material";
import { InputFields, InputMultiSelectField, InputRadioGroupFields, InputSelectField, InputEditorField, ViewField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import Select from 'react-select';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";

const initialFormData = Object.freeze({
    job_title: "",
    job_description: "",
    job_skill: "",
    work_mode: "",
    work_experience: "",
    preferred_industry: "",
    industry: "",
    education_qualification: "",
    other_skill: "",
    project_id: "",
    status: "",
    type: ""

});

const formErrData = Object.freeze({
    descriptionErr: "Description is required",
    job_titleErr: "Title is required",
    work_modeErr: "Work mode is required",
    work_experienceErr: "Work experience is required",
    project_idErr: "Project is required",
    industryErr: "Industry is required",
    education_qualificationErr: "Education qualification is required",
    job_skillErr: "Skills is required",
    typeErr: "Year/Month is required",
    domain_idErr: "Domain is required",
});


const JobPost = () => {
    const dispatch = useDispatch();
    const { jobId } = useParams()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, jobPost_List, jobPost_Create, jobPost_Edit, jobPost_Update, jobPost_Archive, mappingDomain_List } = useSelector(state => state.jobpost)
    const { skill_AllList, qualification_AllList, industry_AllList } = useSelector(state => state.master)
    const { taskList } = useSelector(state => state.task)
    const { resourceWiseProjectList, createNewProjectList } = useSelector(state => state.task)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [skills, setSkills] = useState([])
    const [other_skill, setOther_skill] = useState([])
    const [activeViewMode, setActiveViewMode] = useState(false)
    const [job_description, setJob_description] = useState('')
    const [epicActive, setEpicActive] = useState(false)
    const [createNewProject, setCreateNewProject] = useState(false)
    const [domainId, setDomainId] = useState('')


    useEffect(() => {
        if (jobId) {
            dispatch(jobPostEdit(jobId))
            setReload('edit_data')
        }
        dispatch(getJobPostList(filterQuery))
        dispatch(getAllIndustryList(`{"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getAllQualificationList(`{"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getAllSkillList(`{"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getResourceWiseProjectList(`{"filter":{"resource":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"},"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":10}`))
        dispatch(getProjectMappingDomain())
    }, [])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])

    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Update` && jobPost_Update && jobPost_Update.message) {
        if (jobPost_Update.status === 200) {
            toast.success(jobPost_Update.message)
            setOpen(false);
            setSkills([])
            setOther_skill([])
            setActiveViewMode(false)
            setFormValues(initialFormData);
            setCreateNewProject(false)
            dispatch(getJobPostList(filterQuery))
        } else {
            toast.error(jobPost_Update.message)
        }
        setReload(false)
    }
    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Create` && jobPost_Create && jobPost_Create.message) {
        if (jobPost_Create.status === 200) {
            toast.success(jobPost_Create.message)
            setOpen(false);
            setSkills([])
            setOther_skill([])
            setActiveViewMode(false)
            setFormValues(initialFormData);
            setCreateNewProject(false)
            dispatch(getJobPostList(filterQuery))
        } else {
            toast.error(jobPost_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Archive` && jobPost_Archive && jobPost_Archive.message) {
        if (jobPost_Archive.status === 200) {
            toast.success(jobPost_Archive.message)
            dispatch(getJobPostList(filterQuery))
        } else {
            toast.error(jobPost_Archive.message)
        }
        setReload(false)
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) {
            setID(0)
        }
        if (activeViewMode === true) {
            setActiveViewMode(false)
        } else {
            setOpen(false);
            setSkills([])
            setOther_skill([])
            setFormValues(initialFormData);
            setJob_description("")
        }
        setErrStatus(false)
        setCreateNewProject(false)
        setDomainId("")
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === 'project_id') {
            if (value === '-1') {
                setCreateNewProject(true)
            } else {
                setCreateNewProject(false)
            }
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const handleMultiIndustryChange = (evt) => {
        setFormValues({ ...formValues, preferred_industry: evt })
    }

    const handleMultiQualificationChange = (evt) => {
        setFormValues({ ...formValues, education_qualification: evt })

    }

    const handleSkillChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = skills.length > 0 && skills.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setSkills([...valueArray])
    }
    const handleOtherSkillChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = other_skill.length > 0 && other_skill.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setOther_skill([...valueArray])

    }


    const submitData = (e) => {
        e.preventDefault()
        if (formValues.job_title === "" || job_description === "" || formValues.job_skill === "" || formValues.work_mode === ""
            || formValues.education_qualification === "" || formValues.industry === "" || formValues.work_experience === "" || formValues.project_id === "" || formValues.type === "") {
            setErrStatus(true)
        }

        if (formValues.job_title && job_description && formValues.job_skill && formValues.work_mode &&
            formValues.education_qualification && formValues.industry && formValues.work_experience && formValues.project_id && formValues.type) {
            setErrStatus(false)
            findName()
            setActiveViewMode(true)
        }
    }

    const addMoreSkill = (evt) => {
        let array = []
        setFormValues({ ...formValues, job_skill: evt })
        evt.map(e => {
            let filterExp = skills.filter(x => x.skill_id === e.id)
            return array.push({
                skill: e.skill_name,
                skill_id: e.id,
                experience: filterExp.length > 0 ? filterExp[0].experience : "",
                experience_type: e.type,
                status: true
            })
        })

        setTimeout(() => {
            setSkills(array)
        }, 500);

    }

    const removeSkill = (i) => {
        const updateformData = skills.filter((item, index) => index !== i);
        const updateFormValues = formValues && formValues.job_skill.filter((item, index) => index !== i);
        setFormValues({ ...formValues, job_skill: updateFormValues })
        setSkills(updateformData)
    }

    const addOtherSkill = (evt) => {
        setFormValues({ ...formValues, other_skill: evt })
        if (other_skill.length > 0) {
            setOther_skill([...other_skill,
            {
                skill: "",
                experience: "",
                experience_type: ""
            }]
            )

        } else {
            setOther_skill([
                {
                    skill: "",
                    experience: "",
                    experience_type: ""
                }
            ])
        }

    }

    const removeOtherSkill = (i) => {
        const updateformData = other_skill.filter((item, index) => index !== i);
        setOther_skill(updateformData)
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && jobPost_Edit && jobPost_Edit.data) {
        let editData = jobPost_Edit.data
        let array1 = []
        let array2 = []
        let array3 = []
        let array4 = []
        setID(editData.id)
        if (editData.education_qualification) {
            let qualification = qualification_AllList && qualification_AllList.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []
            editData.education_qualification.map(e => {
                let filterId = qualification.filter(x => x.id === e);
                if (filterId.length > 0) {
                    array1.push(filterId[0])
                }
                return array1
            })
        }
        if (editData.skills) {
            let skill_list = skill_AllList && skill_AllList.data && skill_AllList.data.rows ? skill_AllList.data.rows : []
            editData.skills.map(e => {
                let filterId = skill_list.filter(x => x.skill_name === e.skill_name);
                if (filterId.length > 0) {
                    array2.push(filterId[0])
                    array4.push({
                        skill: e.skill_name,
                        skill_id: filterId[0].id,
                        experience: e.work_experience,
                        experience_type: e.experience_type,
                    })
                }
                return array2
            })
        }
        if (editData.preferred_industry) {
            let industry_list = industry_AllList && industry_AllList.data && industry_AllList.data.rows ? industry_AllList.data.rows : [];
            editData.preferred_industry.map(e => {
                let filterId = industry_list.filter(x => x.id === e);
                if (filterId.length > 0) {
                    array3.push(filterId[0])
                }
                return array3
            })

        }
        array1.length > 0 && setFormValues({
            ...formValues,
            education_qualification: array1,
            job_skill: array2,
            preferred_industry: array3,
            job_title: editData.job_title,
            work_experience: editData.job_experience,
            type: editData.experience_type && { label: editData.experience_type, value: editData.experience_type },
            work_mode: { label: editData.work_mode, value: editData.work_mode },
            project_id: editData.project && { label: editData.project.project_name, value: editData.project_id },
            industry: { label: editData.industry.industry_name, value: editData.industry_id },
            status: editData.status
        })
        if (editData.job_description) { setJob_description(editData.job_description) }
        setSkills(array4)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(jobPostEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        let data = {
            ID: id,
            status: { status: false }
        }
        dispatch(jobPostArchive(data))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getJobPostList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getJobPostList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(jobPost_List && jobPost_List.data && jobPost_List.data.rows && jobPost_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getJobPostList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="job-post"
                            rowid={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive}
                            extraButton={openEpicModule}
                            additionalAction={[{ title: "Epic", key: params.row.id }]}
                            addMoreData={params.row.id}
                            additionalActionIcon="TaskIcon"
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getJobPostList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getJobPostList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    const findName = () => {
        let industrylist = (industry_AllList && industry_AllList.data && industry_AllList.data.rows) || [];
        let projectlist = (resourceWiseProjectList) || []
        let filter = industrylist.filter(x => x.id === formValues.industry)
        let filterWorkMode = GetLocalstorage.get_default_work_mode().filter(x => x === formValues.work_mode)
        let filterProject = projectlist.filter(x => x.id === formValues.project_id)

        if (filterProject.length > 0) {
            formValues.project_id = { label: filterProject[0].project_name, value: filterProject[0].id }

            setFormValues({ ...formValues, project_id: { label: filterProject[0].project_name, value: filterProject[0].id } })

        }
        if (filterWorkMode.length > 0) {
            formValues.work_mode = { label: formValues.work_mode, value: formValues.work_mode }
            setFormValues({ ...formValues, work_mode: { label: formValues.work_mode, value: formValues.work_mode } })

        }
        if (filter.length > 0) {
            formValues.industry = { label: filter[0].industry_name, value: filter[0].id }
            setFormValues({ ...formValues, industry: { label: filter[0].industry_name, value: filter[0].id } })
        }
        //return industry
    }

    const inputHandler = (evt) => {
        var newContent = evt.editor.getData();
        setJob_description(newContent)
        // Define your onSubmit function here
        // ...
        // for example, setData() here

    }

    const finalSubmit = (e) => {
        e.preventDefault()
        let education_qualification_array = [];
        let preferred_industry_array = [];
        if (formValues.education_qualification && formValues.education_qualification.length > 0) {
            formValues.education_qualification.map(e => {
                return education_qualification_array.push(e.id)
            })
        }
        if (formValues.preferred_industry && formValues.preferred_industry.length > 0) {
            formValues.preferred_industry.map(e => {
                return preferred_industry_array.push(e.id)
            })
        }
        let addvalues = {
            ...formValues,
            created_by: GetLocalstorage.userDetail().id,
            job_skill: skills, other_skill: other_skill,
            industry: formValues.industry.value ? formValues.industry.value : formValues.industry,
            work_mode: formValues.work_mode.value ? formValues.work_mode.value : formValues.work_mode,
            education_qualification: education_qualification_array,
            preferred_industry: preferred_industry_array,
            project: formValues.project_id.value ? formValues.project_id.value : formValues.project_id,
            job_experience: formValues.work_experience,
            experience_type: formValues.type.value ? formValues.type.value : formValues.type,
            job_description: job_description

        }
        if (createNewProject === true) {
            addvalues['project'] = null;
            addvalues['create_new_project'] = true;
            addvalues['domain_id'] = domainId;
        }
        delete addvalues['project_id']
        delete addvalues['work_experience']
        delete addvalues['type']
        setReload(true)
        if (ID) {
            dispatch(jobPostUpdate({ ID, addvalues }))
        } else {
            dispatch(jobPostCreate(addvalues))
        }

    }

    const openEpicModule = (jobId) => {
        setEpicActive(true)
        dispatch(getTaskList(`{"filter":{"job_id":{"value":${jobId}, "operation":"eq"}}}`))

    }

    if (epicActive === true && taskList && taskList.data) {
        let epicId = taskList && taskList.data && taskList.data.rows && taskList.data.rows.length > 0 ? taskList.data.rows[0].id : ""
        if (epicId) {
            navigate(`/epic-task/${epicId}`)
        }
        setEpicActive(false)
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Job Post List</h3>
                        <div>
                            <Tooltip title="Add Job Post">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={jobPost_List && jobPost_List.data && jobPost_List.data.rows ? jobPost_List.data.rows : []}
                        columns={JobPostColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"xl"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Job Post {activeViewMode === true && "(Preview)"}</DialogTitle>
                    {activeViewMode === true ?
                        <DialogContent style={{ height: "650px" }}>
                            <div>
                                <form className="regulazation-form">
                                    <div>
                                        <ViewField
                                            label={'Project'}
                                            key={'project_id'}
                                            type={1}
                                            value={formValues.project_id}
                                        />
                                        <ViewField
                                            label={'Job Title'}
                                            key={'job_title'}
                                            type={1}
                                            value={formValues.job_title}
                                        />
                                        <ViewField
                                            label={'Work Mode'}
                                            key={'work_mode'}
                                            type={1}
                                            value={formValues.work_mode}
                                        />
                                        <ViewField
                                            label={'Qualification'}
                                            key={'education_qualification'}
                                            length={1}
                                            optionLevel={['education_qualification_name']}
                                            type={2}
                                            value={formValues.education_qualification}
                                        />

                                        <ViewField
                                            label={'Description'}
                                            key={'editor'}
                                            fieldType={'editor'}
                                            type={1}
                                            value={job_description}
                                        />
                                        <ViewField
                                            label={'Skills'}
                                            key={'job_skill'}
                                            type={2}
                                            length={3}
                                            optionLevel={['skill', 'experience', 'experience_type']}
                                            value={skills}
                                        />
                                        {other_skill && other_skill.length > 0 &&
                                            <ViewField
                                                label={'Other Skill (optional)'}
                                                key={'other_skill'}
                                                type={2}
                                                length={3}
                                                optionLevel={['skill', 'experience', 'experience_type']}
                                                value={other_skill}
                                            />}
                                        <ViewField
                                            label={'Work Experience'}
                                            key={'work_experience'}
                                            type={3}
                                            value={[formValues.work_experience, formValues.type]}
                                        />

                                        <ViewField
                                            label={'Industry'}
                                            key={'industry'}
                                            type={1}
                                            value={formValues.industry}
                                        />
                                        <ViewField
                                            label={'Preferred Industry'}
                                            key={'preferred_industry'}
                                            length={1}
                                            optionLevel={['industry_name']}
                                            type={2}
                                            value={formValues.preferred_industry}
                                        />
                                        <ViewField
                                            label={'Status'}
                                            key={'status'}
                                            type={1}
                                            value={formValues.status}
                                        />

                                    </div>
                                    <br />
                                    <div>
                                        <Button style={{ minWidth: '15%' }} className="submit-modal" onClick={finalSubmit}>Confirm</Button>
                                        <Button style={{ minWidth: '15%', backgroundColor: 'red', marginLeft: 10 }} className="submit-modal" onClick={handleClose}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        </DialogContent>
                        :
                        <DialogContent>
                            <form>
                                <div className="add-new-user-form">
                                    <InputSelectField
                                        label={"Project"}
                                        name={"project_id"}
                                        placeholder={"Select Project"}
                                        onChange={handleChange}
                                        defaultValue={formValues.project_id}
                                        errStatus={errStatus}
                                        formErr={formErr.project_idErr}
                                        optionList={ID && resourceWiseProjectList && resourceWiseProjectList.length > 0 ? resourceWiseProjectList : createNewProjectList && createNewProjectList.length > 0 ? createNewProjectList : []}
                                        optionLevel={['project_name']}
                                        optionValue={'id'}
                                    />
                                    {
                                        createNewProject === true ?
                                            <div className="formInput">
                                                <label>Domain</label>
                                                <Select
                                                    className="select"
                                                    placeholder="Select Domain"
                                                    defaultValue={domainId}
                                                    isSearchable={true}
                                                    onChange={(e) => setDomainId(e.value)}
                                                    options={
                                                        mappingDomain_List && mappingDomain_List.map((option) => {
                                                            return { label: option.name, value: option.id, name: "domain_id" }
                                                        })
                                                    }
                                                />
                                            </div>
                                            : ""
                                    }
                                    <InputFields
                                        label={"Job Title"}
                                        name="job_title"
                                        defaultValue={formValues.job_title}
                                        type="text"
                                        placeholder="Title"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.job_titleErr}
                                    />
                                    <InputSelectField
                                        label={"Work Mode"}
                                        name={"work_mode"}
                                        placeholder={"Work Mode"}
                                        onChange={handleChange}
                                        defaultValue={formValues.work_mode}
                                        errStatus={errStatus}
                                        formErr={formErr.work_modeErr}
                                        optionList={GetLocalstorage.get_default_work_mode()}
                                        optionLevel={['option']}
                                        optionValue={'option'}
                                    />
                                    <InputMultiSelectField
                                        label={"Education Qualification"}
                                        name={"education_qualification"}
                                        placeholder={"Education Qualification"}
                                        onRemove={handleMultiQualificationChange}
                                        onSelect={handleMultiQualificationChange}
                                        defaultValue={formValues.education_qualification}
                                        errStatus={errStatus}
                                        formErr={formErr.education_qualificationErr}
                                        optionList={qualification_AllList && qualification_AllList.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []}
                                        optionLevel={['education_qualification_name']}
                                        optionValue={'id'}
                                    />
                                    <InputEditorField
                                        label={"Description"}
                                        defaultValue={job_description}
                                        name="job_description"
                                        onChange={inputHandler}
                                        placeholder="Description"
                                        errStatus={errStatus}
                                        formErr={formErr.descriptionErr}
                                    />
                                    <InputMultiSelectField
                                        divstyle={{ flexBasis: '100%' }}
                                        label={"Skill"}
                                        name={"job_skill"}
                                        placeholder={"Skill"}
                                        onRemove={addMoreSkill}
                                        onSelect={addMoreSkill}
                                        defaultValue={formValues.job_skill}
                                        errStatus={errStatus}
                                        formErr={formErr.job_skillErr}
                                        optionList={skill_AllList && skill_AllList.data && skill_AllList.data.rows ? skill_AllList.data.rows : []}
                                        optionLevel={['skill_name']}
                                        optionValue={'id'}
                                    />
                                    {
                                        skills.map((item, i) => {
                                            return (
                                                <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>

                                                    <div className="formInput" style={{ flexBasis: '40%' }}>
                                                        <label >
                                                            Skill
                                                        </label>
                                                        <input
                                                            disabled
                                                            name={"skill"}
                                                            onChange={(e) => handleSkillChange(e, i)}
                                                            type="text"
                                                            placeholder="Skill"
                                                            value={item.skill}
                                                        />
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Experienced
                                                        </label>
                                                        <input
                                                            name={"experience"}
                                                            onChange={(e) => handleSkillChange(e, i)}
                                                            type="number"
                                                            min="0"
                                                            placeholder="Experience"
                                                            value={item.experience}
                                                        />
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Month/Year
                                                        </label>
                                                        <select
                                                            name={"experience_type"}
                                                            onChange={(e) => handleSkillChange(e, i)}
                                                            value={item.experience_type}
                                                        >
                                                            <option>Select</option>
                                                            {GetLocalstorage.get_default_month_year().map((e, i) => {
                                                                return (<option value={e} key={i}>{e}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeSkill(i)} />
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    <div className="formInput" style={{ flexBasis: '100%' }}>
                                        <span style={{ display: "flex", alignItems: "center" }} >Other Skill (optional) <AddCircleOutline onClick={() => addOtherSkill()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} /> </span>
                                    </div>

                                    {
                                        other_skill.map((item, i) => {
                                            return (
                                                <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>

                                                    <div className="formInput" style={{ flexBasis: '40%' }}>
                                                        <label >
                                                            Skill
                                                        </label>
                                                        <input
                                                            name={"skill"}
                                                            onChange={(e) => handleOtherSkillChange(e, i)}
                                                            type="text"
                                                            placeholder="Skill"
                                                            value={item.skill}
                                                        />
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Experienced
                                                        </label>
                                                        <input
                                                            name={"experience"}
                                                            onChange={(e) => handleOtherSkillChange(e, i)}
                                                            type="number"
                                                            min="0"
                                                            placeholder="Experience"
                                                            value={item.experience}
                                                        />
                                                    </div>
                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Month/Year
                                                        </label>
                                                        <select
                                                            name={"experience_type"}
                                                            onChange={(e) => handleOtherSkillChange(e, i)}
                                                            value={item.experience_type}
                                                        >
                                                            <option>Select</option>
                                                            {GetLocalstorage.get_default_month_year().map((e, i) => {
                                                                return (<option value={e} key={i}>{e}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeOtherSkill(i)} />
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    <InputFields
                                        label={"Work Experience"}
                                        name="work_experience"
                                        defaultValue={formValues.work_experience}
                                        type="number"
                                        placeholder="Work Experience"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.work_experienceErr}
                                    />
                                    <InputSelectField
                                        label={"Experience(Year/Month)"}
                                        name={"type"}
                                        placeholder={"Select Year/Month"}
                                        onChange={handleChange}
                                        defaultValue={formValues.type}
                                        errStatus={errStatus}
                                        formErr={formErr.typeErr}
                                        optionList={GetLocalstorage.get_default_month_year()}
                                        optionLevel={['option']}
                                        optionValue={'option'}
                                    />


                                    <InputSelectField
                                        label={"Industry"}
                                        name={"industry"}
                                        placeholder={"Industry"}
                                        onChange={handleChange}
                                        defaultValue={formValues.industry}
                                        errStatus={errStatus}
                                        formErr={formErr.industryErr}
                                        optionList={industry_AllList && industry_AllList.data && industry_AllList.data.rows ? industry_AllList.data.rows : []}
                                        optionLevel={['industry_name']}
                                        optionValue={'id'}
                                    />
                                    <InputMultiSelectField
                                        label={"Preferred Industry"}
                                        name={"preferred_industry"}
                                        placeholder={"Preferred Industry"}
                                        onRemove={handleMultiIndustryChange}
                                        onSelect={handleMultiIndustryChange}
                                        defaultValue={formValues.preferred_industry}
                                        optionList={industry_AllList && industry_AllList.data && industry_AllList.data.rows ? industry_AllList.data.rows : []}
                                        optionLevel={['industry_name']}
                                        optionValue={'id'}
                                    />
                                    <InputRadioGroupFields
                                        label={"Status"}
                                        name="status"
                                        defaultValue={formValues.status}
                                        type="text"
                                        placeholder="Status"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.statusErr}
                                    />

                                </div>
                                <Button style={{ minWidth: '17%' }} className="submit-modal" onClick={submitData}>{ID ? 'Preview and Update' : 'Preview And Submit'}</Button>
                            </form>
                        </DialogContent>
                    }
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive job-post'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default JobPost