import { ArrowBack } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import LoaderForm from '../../../components/common-function/LoaderForm'
import Navbar from '../../../components/navbar/Navbar'
import Sidebar from '../../../components/sidebar/Sidebar'
import { STATUSES } from '../../../redux/common-status/constant'
import { createSovernCredential } from '../../../redux/features/sovernCredentialSlice'

const formErrData = Object.freeze({
    accountErr: 'Account ID is required',
    servicekeyErr: "Service Key is requird",
});

const initialFormData = Object.freeze({
    account_id: "",
    service_key: "",
});

export default function AddSovernCredential() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { create_sovern_credential, status, error } = useSelector(state => state.sovernCredentialSlice)

    const { REACT_APP_ENV } = process.env;
    const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id

    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [reload, setReload] = useState(false)

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormValues({ ...formValues, [name]: value });
    }

    if (reload === "submit" && create_sovern_credential && create_sovern_credential.status) {
        if (create_sovern_credential.status === 200) {
            toast.success(create_sovern_credential.message)
            setTimeout(() => {
                navigate('/sovern-credentials')
            }, 2000);
        } else {
            toast.error(create_sovern_credential.message)
        }
        setTimeout(() => {
            setReload(false)
        }, 1000);
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (formValues.account_id === "" || formValues.service_key === "") {
            setErrStatus(true)
        }
        if (formValues.account_id !== "" && formValues.service_key !== "") {
            let addvalues = { ...formValues, created_by: userId }
            // let addvalues = formValues
            dispatch(createSovernCredential(addvalues))
            setErrStatus(false)
            setReload("submit")
        }
    }
    if (reload === "submit" && status === STATUSES.FAILURE && error && error.message) {
        setReload(false)
        toast.error(error.message);
    }

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />

                <ToastContainer />
                <div className="top-headings">
                    <h3>Add New Sovern Credential </h3>
                    <Tooltip title="Back">
                        <Link to={-1} className="btn btn-add-new"><ArrowBack /></Link>
                    </Tooltip>
                </div>
                {reload === "submit" && <LoaderForm />}

                <form onSubmit={onSubmit}>
                    <div className="add-new-user-form">
                        <div className="formInput" style={{ flexBasis: "48%" }}>
                            <label >
                                Account ID
                            </label>
                            <input
                                name="account_id"
                                defaultValue={formValues.account}
                                type="text"
                                placeholder="Account ID"
                                onChange={handleChange}
                            />
                            {errStatus === true && formValues && formValues.account_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.accountErr}</p>}
                        </div>

                        <div className="formInput" style={{ flexBasis: "48%" }}>
                            <label >
                                Service Key
                            </label>
                            <input
                                name="service_key"
                                defaultValue={formValues.service_key}
                                type="text"
                                placeholder="Service Key"
                                onChange={handleChange}
                            />
                            {errStatus === true && formValues && formValues.service_key === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.servicekeyErr}</p>}

                        </div>
                    </div>
                    <button
                        className="btn btn-submit"
                        disabled={reload === "submit" ? true : false}
                        style={{ marginBottom: "60px", marginTop: "10px", backgroundColor: reload === "submit" ? "gray" : "" }}
                    >Submit
                    </button>
                </form>
            </div>
        </div>
    )
}
