import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getRole } from '../../redux/features/roleSlice';
import { roleColumns } from "../../components/datatable-coulms/tableCoulmns";
import dataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import { TablePagination } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";

function AllRoles() {
  const dispatch = useDispatch();

  const { data, status } = useSelector(state => state.role)
  const { data: list } = data

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)

  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":25,"order_by":{"column":"-id"}}`)
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);

  useEffect(() => {
    dispatch(getRole(filterQuery))
    if (status === 'error') { console.log("error") }
  }, [])

  const handleDelete = async (id) => {
    const request = { status: 3 }
    setOpen(true)
    if (open === true) {
      const response = await dataService.deleteRole(id, request)
      if (response && response.data) {
        toast.success(response.data.message);
        setOpen(false)
        setTimeout(() => {
          dispatch(getRole(filterQuery))
        }, 800);
      }
    }
  }

  const handleClickOpen = (type, id) => {
    setDeleteId(id)
    if (type === true) {
      handleDelete(id);
    }
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent moduleName="roles" rowid={params.row.id} editLinkUrl={`/edit-role/${params.row.id}`} viewLinkUrl="" isDelete={true} editButton={false} viewButton={false} deleteButton={handleClickOpen} />
          </div>
        );
      },
    },
  ];

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setLimit(limit)
    dispatch(getRole(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(getRole(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
              filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getRole(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      // setPageSize(25)
      setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`)
      dispatch(getRole(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`))
    }
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(getRole(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
    }
  }

  return (
    <div className="listing">
      <Sidebar />
      <div className="listingContainer">
        <Navbar />
        {/* {status === "loading" ? <LoaderForm /> : */}
        <div className="datatable">
          <div className="datatableTitle">
            <h3>All Roles</h3>
            <Tooltip title="Add New Role">
              <Link to="/add-new-role" className="btn-add-new ">
                <AddIcon />
              </Link>
            </Tooltip>
          </div>

          <ToastContainer />
          {(status === STATUSES.LOADING) && <LoaderForm />}
          <DataGrid
            className="datagrid"
            columnVisibilityModel={{
              id: false
            }}
            rows={list && list.rows ? list.rows : []}
            columns={roleColumns.concat(actionColumn)}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            onSortModelChange={(sort) => handleSort(sort)}
          />
        </div>

        {/* for delete popup */}
        <DeleteDialogModal
          open={open}
          onClose={handleClose}
          heading={'Delete ?'}
          paragraph={'Are You Sure To Remove This Role'}
          handleArchive={handleClickOpen}
          id={deleteId}
          moduleName={'AllRoles'}
        />
      </div>
    </div>
  )
}
export default AllRoles