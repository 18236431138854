import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getResumeList = createAsyncThunk("resume-parse/list", async (data) => {
    const res = await dataService.get_resume_parse_list(data);
    return res.data;
});
export const getResumeById = createAsyncThunk("resume-parse/id", async (id) => {
    const res = await dataService.get_resume_parse_by_id(id);
    return res.data;
});
export const updateResumeById = createAsyncThunk("resume-parse/update", async (data) => {
    const res = await dataService.update_resume_parse_by_id(data.id, data.addValues);
    return res.data;
});

const resumeSlice = createSlice({
    name: "resume",
    initialState: {
        status: 'idle',
        error: '',
        resumeList: [],
        resumeById: [],
        resumeUpdate: []
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get resume list
            .addCase(getResumeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getResumeList.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = STATUSES.SUCCESS;
                    state.resumeList = action.payload;
                } else {
                    state.status = STATUSES.FAILURE;
                    state.error = result.message
                }

            })
            .addCase(getResumeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get by id resume data
            .addCase(getResumeById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getResumeById.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = STATUSES.SUCCESS;
                    state.resumeById = action.payload;
                } else {
                    state.status = STATUSES.FAILURE;
                    state.error = result.message
                }

            })
            .addCase(getResumeById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //update by id resume data
            .addCase(updateResumeById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateResumeById.fulfilled, (state, action) => {
               // let result = action.payload;
                //if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_resumeUpdate`;
                    state.resumeUpdate = action.payload;
                // } else {
                //     state.status = STATUSES.FAILURE;
                //     state.error = result.message
                // }

            })
            .addCase(updateResumeById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
    },
});


export default resumeSlice.reducer;