const { REACT_APP_ENV } = process.env;
class LocalStorageDataService {

    userDetail() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
    }

    userToken() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).token : "";
    }

    priority_list() {
        return ["High", "Medeium", "Normal"]
    }
    labels_list() {
        return ["API", "UI", "UX"]
    }
    lastInteractionTime() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).lastInteractionTime : "";
    }
    regularize_type() {
        return [{ value: '-1', label: "select" }, { value: 1, label: "Regularize Punch In Time" }, { value: 2, label: "Regularize Punch Out Time" }, { value: 0, label: "Regularize Both" }]
    }
    regularize_status_type() {
        return [{ value: 4, label: "PENDING" }, { value: 7, label: "REJECTED" }, { value: 5, label: "APPROVED" }, { value: 6, label: "CANCELLED" }]
    }
    get_default_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user &&localStorage.getItem(`${REACT_APP_ENV}_${user.id}_task`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_task`)) : "";
    }
    get_default_work_mode() {
        return ["Work From Home", "Work From Office", "Hybrid"]
    }
    get_default_month_year() {
        return ["Months", "Years"]
    }
    get_file_status() {
        return [{ value: 0, label: "Uploaded Sucsessfully" }, { value: 1, label: "Parsing In Progress" }, { value: 2, label: "Parsed Sucsessfully" }, { value: 3, label: "Story Created" },
        { value: 4, label: "Invalid FIle Uploaded" }, { value: 5, label: "Story Creation In Progress" }, { value: 6, label: "Story Creation Failed" }]

    }
    get_pagination_filter_all_call() {
        return localStorage.getItem(`${REACT_APP_ENV}_all_call_details_pagiation`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_all_call_details_pagiation`)) : "";
    }

    get_default_time_in_mins() {
        return [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
    }
    get_domain_data() {
        let status = false
        let userData = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : ""
        userData && userData.domains_data && userData.domains_data.length > 0 && userData.domains_data.map((item) => {
            if (item.status === true) {
                const domain_name = item.domain && item.domain.name && item.domain.name
                let result = domain_name.match("Recruitment") || domain_name.match("recruitment")
                if (result) {
                    status = true
                }
            }
        })
        return status
    }

    ckEditor_config_toolbar() {
        let toolbar = {
            toolbar: [
                ["Source"],
                ["Styles", "Format", "Font", "FontSize"],
                ["Bold", "Italic"],
                ["Undo", "Redo"],
                ["EasyImageUpload"],
                { name: 'insert', items: ['Image', 'Table', 'Smiley', 'PageBreak', 'Iframe'] },
            ],
        }
        return toolbar
    }

}
export default new LocalStorageDataService();