import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import './resume.scss'
import {  useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CONSTANTS from "../../components/constant/constantComponents";
import { useDispatch, useSelector } from "react-redux";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { getResumeById, updateResumeById } from "../../redux/features/resumeSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
//import { countryList, getCountryWiseSate } from "../../redux/features/countrySlice";
import { toast, ToastContainer } from "react-toastify";
//import { Tooltip } from "rsuite";


const { REACT_APP_API_BASEURL } = process.env;

const formErrData = Object.freeze({
    fullNameNameErr: "Name is required",
    emailErr: "Email is required",
    contactErr: "Contact is required"
});

const ResumeEdit = ({ resumeID }) => {
    const { innerHeight } = window
    const dispatch = useDispatch();
    const { resumeId } = useParams();
    const navigate = useNavigate()
    const { resumeById, status, error, resumeUpdate } = useSelector(state => state.resume)
    //const { country_list, countryStateList } = useSelector(state => state.countryList)
    const [formValues, setFormValues] = useState({})
    //const [formErr] = useState(formErrData)
    //const [errStatus, setErrStatus] = useState(false);
    const [contacts, setContacts] = useState([])
    const [emails, setEmails] = useState([])
    const [loading, setLoading] = useState(true)
    const [Id] = useState(resumeID ? resumeID : resumeId)


    useEffect(() => {
        Id && dispatch(getResumeById(Id))
        //dispatch(countryList())

    }, [])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            setLoading(false)
            errorHandling(error, navigate)
        }
    }, [error])

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        // if (name === 'country_code') {
        //     dispatch(getCountryWiseSate(`{"filter":{"code":{"value":"${value}", "operation":"eq"}}, "no_limit": True}`))
        //     setFormValues({ ...formValues, ['municipality']: '' });
        // }
        setFormValues({ ...formValues, [name]: value });

    }

    if (loading === true && resumeById && resumeById.status === 200) {
        setLoading(false);
        let emailIs = []
        let contactIs = []
        let data = resumeById.data
        {
            data.ContactInformation &&
                setFormValues({
                    country_code: data.ContactInformation.Location && data.ContactInformation.Location.CountryCode,
                    municipality: data.ContactInformation.Location && data.ContactInformation.Location.Municipality,
                    postal_code: data.ContactInformation.Location && data.ContactInformation.Location.PostalCode,
                    regions: data.ContactInformation.Location && data.ContactInformation.Location.Regions,
                    address: data.ContactInformation.Location && data.ContactInformation.Location.StreetAddressLines,
                    full_name: data.ContactInformation.CandidateName && data.ContactInformation.CandidateName.GivenName,
                    short_name: data.ContactInformation.CandidateName && data.ContactInformation.CandidateName.FamilyName

                })
            if (data.ContactInformation && data.ContactInformation.Telephones && data.ContactInformation.Telephones.length > 0) {
                data.ContactInformation.Telephones.map(e => {
                    contactIs.push({ contact: e.Raw, disable: true })
                })
            }
            setContacts(contactIs)
            if (data.ContactInformation && data.ContactInformation.EmailAddresses && data.ContactInformation.EmailAddresses.length > 0) {
                data.ContactInformation.EmailAddresses.map(e => {
                    emailIs.push({ email: e, disable: true })
                })
            }
            setEmails(emailIs)
        }

    }


    const addMoreContacts = () => {
        if (contacts.length > 0) {
            setContacts([...contacts,
            {
                contact: "",
                disable: false
            }]
            )

        } else {
            setContacts([
                {
                    contact: "",
                    disable: false

                }
            ])
        }
    }

    const removeContacts = (i) => {
        const updateformData = contacts.filter((item, index) => index !== i);
        setContacts(updateformData)
    }

    const handleContacts = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = contacts.length > 0 && contacts.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setContacts([...valueArray])
    }

    const addMoreEmails = () => {
        if (emails.length > 0) {
            setEmails([...emails,
            {
                email: "",
                disable: false
            }]
            )

        } else {
            setEmails([
                {
                    email: "",
                    disable: false

                }
            ])
        }
    }

    const removeEmails = (i) => {
        const updateformData = emails.filter((item, index) => index !== i);
        setEmails(updateformData)
    }

    const handleEmails = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = emails.length > 0 && emails.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setEmails([...valueArray])
    }

    const nothing = () => {

    }

    const submitData = (e) => {
        e.preventDefault()
        let contactArray = [];
        let emailArray = []
        let contactInfo = {}
        contacts.map(e => {
            return contactArray.push({
                "Raw": e.contact,
                "Normalized": e.contact,
                "SubscriberNumber": e.contact,
                "InternationalCountryCode": null
            })
        })
        emails.map(e => {
            return emailArray.push(e.email)
        })
        contactInfo = {
            "GivenName": formValues.full_name,
            "FamilyName": formValues.short_name,
            "FormattedName": formValues.full_name
        }
        let data = {
            "status": 2,
            "ContactInformation": {
                "Location": {
                    "Regions": formValues.regions,
                    "CountryCode": formValues.country_code,
                    "PostalCode": formValues.postal_code,
                    "Municipality": formValues.municipality,
                    "StreetAddressLines": formValues.address
                },
                "Telephones": contactArray,
                "CandidateName": contactInfo,
                "emailsddresses": emailArray
            }
        }
        setLoading("update")
        dispatch(updateResumeById({ id: Id, addValues: data }))

    }

    if (loading === 'update' && resumeUpdate.status) {
        if (resumeUpdate.status === 200) {
            setLoading(false)
            toast.success(resumeUpdate.message)
            //dispatch(getResumeById(Id))
        } else {
            setLoading(false)
            toast.error(resumeUpdate.message)
        }
    }
    const fileType = resumeById.data && (resumeById.data.source_file_path).split(".")[1]
    return (
        // <div className="resource">
        //     <Sidebar />
        //     <div className="resourceContainer">
        //         <Navbar />
        //         <ToastContainer />
        //         <div className="datatable">
        //             <div className="top-headings">
        //                 <h3>Update Resume Crediential</h3>
        //                 <Tooltip title="Back">
        //                     <button onClick={() => navigate(-1)} className="btn btn-add-new"><ArrowBack/></button>
        //                 </Tooltip>
        //             </div>
        <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
                {fileType !== 'pdf' ?
                    resumeById.data && resumeById.data.parsed_data && resumeById.data.parsed_data.Value &&
                    <span
                        dangerouslySetInnerHTML={{
                            __html: resumeById.data.parsed_data.Value.Conversions.HTML
                        }}
                    />
                    :
                    resumeById.data && <iframe
                        src={REACT_APP_API_BASEURL.replace("api/", "") + resumeById.data.source_file_path + "#toolbar=0"}
                        width="100%"
                        height={innerHeight}
                        scrolling="no"
                        frameBorder="0"
                        allow="fullscreen"
                        className="pdf-container"
                    />
                }
            </div>
            <div style={{ width: '50%', paddingLeft:'10px' }}>  <form>
                <div className="add-new-user-form">
                    <div className="formInput">
                        <label >
                            Full Name
                        </label>
                        <input
                            name="full_name"
                            defaultValue={formValues.full_name}
                            type="text"
                            placeholder="Full Name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formInput">
                        <label >
                            Family Name
                        </label>
                        <input
                            name="short_name"
                            defaultValue={formValues.short_name}
                            type="text"
                            placeholder="Family Name"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formInput" style={{ flexBasis: '100%' }}>
                        <span style={{ display: "flex", alignItems: "center" }} >Add More Contact <AddCircleOutline onClick={() => addMoreContacts()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} /> </span>
                    </div>
                    {
                        contacts.map((item, i) => {
                            return (
                                <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>


                                    <div className="formInput" style={{ flexBasis: '15%' }}>
                                        <label >
                                            Contact-{i+1}
                                        </label>
                                        <input
                                            name={"contact"}
                                            onChange={(e) => handleContacts(e, i)}
                                            placeholder="000000"
                                            disabled={item.disable}
                                            value={item.contact}
                                        />
                                    </div>

                                    <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => item.disable ? nothing : removeContacts(i)} />
                                    </div>

                                </div>
                            )
                        })
                    }

                    <div className="formInput" style={{ flexBasis: '100%' }}>
                        <span style={{ display: "flex", alignItems: "center" }} >Add More Email <AddCircleOutline onClick={() => addMoreEmails()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} /> </span>
                    </div>
                    {
                        emails.map((item, i) => {
                            return (
                                <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>


                                    <div className="formInput" style={{ flexBasis: '15%' }}>
                                        <label >
                                            Email-{i+1}
                                        </label>
                                        <input
                                            name={"email"}
                                            onChange={(e) => handleEmails(e, i)}
                                            placeholder="example@gmail.com"
                                            value={item.email}
                                            disabled={item.disable}
                                            type="email"
                                        />
                                    </div>


                                    <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => item.disable ? nothing : removeEmails(i)} />
                                    </div>

                                </div>
                            )
                        })
                    }

                    <div className="formInput">
                        <label >
                            Country
                        </label>
                        <input
                            name="country_code"
                            defaultValue={formValues.country_code}
                            type="text"
                            placeholder="Country"
                            onChange={handleChange}
                        />
                        {/* <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Select"
                                        defaultValue={formValues.country_code}
                                        isSearchable={true}
                                        name={"country_code"}
                                        options={
                                            country_list.data && country_list.data.rows.map((option) => {
                                                return { label: option.name, value: option.code, name: "country_code" }
                                            })
                                        }
                                        onChange={handleChange}
                                    /> */}
                        {/* {errStatus === true && formValues && formValues.assignee_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>} */}
                    </div>
                    <div className="formInput">
                        <label >
                            Municipality
                        </label>
                        <input
                            name="municipality"
                            defaultValue={formValues.municipality}
                            type="text"
                            placeholder="Municipality"
                            onChange={handleChange}
                        />
                        {/* <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Select"
                                        defaultValue={formValues.municipality}
                                        isSearchable={true}
                                        name={"municipality"}
                                        options={
                                            countryStateList.data && countryStateList.data.rows.map((option) => {
                                                return { label: option.name, value: option.code, name: "municipality" }
                                            })
                                        }
                                        onChange={handleChange}
                                    /> */}
                        {/* {errStatus === true && formValues && formValues.assignee_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>} */}
                    </div>
                    <div className="formInput">
                        <label >
                            Regions
                        </label>
                        <input
                            name="regions"
                            defaultValue={formValues.regions}
                            type="text"
                            placeholder="Regions"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formInput">
                        <label >
                            Postal Code
                        </label>
                        <input
                            name="postal_code"
                            defaultValue={formValues.postal_code}
                            type="number"
                            min={0}
                            placeholder="Postal Code"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formInputDescription">
                        <label >
                            Address
                        </label>

                        <textarea defaultValue={formValues.address} name="address" onChange={handleChange} rows="3" placeholder="Address..."></textarea>
                    </div>

                </div>
                {loading !== 'update' &&
                    <button className="submit-modal" onClick={submitData}>{CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                }
            </form>

            </div>
        </div>

        //         </div>
        //     </div>
        // </div>
    )
}

export default ResumeEdit