import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const resourceListOption = createAsyncThunk(
    "get/resources_dropDown",
    async (params) => {
        const res = await DataService.resources_dropDown();
        const resp = res.data;
        return resp;
    }
);

export const domainListOption = createAsyncThunk(
    "get/domain_dropdown",
    async (params) => {
        const res = await DataService.domain_list(params);
        const resp = res.data;
        return resp;
    }
);

export const projectsListOption = createAsyncThunk(
    "get/projects_dropdown",
    async (params) => {
        const res = await DataService.projects_list(params);
        const resp = res.data;
        return resp;
    }
);

export const epicOption = createAsyncThunk("get/epic_dropDown", async (data) => {
    const res = await DataService.get_my_job_list(data);
    const resp = res.data;
    return resp;
}
);

export const skilOption = createAsyncThunk(
    "get/skil_dropDown",
    async () => {
        const res = await DataService.skil_dropDown();
        const resp = res.data;
        return resp;
    }
);

export const projectTypeOptionList = createAsyncThunk("project-type-options", async (id) => {
    const res = await DataService.project_type_list_opyion(id);
    return res.data;
});

export const departmentOptionList = createAsyncThunk("department-options", async (data) => {
    const res = await DataService.department_list_option(data);
    return res.data;
});

export const departmentDomainOptionList = createAsyncThunk("department-domain-options", async (data) => {
    const res = await DataService.departmentDomainList(data);
    return res.data;
});

export const workflowOptionList = createAsyncThunk("workflow-options", async (data) => {
    const res = await DataService.workflow_list(data);
    return res.data;
});

const optionsSlice = createSlice({
    name: "domain",
    initialState: {

        resources: [],
        resources_count: 0,
        resources_status: STATUSES.IDLE,
        resources_error: '',

        domains: [],
        domains_count: 0,
        domains_status: STATUSES.IDLE,
        domains_error: '',

        projects: [],
        projects_count: 0,
        projects_status: STATUSES.IDLE,
        projects_error: '',

        epics: [],
        epics_count: 0,
        epics_status: STATUSES.IDLE,
        epics_error: '',

        skils: [],
        skils_count: 0,
        skils_status: STATUSES.IDLE,
        skils_error: '',

        projectTypes: [],
        projectType_count: 0,
        projectType_status: STATUSES.IDLE,
        projectType_error: '',

        departments: [],
        department_count: 0,
        department_status: STATUSES.IDLE,
        department_error: '',

        workflows: [],
        workflow_count: 0,
        workflow_status: STATUSES.IDLE,
        workflow_error: '',

        departmentDomain: [],
        departmentDomain_count: 0,
        departmentDomain_status: STATUSES.IDLE,
        departmentDomain_error: '',

    },
    reducers: {},
    extraReducers(builder) {
        builder
            // GET resource LISTOption
            .addCase(resourceListOption.pending, (state, action) => {
                state.resources_status = STATUSES.LOADING;
            })
            .addCase(resourceListOption.fulfilled, (state, action) => {
                state.resources_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.resources_error = !action.payload.data ? false : true;
                state.resources = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: `${e.name} (${e.email})`, value: e.id }));
                state.resources_count = action.payload.data.count;
            })
            .addCase(resourceListOption.rejected, (state, action) => {
                state.resources_status = STATUSES.FAILURE;
                state.resources_error = action.error
            })

            // GET DOMAIN LISTOption
            .addCase(domainListOption.pending, (state, action) => {
                state.domains_status = STATUSES.LOADING;
            })
            .addCase(domainListOption.fulfilled, (state, action) => {
                state.domains_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.domains_error = !action.payload.data ? false : true;
                state.domains = !action.payload.data.rows ? [] : action.payload.data.rows;
                // state.domains= !action.payload.data.rows ? [] : action.payload.data.rows.map((e)=>({...e, label:`${e.name} (${e.email})`, value:e.id }));
                state.domains_count = action.payload.data.count;
            })
            .addCase(domainListOption.rejected, (state, action) => {
                state.domains_status = STATUSES.FAILURE;
                state.domains_error = action.error
            })

            //  PROJECT LISTOption
            .addCase(projectsListOption.pending, (state, action) => {
                state.projects_status = STATUSES.LOADING;
            })
            .addCase(projectsListOption.fulfilled, (state, action) => {
                state.projects_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.projects_error = !action.payload.data ? false : true;
                state.projects = !action.payload.data.rows ? [] : action.payload.data.rows;
                // state.projects= !action.payload.data.rows ? [] : action.payload.data.rows.map((e)=>({...e, label:`${e.name} (${e.email})`, value:e.id }));
                state.projects_count = action.payload.data.count;
            })
            .addCase(projectsListOption.rejected, (state, action) => {
                state.projects_status = STATUSES.FAILURE;
                state.projects_error = action.error
            })


            //  Epic LISTOption
            .addCase(epicOption.pending, (state, action) => {
                state.epics_status = STATUSES.LOADING;
            })
            .addCase(epicOption.fulfilled, (state, action) => {
                state.epics_status = STATUSES.SUCCESS;
                let result = action.payload;
                let array = []
                result && result.data && result.data.rows.map(e => { return array.push({ value: e.id, label: e.ticket_key }) })
                let arrayUniqueByKey = [...new Map(array.map(item =>
                    [item['value'], item])).values()];
                state.epics = arrayUniqueByKey

            })
            .addCase(epicOption.rejected, (state, action) => {
                state.epics_status = STATUSES.FAILURE;
                state.epics_error = action.error
            })

            // GET skil LISTOption
            .addCase(skilOption.pending, (state, action) => {
                state.skils_status = STATUSES.LOADING;
            })
            .addCase(skilOption.fulfilled, (state, action) => {
                state.skils_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.skils_error = !action.payload.data ? false : true;
                state.skils = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: `${e.name} (${e.email})`, value: e.id }));
                state.skils_count = action.payload.data.count;
            })
            .addCase(skilOption.rejected, (state, action) => {
                state.skils_status = STATUSES.FAILURE;
                state.skils_error = action.error
            })

            // GET project type LISTOption
            .addCase(projectTypeOptionList.pending, (state, action) => {
                state.projectType_status = STATUSES.LOADING;
            })
            .addCase(projectTypeOptionList.fulfilled, (state, action) => {
                state.projectType_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.projectType_error = !action.payload.data ? false : true;
                state.projectTypes = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.project_type_name, value: e.id }));
                state.projectType_count = action.payload.data.count;
            })
            .addCase(projectTypeOptionList.rejected, (state, action) => {
                state.projectType_status = STATUSES.FAILURE;
                state.projectType_error = action.error
            })

            // GET department LISTOption
            .addCase(departmentOptionList.pending, (state, action) => {
                state.department_status = STATUSES.LOADING;
            })
            .addCase(departmentOptionList.fulfilled, (state, action) => {
                state.department_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.department_error = !action.payload.data ? false : true;
                state.departments = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.department_count = action.payload.data.count;
            })
            .addCase(departmentOptionList.rejected, (state, action) => {
                state.department_status = STATUSES.FAILURE;
                state.department_error = action.error
            })

            // GET department domain LISTOption
            .addCase(departmentDomainOptionList.pending, (state, action) => {
                state.departmentDomain_status = STATUSES.LOADING;
            })
            .addCase(departmentDomainOptionList.fulfilled, (state, action) => {
                state.departmentDomain_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.departmentDomain_error = !action.payload.data ? false : true;
                state.departmentDomain = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.departmentDomain_count = action.payload.data.count;
            })
            .addCase(departmentDomainOptionList.rejected, (state, action) => {
                state.departmentDomain_status = STATUSES.FAILURE;
                state.departmentDomain_error = action.error
            })

            // GET workflow LISTOption
            .addCase(workflowOptionList.pending, (state, action) => {
                state.workflow_status = STATUSES.LOADING;
            })
            .addCase(workflowOptionList.fulfilled, (state, action) => {
                state.workflow_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.workflow_error = !action.payload.data ? false : true;
                state.workflows = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.workflow_count = action.payload.data.count;
            })
            .addCase(workflowOptionList.rejected, (state, action) => {
                state.workflow_status = STATUSES.FAILURE;
                state.workflow_error = action.error
            })
    },
});


export default optionsSlice.reducer;