import './phoneCallDetails.scss';
import { ArrowBack } from '@mui/icons-material';
import { TablePagination, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import { myPhoneCallDetailsColumn } from '../../components/datatable-coulms/tableCoulmns'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from '../../redux/common-status/constant';
import { getMyCallLogDetails } from '../../redux/features/callingSlice';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { useLocation, useNavigate, Link, useSearchParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';

const user = LocalStorageDataService.userDetail();
const userId = user && user.id
export const MyCallDetails = () => {

    const localState = useLocation().state;
    const [searchParams] = useSearchParams();
    const urlKey = searchParams.get('key');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const date = moment().format('YYYY-MM-DD')

    const { mycallDetails, status } = useSelector(state => state.callingSlice)
    const { data: list } = mycallDetails

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource":{"value":${userId},"operation": "eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"}}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    let callTypeWiseFilter;
    if (urlKey) {
        if (urlKey === "in-bound") {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"},"direction":{"value":0,"operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`
        } else if (urlKey === "out-bound") {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"},"direction":{"value":1,"operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`
        } else {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`
        }
    } else {
        callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`
    }

    useEffect(() => {
        setFilterQuery(callTypeWiseFilter)
        dispatch(getMyCallLogDetails(callTypeWiseFilter))
    }, [localState?.key])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        dispatch(getMyCallLogDetails(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        dispatch(getMyCallLogDetails(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue


            let filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
            if (urlKey) {
                if (urlKey === "in-bound") {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"direction":{"value":0,"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                } else if (urlKey === "out-bound") {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"direction":{"value":1,"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                } else {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                }
            } else {
                filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
            }
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getMyCallLogDetails(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            // setPageSize(10)
            setFilterQuery(callTypeWiseFilter)
            dispatch(getMyCallLogDetails(callTypeWiseFilter))
        }
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getMyCallLogDetails(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }

    const actions = [
        {
            field: 'project',
            headerName: 'Project Name',
            width: 200,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <Tooltip title="View Project">
                            <Link to={`/view-project`} state={{ id: params.row.project }}>{params.row.project_name}</Link>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: 'story',
            headerName: 'Story Name',
            filterable: false,
            width: 290,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <Tooltip title="View Story">
                            <Link to={`/story-task/${params.row.story}`}>{params.row.story_name}</Link>
                        </Tooltip>
                    </div>
                );
            }
        },
        {
            field: 'recording_url',
            headerName: 'Call Recording Link',
            width: 350,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <ReactAudioPlayer
                            src={params.row.recording_url}
                            autoPlay={false}
                            controls
                            style={{ height: '30px' }}
                        />
                    </div>
                )
            }
        },
    ]

    return (
        <div className="phoneCallListing">
            <Sidebar />
            <div className="phoneCallListingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>My Phone Call Details</h3>
                        <Tooltip title="Back">
                            <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                        </Tooltip>
                    </div>

                    <ToastContainer />
                    {(status === STATUSES.LOADING) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={list && list.rows ? list.rows : []}
                        columns={myPhoneCallDetailsColumn.concat(actions)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

            </div>
        </div>
    )
}
