import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postUploadResume } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import LoaderForm from "../../components/common-function/LoaderForm";
import { AppBar, Autocomplete, Box, Tab, Tabs, TextField } from '@mui/material';
import { getEpicListByprojectId, getTaskType, getResourceWiseProjectList } from "../../redux/features/taskSlice";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dropzone from 'react-dropzone'
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import ResumeList from "../resume/ResumeList";
import ResumeAnalysisReport from "../reports/ResumeAnalysisReport";


const initialFormData = Object.freeze({
    project_id: "",
    epic_id: "",
    file: ""
});

const formErrData = Object.freeze({
    projectErr: "Project is required",
    epicErr: "Epic is required",
    fileErr: "File is required",
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}


const UploadResume = () => {
    // const inputRef = useRef(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, createUploadResume, } = useSelector(state => state.master)
    const { taskTypeList, epicList, resourceWiseProjectList } = useSelector(state => state.task)
    const { resumeList } = useSelector(state => state.resume)
    const [reload, setReload] = useState(false)
    const [fileErr, setFileErr] = useState("")
    const [defaultEpicValue, setDefaultEpicValue] = useState(null)
    const [defaultProjectValue, setDefaultProjectValue] = useState(null)
    const [projectId, setProjectId] = useState('')
    const [tabvalue, settabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        settabValue(newValue);
    };

    useEffect(() => {
        dispatch(getResourceWiseProjectList(`{"filter":{"resource":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"},"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":10}`))
    }, [])

    if (status === STATUSES.FAILURE && error) {
        setReload(false)
        errorHandling(error, navigate)
    }
    if (reload === true && taskTypeList && taskTypeList.data) {
        setDefaultEpicValue(null)
        setReload(false)
        taskTypeList.data.rows && taskTypeList.data.rows.map((option) => {
            if (option.issue_name === 'Epic' || option.issue_name === 'epic') {
                const epic_id = option.id;
                dispatch(getEpicListByprojectId({ "project_id": projectId, "issue_type": epic_id }))
            }
        })
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_createUploadResume` && createUploadResume && createUploadResume.message) {
        if (createUploadResume.status === 200) {
            setFormValues(initialFormData);
            setDefaultEpicValue(null)
            setDefaultProjectValue(null)
            toast.success(createUploadResume.message)
        } else {
            toast.error(createUploadResume.message)
        }
        setReload(false)
    }

    const handleProjectChange = (evt) => {
        if (evt) {
            const { name, value, label } = evt;
            if (name === "project_id") {
                setDefaultProjectValue(label)
                dispatch(getTaskType(value))
                setReload(true)
                setProjectId(value)
            }
            setFormValues({ ...formValues, [name]: value });
        } else {
            // dispatch(getTaskType(null))
            setDefaultProjectValue(null)
            setDefaultEpicValue(null)
            dispatch(getEpicListByprojectId(null))
            setFormValues({ ...formValues, epic_id: "", project_id: "" });
        }

    }

    const handleChange = (evt) => {
        if (evt) {
            const { name, value, label } = evt;
            if (name === 'epic_id') {
                setDefaultEpicValue(label)
            }
            setFormValues({ ...formValues, [name]: value });
        } else {
            setDefaultEpicValue(null)
            setFormValues({ ...formValues, epic_id: null });
        }

    }
    const submitData = (e) => {
        e.preventDefault()

        if (formValues.project_id === "" || formValues.epic_id === "" || formValues.file === "") {
            setErrStatus(true)
        }
        if (formValues.project_id && formValues.epic_id && formValues.file) {
            const formData = new FormData();
            formData.append("file", formValues.file);
            formData.append("project", `${formValues.project_id}`);
            formData.append("epic", `${formValues.epic_id}`);
            dispatch(postUploadResume(formData))
            setReload('submit')

        }
    }


    const onFileChange = (event) => {
        // Update the state      
        let file = event[0]
        const fileType = file.name.split(".")[1];
        if (fileType === "pdf" || fileType === "doc" || fileType === "docx" || fileType === "zip" || fileType === "csv" || fileType === "xlsx") {
            // if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === "application/pdf" || file.type === "application/zip" || file.type === "application/msword") {
            setFileErr("")
            setFormValues({ ...formValues, file: file });
        } else {
            setFormValues({ ...formValues, file: "" });
            setFileErr("You can only upload pdf, docx, doc, zip, csv and xlsx files")
        }
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Upload Resume</h3>

                        <Tooltip title="Back">
                            <button onClick={() => navigate(-1)} className="btn btn-add-new"><ArrowBackIcon /></button>
                        </Tooltip>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <form>
                        <div className="add-new-user-form" style={{ marginTop: "20px" }}>
                            <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                <label style={{ marginBottom: "15px", display: "block" }} >
                                    Project
                                </label>
                                <Autocomplete
                                    style={{ width: "100%" }}
                                    value={defaultProjectValue}
                                    id="controllable-states-demo"
                                    onChange={(event, newValue) => {
                                        handleProjectChange(newValue);
                                    }}

                                    options={resourceWiseProjectList && resourceWiseProjectList.map((e, key) => {
                                        return { key: e.id, name: "project_id", value: e.id, label: e.project_name }
                                    })}
                                    // sx={{ width: 400 }}
                                    renderInput={(params) => <TextField {...params} label="Select Project" />}
                                />

                                {errStatus === true && formValues && formValues.project_id === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.projectErr}</p>}
                            </div>

                            <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                <label style={{ marginBottom: "15px", display: "block" }}>
                                    Epic
                                </label>
                                {defaultProjectValue ?
                                    <Autocomplete
                                        value={defaultEpicValue}
                                        style={{ width: "100%" }}
                                        id="controllable-states-demo"
                                        onChange={(event, newValue) => {
                                            handleChange(newValue);
                                        }}
                                        options={
                                            epicList && epicList.data && epicList.data.rows.map((option) => {
                                                return { label: option.summary + "(" + option.ticket_key + ")", value: option.id, name: "epic_id" }
                                            })
                                        }
                                        renderInput={(params) => <TextField {...params} label="Select Epic" />}
                                    />
                                    :
                                    <Autocomplete
                                        value={defaultEpicValue}
                                        style={{ width: "100%" }}
                                        id="controllable-states-demo"
                                        disabled
                                        renderInput={(params) => <TextField {...params} label="Select Epic" />}
                                    />
                                }
                                {errStatus === true && formValues && formValues.epic_id === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.epicErr}</p>}
                            </div>
                            {defaultProjectValue && defaultEpicValue &&
                                <div className="formInput">
                                    <label >
                                    Upload Resume(Pdf/Zip/Docx/Doc/Csv/Xlsx)
                                    </label>
                                    <div className="file-dropzone-tabs">
                                        <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>{formValues.file ? formValues.file.name : "Drag 'n' drop some files here, or click to select files"}</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                    {/* <input ref={inputRef} type="file" name="file" onChange={onFileChange} /> */}
                                    {errStatus === true && formValues && formValues.file === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.fileErr}</p>}
                                    {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}

                                </div>
                            }
                        </div>
                        <Button className="submit-modal" onClick={submitData}>{CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                    </form>

                    <Box className="resume-tabs" sx={{ bgcolor: 'background.paper', width: '100%', marginTop: '10px' }}>
                        <AppBar position="static" style={{ backgroundColor: '#e7e7e7', color: '#000', borderRadius: '6px' }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab
                                    value={0}
                                    label="Resume Analysis Report"
                                />
                                <Tab value={1} label={`Failed Resume List(${resumeList.data && resumeList.data.count ? resumeList.data.count : 0})`} />

                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabvalue} index={1} className="custom-loader-tabs" >
                            <ResumeList />
                        </TabPanel>
                        <TabPanel value={tabvalue} index={0} className="custom-loader-tabs" >
                            <ResumeAnalysisReport type={'tab'} />
                        </TabPanel>
                    </Box>
                </div>
            </div >
        </div >
    )
}

export default UploadResume