import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./marketing.scss"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResourceRoleWise, getSourceTypeList, postClientCreate } from "../../redux/features/clientSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import { Tooltip } from '@mui/material';
import Multiselect from 'multiselect-react-dropdown';
import { AddCircleOutline, ArrowBack, Attachment, RemoveCircleOutline } from "@mui/icons-material";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { workFlowList, workflowMappingForWorkflowId } from "../../redux/features/workflowSlice"
import { useEffect } from "react";
import Select from 'react-select';


const initialFormData = Object.freeze({
    client_name: "",
    organization: "",
    contact_person: [],
    source_url: [],
    description: "",
    client_contact: "",
    client_email: "",
    status_id: "",
    assignee_id: "",
    workflow_id: "",
    client_status: 1

});

const formErrData = Object.freeze({
    clientNameErr: "Name is required",
    clientEmailErr: "Email is required",
    clientContactErr: "Contact is required",
    clientStatusIdErr: "STatus is required",
    clientWorkflowErr: "Workflow is required",
    organizationErr: "Organization is required",
    contactPersonErr: "Contact details is required",
    sourceUrlErr: "Status is required"
});



function readFileDataAsBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsDataURL(file);
    });
}


const ClientAdd = () => {
    const { REACT_APP_ENV } = process.env;
    const uploadInputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, clientCreate, resourceMarketingList, masterSourceTypeList } = useSelector(state => state.client)
    const { workflows, workflow_StatusMapping } = useSelector(state => state.workflow)

    const [reload, setReload] = useState(false)
    const [contactPerson, setContactPerson] = useState([])
    const [sourceUrl, setSourceUrl] = useState([])
    const [contactPersonErr, setContactPersonErr] = useState("")
    const [validateErr, setValidateErr] = useState("")


    if (status === STATUSES.FAILURE && error) {
        setReload(false)
        errorHandling(error, navigate)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_clientCreate` && clientCreate && clientCreate.message) {
        if (clientCreate.status === 200) {
            toast.success(clientCreate.message)
        } else {
            toast.error(clientCreate.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === 'workflow_id') {
            dispatch(workflowMappingForWorkflowId(value))
            setFormValues({ ...formValues, [name]: value, ['status_id']: '' });
        }else{
            setFormValues({ ...formValues, [name]: value });

        }

    }


    const addMoreContactPerson = () => {
        //setFormValues({ ...formValues, contact_person: evt })
        if (contactPerson.length > 0) {
            setContactPerson([...contactPerson,
            {
                name: "",
                contact: "",
                email: "",
                skyup: ""
            }]
            )

        } else {
            setContactPerson([
                {
                    name: "",
                    contact: "",
                    email: "",
                    skyup: ""
                }
            ])
        }
    }

    const removeContactPerson = (i) => {
        const updateformData = contactPerson.filter((item, index) => index !== i);
        setContactPerson(updateformData)
    }


    const addMoreSourceUrl = (evt) => {
        let array = []
        setFormValues({ ...formValues, source_url: evt })
        evt.map(e => {
            let filterExp = sourceUrl.filter(x => x.type === e.source_type_name_key);
            return array.push({
                id: e.id,
                type: e.source_type_name_key,
                label: e.source_type_name,
                label_value: filterExp.length > 0 ? filterExp[0].label_value : "",
                file: filterExp.length > 0 ? filterExp[0].file : "",
                convertFile: filterExp.length > 0 ? filterExp[0].convertFile : "",
            })
        })

        setTimeout(() => {
            setSourceUrl(array)
        }, 500);


    }

    const removeSourceUrl = (i) => {
        const updateformData = sourceUrl.filter((item, index) => index !== i);
        const updateFormValues = formValues && formValues.source_url.filter((item, index) => index !== i);
        setFormValues({ ...formValues, source_url: updateFormValues })
        setSourceUrl(updateformData)
    }

    const handleContactPersonChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = contactPerson.length > 0 && contactPerson.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setContactPerson([...valueArray])
    }
    const handleSourceUrlChange = async (evt, index = null) => {
        const { name, value, files } = evt.target || evt;
        const fileArray = sourceUrl.length > 0 && sourceUrl.filter((item, i) => i === index && item.file !== "" && item.file)
        let array = fileArray.length > 0 ? fileArray[0].file : [];
        let convertarray = fileArray.length > 0 ? fileArray[0].convertFile : [];
        if (files && files.length > 0) {
            let convertFile = await readFileDataAsBase64(files[0])
            convertarray.push(convertFile)
            array.push(files[0])
            const valueArray = sourceUrl.length > 0 && sourceUrl.map((item, i) => i === index ?
                { ...item, ['file']: array, ['convertFile']: convertarray } : item)
            index !== null && setSourceUrl([...valueArray])
        } else {
            const valueArray = sourceUrl.length > 0 && sourceUrl.map((item, i) => i === index ?
                { ...item, [name]: value } : item)
            index !== null && setSourceUrl([...valueArray])
        }

    }

    useEffect(() => {
        dispatch(workFlowList({ "order_by": { "column": "-id" }, "no_limit": "True" }))
        dispatch(getResourceRoleWise())
        dispatch(getSourceTypeList({ "filter": { "status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "-id" }, "no_limit": "True" }))
    }, [])


    const validate = (email, contact) => {
        const errors = {}

        if (!email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            errors.email = ""
        }

        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10) {
            errors.contact = "Please enter valid  Mobile Number.";
        } else {
            errors.contact = ""
        }

        return errors
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.client_name === "" || formValues.organization === "" || formValues.client_email === "" ||
            formValues.client_contact === "" || formValues.status_id === "" || formValues.workflow_id === "" ||
            formValues.source_url.length === 0) {
            setErrStatus(true)
        }
        // if (contactPerson.length > 0) {
        //     let status = false
        //     let filterArray = contactPerson.filter(x => x.name !== null && x)
        //     if (filterArray.length > 0) {
        //         filterArray.map(item => {
        //             if (item.name && item.email && item.contact) {
        //                 const isValidate = validate(item.email, item.contact);
        //                 if (isValidate.email || isValidate.contact) {
        //                     setErrStatus('contact_err')
        //                     setValidateErr(isValidate)
        //                 } else {
        //                     status = true
        //                 }

        //             }
        //         })
        //     }
        // }
        // if (status === false) {
        //     setErrStatus('contact_err')
        //     setContactPersonErr("Please fill atleast one contact person details(name, email and contact)")
        // } else {
        if (formValues.client_name && formValues.organization && formValues.client_email && formValues.client_contact &&
            formValues.workflow_id && formValues.source_url.length > 0) {

            const isValidate = validate(formValues.client_email, formValues.client_contact);
            if (isValidate.email || isValidate.contact) {
                setErrStatus('contact_err')
                setValidateErr(isValidate)
            } else {
                let source_type_array = []
                sourceUrl.length > 0 && sourceUrl.map(item => {
                    return source_type_array.push({
                        source_type_id: item.id,
                        value: item.label_value,
                        file: item.convertFile,
                    })
                })
                const userId = GetLocalstorage.userDetail().id
                let addvalues = { ...formValues, name: formValues.client_name, created_by: userId, contact_person: contactPerson, source_url: source_type_array, reported_by_id: userId }
                delete addvalues.client_name;
                console.log("addvalues", addvalues, JSON.stringify(addvalues));
                setErrStatus(false)
                setReload(true)
                setValidateErr("")
                setContactPersonErr("")
                dispatch(postClientCreate(addvalues)) 
            }

            // }
            // } else {
            //     setErrStatus(true)
            //     setValidateErr("")
            //     setContactPersonErr("")
            // }

        }
    }
    //console.log("formValues", formValues);
    return (
        <div className="task">
            <Sidebar />
            <div className="taskContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>Add Client</h3>
                    <Tooltip title="Back">
                        <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBack /></button>
                    </Tooltip>
                </div>
                <ToastContainer />
                <div>
                    <form>
                        <div className="add-new-user-form">
                            <div className="formInput">
                                <label >
                                    Client Name
                                </label>
                                <input
                                    name="client_name"
                                    defaultValue={formValues.client_name}
                                    type="text"
                                    placeholder="Name"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && formValues.client_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.clientNameErr}</p>}
                            </div>
                            <div className="formInput">
                                <label >
                                    Client Email
                                </label>
                                <input
                                    name="client_email"
                                    defaultValue={formValues.client_email}
                                    type="text"
                                    placeholder="test@gmail.com"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && formValues.client_email === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.clientEmailErr}</p>}
                                {errStatus === 'contact_err' && validateErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr.email}</p>}

                            </div>
                            <div className="formInput">
                                <label >
                                    Client Contact Number
                                </label>
                                <input
                                    name="client_contact"
                                    defaultValue={formValues.client_contact}
                                    type="phone"
                                    placeholder="0000000"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && formValues.client_contact === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.clientContactErr}</p>}
                                {errStatus === 'contact_err' && validateErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr.contact}</p>}

                            </div>

                            <div className="formInput">
                                <label >
                                    Organization
                                </label>
                                <input
                                    name="organization"
                                    defaultValue={formValues.organization}
                                    type="text"
                                    placeholder="Organization"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && formValues.organization === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.organizationErr}</p>}
                            </div>
                            <div className="formInput">
                                <label >
                                    Assignee
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    defaultValue={formValues.assignee_id}
                                    isSearchable={true}
                                    name={"assignee_id"}
                                    options={
                                        resourceMarketingList.map((option) => {
                                            return { label: option.name, value: option.id, name: "assignee_id" }
                                        })
                                    }
                                    onChange={handleChange}
                                />
                                {/* {errStatus === true && formValues && formValues.assignee_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>} */}
                            </div>

                            <div className="formInput">
                                <label >
                                    Workflow
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    defaultValue={formValues.workflow_id}
                                    isSearchable={true}
                                    name={"workflow_id"}
                                    options={
                                        workflows.map((option) => {
                                            return { label: option.name, value: option.id, name: "workflow_id" }
                                        })
                                    }
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && formValues.workflow_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.clientWorkflowErr}</p>}
                            </div>
                            <div className="formInput">
                                <label >
                                    Status
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    defaultValue={formValues.status_id}
                                    isSearchable={true}
                                    name={"status_id"}
                                    options={
                                        workflow_StatusMapping.map((option) => {
                                            return { label: option.workflow_status.name, value: option.workflow_status_id, name: "status_id" }
                                        })
                                    }
                                    onChange={handleChange}
                                />
                                {/* {errStatus === true && formValues && formValues.assignee_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>} */}
                            </div>
                            <div className="formInput" style={{ flexBasis: '100%' }}>
                                <label >
                                    Client Source URL
                                </label>

                                <Multiselect
                                    isObject={true}
                                    onRemove={addMoreSourceUrl}
                                    onSelect={addMoreSourceUrl}
                                    displayValue={['source_type_name']}
                                    selectedValues={formValues.source_url}
                                    options={masterSourceTypeList}
                                />
                                {errStatus === true && formValues && formValues.source_url.length === 0 && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.sourceUrlErr}</p>}

                            </div>

                            {/* <div className="formInput" style={{ flexBasis: '100%' }}>
                                <span style={{ display: "flex", alignItems: "center" }} >Client Source URL <AddCircleOutline onClick={() => addMoreSourceUrl()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} /> </span>
                            </div> */}
                            {
                                sourceUrl.map((item, i) => {
                                    return (
                                        <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>
                                            <div className="formInput" style={{ flexBasis: '45%' }}>
                                                <label >
                                                    {item.label}
                                                </label>
                                                <input
                                                    name={'label_value'}
                                                    onChange={(e) => handleSourceUrlChange(e, i)}
                                                    placeholder="text here...."
                                                    value={item.label_value}
                                                />
                                            </div>
                                            {(item.type === 'cold-calling' || item.type === 'old-customer' || item.type === 'referral' || item.type === 'enquiry-through-website') &&
                                                <div className="formInput" style={{ flexBasis: '10%', marginTop: '2%' }}>
                                                    <div className="file-attchment-icon-btn">
                                                        <input
                                                            id='fileUpload'
                                                            ref={uploadInputRef}
                                                            type='file'
                                                            multiple
                                                            // accept='application/pdf, image/png,image/jpeg'
                                                            style={{ display: "none" }}
                                                            onChange={(e) => handleSourceUrlChange(e, i)}
                                                            name="file"
                                                        />
                                                        <Tooltip title="Attach File">
                                                            <Attachment
                                                                style={{ cursor: 'pointer', color: '#0096fb' }}
                                                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={item.file && item.file.length > 0 && item.file.map((itm, index) => {
                                                                return (<p key={index}>{itm.name}</p>)
                                                            })}>
                                                            <p>{item.file && item.file.length}</p>
                                                        </Tooltip>


                                                    </div>
                                                </div>}


                                            <div className="formInput" style={{ flexBasis: '40%', marginTop: '3%' }}>
                                                <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeSourceUrl(i)} />
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            <div className="formInput" style={{ flexBasis: '100%' }}>
                                <span style={{ display: "flex", alignItems: "center" }} >Contact Person <AddCircleOutline onClick={() => addMoreContactPerson()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} /> </span>
                                {/* {errStatus === true && contactPerson.length === 0 && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.contactPersonErr}</p>} */}
                                {/* {errStatus === 'contact_err' && contactPersonErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{contactPersonErr}</p>} */}
                                {/* {errStatus === 'contact_err' && validateErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr.email}<br />{validateErr.contact}</p>} */}

                            </div>
                            {
                                contactPerson.map((item, i) => {
                                    return (
                                        <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>

                                            <div className="formInput" style={{ flexBasis: '15%' }}>
                                                <label >
                                                    Name
                                                </label>
                                                <input
                                                    name={"name"}
                                                    onChange={(e) => handleContactPersonChange(e, i)}
                                                    type="text"
                                                    placeholder="Name"
                                                    value={item.name}
                                                />
                                            </div>

                                            <div className="formInput" style={{ flexBasis: '15%' }}>
                                                <label >
                                                    Email
                                                </label>
                                                <input
                                                    name={"email"}
                                                    onChange={(e) => handleContactPersonChange(e, i)}
                                                    placeholder="example@gmail.com"
                                                    value={item.email}
                                                    type="email"
                                                />
                                            </div>

                                            <div className="formInput" style={{ flexBasis: '15%' }}>
                                                <label >
                                                    Contact
                                                </label>
                                                <input
                                                    name={"contact"}
                                                    onChange={(e) => handleContactPersonChange(e, i)}
                                                    placeholder="000000"
                                                    value={item.contact}
                                                />
                                            </div>
                                            <div className="formInput" style={{ flexBasis: '15%' }}>
                                                <label >
                                                    Skyup
                                                </label>
                                                <input
                                                    name={"skyup"}
                                                    onChange={(e) => handleContactPersonChange(e, i)}
                                                    placeholder="example@skyup.com"
                                                    value={item.skyup}
                                                />
                                            </div>

                                            <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeContactPerson(i)} />
                                            </div>

                                        </div>
                                    )
                                })
                            }


                            <div className="formInputDescription">
                                <label >
                                    Description
                                </label>

                                <textarea defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Text here..."></textarea>
                            </div>

                        </div>
                        <button className="submit-modal" onClick={submitData}>{CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default ClientAdd