import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./task.scss";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import CONSTANTS from "../../components/constant/constantComponents";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import { getSprintList, getTaskStoryList, getTaskType, jireStatus, main_Project_List, postTaskCreate, postTeamMemberCreate, resourceProjectList, taskWorkFlowStatusList } from "../../redux/features/taskSlice";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm";
import React from "react";
import { postUploadResume } from "../../redux/features/masterSlice";
import { InputButton, InputFields, InputMultiSelectField, InputSelectField, InputSelectValueField, InputTextareaField } from "../../components/common-function/InputFields";
import { TaskModal } from "./TaskModal";
// import Select from 'react-select';
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const initialFormData = Object.freeze({
    project: "",
    sprint: "",
    assignee: "",
    reporter: LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().display_name,
    t_status: "",
    t_description: "",
    task_type: "",
    priority: "",
    summary: "",
    epic_id: "",
    team_members: "",
    file: "",
    is_jira_issue: false
});


const formErrData = Object.freeze({
    projectErr: " Project Name is required",
    sprintErr: "Sprint is required",
    assigneeErr: "Assignee is required",
    reporterErr: "Reporter  is required",
    t_statusErr: "Status is required",
    t_descriptionErr: "Task Description is required",
    task_typeErr: "Task Type  is required",
    priorityErr: " Priority is required",
    summaryErr: "Summary is Required",
    is_jira_issueErr: "Jira Issue is required"
});

export const Task = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const urlKey = location && location.state;
    const [formValues, setFormValues] = useState(initialFormData)
    const [errStatus, setErrStatus] = useState(false)
    const [activeEpicField, setActiveEpicField] = useState(false)
    const [activeStoryField, setActiveStoryField] = useState(false)
    const [activeResourceField, setActiveResourceField] = useState(false)
    // const [fileErr, setFileErr] = useState("")
    // const [activeId, setActiveId] = useState(false)
    const [syncLoading, setSyncLoading] = useState(false)
    const [formErr] = useState(formErrData)
    const { error, status, mainProjectList, jireStatusList, taskTypeList, createTask, updateTaskData, taskStoryList, resourceProject_list, createTeamMember, taskList, workFlowStatusList } = useSelector(state => state.task)
    const { data } = useSelector(state => state.resource)
    const { createUploadResume } = useSelector(state => state.master)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedTaskTypeOption, setSelectedTaskTypeOption] = useState(null);
    // const [role_key] = LocalStorageDataService.userDetail().tms_role.role_key;
    const [projectDomain, setProjectDomain] = useState([])
    const [projectDomainId, setProjectDomainId] = useState(null)
    const [makeTeamMemberData, setMakeTeamMemberData] = useState("")
    const [makeFileData, setMakeFileData] = useState("")
    const [activeUploadModal, setActiveUploadModal] = useState(false)
    const [activeStoryModal, setActiveStoryModal] = useState(false)
    const [epicId, setEpicId] = useState("")
    const [workflowStatus, setWorkflowStatus] = useState(false)
    const [workflowStatusList, setWorkflowStatusList] = useState([])

    // Form Change Handler
    const handleTeamChange = (evt) => {
        setFormValues({ ...formValues, 'team_members': evt })
    }

    const checkActiveFileOption = (data, type) => {
        let project_list = mainProjectList && mainProjectList.data && mainProjectList.data.rows.length > 0 ? mainProjectList.data.rows : [];
        let fiterProject = project_list.filter(x => x.id === formValues.project)
        let projectisjira = false
        if (fiterProject.length > 0) {
            projectisjira = fiterProject[0].is_jira_project;
        }
        if (projectisjira === false) {
            const task_type = type === 'task_type' ? data : formValues.task_type
            const domain = type === 'domain_type' ? data.label : projectDomainId && projectDomainId.label
            if (task_type && domain) {
                // let result = text.match("recruitment");
                let result = domain.match("recruitment") || domain.match("Recruitment") || domain.match("job");
                if (result) {
                    setActiveEpicField(true)
                } else {
                    setActiveEpicField(false)
                }
            }

        }

    }

    const handleDomainChange = (evt) => {
        setProjectDomainId(evt)
        checkActiveFileOption(evt, 'domain_type')
        setFormValues({ ...formValues, 'domain_id': evt.value })
    }

    const domainProject = (id) => {
        setProjectDomainId("")
        let list = mainProjectList && mainProjectList.data && mainProjectList.data.rows;
        let filterDomain = list.filter(x => x.id === id)
        if (filterDomain.length > 0) {
            if (filterDomain[0] && filterDomain[0].domains && filterDomain[0].domains.length > 0) {
                setProjectDomain(filterDomain[0].domains)
            } else {
                setProjectDomain([])
            }
            if (filterDomain[0] && filterDomain[0].workflow_id) {
                setWorkflowStatus(true)
                dispatch(taskWorkFlowStatusList(`{"filter":{"workflow_id":{"value":${filterDomain[0].workflow_id}, "operation":"eq"}},  "order_by":{"column":"-id"}, "no_limit":True}`))

            } else {
                setWorkflowStatus(true)
            }
        }
        if (filterDomain.length === 0) {
            setWorkflowStatus(true)
            setProjectDomain([])
        }
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === "project" && value > 0) {
            // setActiveId(true)
            dispatch(getTaskType(value))
            domainProject(value)
            setSelectedTaskTypeOption(null)
            setSelectedOption(null)
            setFormValues({ ...formValues, [name]: value, task_type: "" });
        } else {
            if (name === "task_type" && value > 0) {
                checkEpicType(value)
                setSelectedTaskTypeOption(value)
                setFormValues({ ...formValues, [name]: value, epic_id: "" });
                setSelectedOption(null)
            }
            else if (name === 't_status') {
                setWorkflowStatus(true)
                dispatch(taskWorkFlowStatusList(`{"filter":{"workflow_id":{"value":${value}, "operation":"eq"}},  "order_by":{"column":"-id"}, "no_limit":True}`))
                setFormValues({ ...formValues, [name]: value });
            }

            else {
                setFormValues({ ...formValues, [name]: value });
            }

        }
        // if (name === "project" && value === 'Select') {
        //     dispatch(getTaskType(null))
        //     // setActiveId(false)
        // }


    }
    function removeDuplicates(arr) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    //useEffect(()=>{
    if (workflowStatus === true) {
        let workflowlist = workFlowStatusList && workFlowStatusList.data && workFlowStatusList.data.rows
        let array = []
        if (workflowlist && workflowlist.length > 0 && jireStatusList && jireStatusList.length > 0) {
            workflowlist.map(e => {
                if (e.related_status && e.related_status.length > 0) {
                    e.related_status.map(item => {
                        let filterStatus = jireStatusList.filter(x => x.id === item)
                        if (filterStatus.length > 0) {
                            array.push(filterStatus[0])
                        }
                        return array
                    })
                }
                return array
            })
            let array1 = removeDuplicates(array)
            setWorkflowStatusList(array1)
            setWorkflowStatus(false)
        }
        if (workflowlist && workflowlist.length === 0 && jireStatusList && jireStatusList.length > 0 && workflowStatusList && workflowStatusList.length === 0) {
            setWorkflowStatus(false)
            setWorkflowStatusList(jireStatusList)
        }


    }
    //},[workflowStatus])



    const taskTypeChange = (evt) => {
        checkEpicType(evt.value)
        setSelectedTaskTypeOption(evt)
        setFormValues({ ...formValues, task_type: evt.value });
        setSelectedOption(null)
    }

    const checkEpicType = (id) => {
        const tasktype_list = taskTypeList && taskTypeList.data && taskTypeList.data.rows.length > 0 ? taskTypeList.data.rows : []
        const filterForEpic = `{"filter":{"project":{"value":${formValues.project},"operation":"eq"}, "issue_type":{"value":${id},"operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`;
        const filterForProject = `{"filter":{"project":{"value":${formValues.project},"operation":"eq"}},  "order_by":{"column":"-id"},"no_limit":True}`;

        let filterEpicType = tasktype_list.filter(x => x.id === id && (x.issue_name === 'Epic' || x.issue_name === 'epic'))
        let filterStoryType = tasktype_list.filter(x => x.id === id && (x.issue_name === 'Story' || x.issue_name === 'story'))
        if (filterStoryType.length > 0) {
            //const filterForEpic = `{"filter":{"project":{"value":${formValues.project},"operation":"eq"}, "issue_type":{"value":${id},"operation":"eq"}}, "order_by":{"column":"-created_on"}, "no_limit":True}`;
            dispatch(getTaskStoryList(filterForEpic))
            // formValues.assignee = LocalStorageDataService.userDetail().display_name
            // formValues.file = ""
            // setFormValues({ ...formValues, assignee: LocalStorageDataService.userDetail().display_name, file: "" })
            setActiveEpicField(false)
            setActiveResourceField(true)
            setActiveStoryField(true)
        }
        if (filterEpicType.length > 0) {
            //const filterForEpic = `{"filter":{"project":{"value":${formValues.project},"operation":"eq"}, "issue_type":{"value":${id},"operation":"eq"}}, "order_by":{"column":"-created_on"}, "no_limit":True}`;
            dispatch(getTaskStoryList(filterForEpic))
            //setActiveEpicField(true)
            formValues.assignee = LocalStorageDataService.userDetail().display_name
            formValues.file = ""
            setFormValues({ ...formValues, assignee: LocalStorageDataService.userDetail().display_name, file: "" })
            checkActiveFileOption(id, 'task_type')
            setActiveResourceField(true)
            setActiveStoryField(false)
            dispatch(resourceProjectList(filterForProject))
            setFormValues({ ...formValues, assignee: "" })

        }
        if (filterEpicType.length === 0) {
            setActiveEpicField(false)
            setActiveResourceField(false)
            setFormValues({ ...formValues, assignee: "" })

        }
        if (filterStoryType.length === 0) {
            setActiveStoryField(false)
            setFormValues({ ...formValues, assignee: "", })

        }
    }



    useEffect(() => {

        if (status === STATUSES.FAILURE) {
            toast.error("Some Server Issue ! ")
            errorHandling(error, navigate)
        }
        dispatch(main_Project_List())
        dispatch(resourceList('{"order_by":{"column":"-id"},"no_limit":True}'))
        dispatch(jireStatus(`{"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getSprintList())
        //dispatch(getTaskList(`{"filter":{"epic_id":{"value":892, "operation":"eq"}},"order_by":{"column":"-id"}}`))

    }, [])

    const submitData = (e) => {
        e.preventDefault()
        //formValues.assignee === ""
        if (formValues.project === "" || formValues.task_type === "" ||
            formValues.reporter === "" || formValues.t_status === "" || formValues.summary === "") {
            setErrStatus(true)
        }
        //formValues.assignee !== "" &&
        if (formValues.project !== "" && formValues.task_type !== "" &&
            formValues.reporter !== "" && formValues.summary !== "" && formValues.t_status !== "") {

            let addvalues = {
                reported_by: LocalStorageDataService.userDetail().id,
                assignee: formValues.assignee > 0 ? formValues.assignee : LocalStorageDataService.userDetail().id,
                description: formValues.t_description,
                summary: formValues.summary,
                issue_type: selectedTaskTypeOption ? selectedTaskTypeOption.value : "",
                parent_issue_id: 0,
                story_point: 0,
                sprint: formValues.sprint,
                fixed_version: "",
                labels: ["API", "UI"],
                priority: formValues.priority,
                original_estimate: 0,
                component: [],
                is_jira_issue: formValues.is_jira_issue,
                project: formValues.project,
                status: formValues.t_status,
                created_by: LocalStorageDataService.userDetail().id,
                epic_id: selectedOption ? selectedOption.value : "",
                domain_id: projectDomainId ? projectDomainId.value : ""

            }

            if (formValues.file) {
                let fileData = {
                    "file": formValues.file,
                    "project_id": addvalues.project,
                    "epic_id": "",
                }
                // const formData = new FormData();
                // formData.append("file", formValues.file);
                // formData.append("project_id", `${addvalues.project}`);
                // formData.append("epic_id", `${addvalues.epic_id}`);
                setMakeFileData(fileData)
                //dispatch(postUploadResume(formData))
            }
            if (formValues.team_members) {
                let uniqueObjArray = [...new Map(formValues.team_members.map((item) => [item["id"], item.id])).values()];
                //uniqueObjArray.length > 0 && uniqueObjArray.map(e => {
                let teamMemberData = {
                    "jira_rtms_issue": formValues.is_jira_issue === false ? "" : formValues.is_jira_issue,
                    "resource": uniqueObjArray,
                    "status": 1,
                    "created_by": LocalStorageDataService.userDetail().id
                }

                setMakeTeamMemberData(teamMemberData)
                //dispatch(postTeamMemberCreate(teamMemberData))
                // })
            }
            setErrStatus(false)
            setSyncLoading(true)
            dispatch(postTaskCreate(addvalues))
        }

    }

    const resumeUpload = (e) => {
        e.preventDefault()
        setActiveUploadModal(false)
    }

    if (syncLoading === true && createTask && createTask.message && status === STATUSES.SUCCESS) {
        if (createTask.status === 200) {
            if (createTask.data) {
                setEpicId(createTask.data.id)
                if (makeTeamMemberData) {
                    makeTeamMemberData['jira_rtms_issue'] = createTask.data.id
                    dispatch(postTeamMemberCreate(makeTeamMemberData))
                }
                if (makeFileData) {
                    makeFileData['epic_id'] = createTask.data.id
                    const formData = new FormData();
                    formData.append("file", makeFileData.file);
                    formData.append("project", `${makeFileData.project_id}`);
                    formData.append("epic", `${makeFileData.epic_id}`);
                    dispatch(postUploadResume(formData))
                }

            }
            setTimeout(() => {
                // goback()
                if (urlKey && urlKey === "task") {
                    navigate('/task')
                } else if (urlKey === "reported-task") {
                    navigate('/reported-task')
                } else {
                    navigate('/my-task')
                }
            }, 2000);
            toast.success(createTask.message)
            setSyncLoading(false)
            //}

        } else {
            setSyncLoading(false)
            toast(createTask.message)
        }
    }

    if (createTeamMember && createTeamMember.message && status === `${STATUSES.SUCCESS}_createTeamMember` && makeTeamMemberData) {
        setMakeTeamMemberData("")
    }
    if (epicId && createUploadResume && createUploadResume.message && makeFileData) {
        // if (createUploadResume.status === 200) {
        //     dispatch(getTaskList(`{"filter":{"epic_id":{"value":${epicId}, "operation":"eq"}}}`))
        //     setActiveStoryModal(true)
        // }
        setMakeFileData("")
    }

    if (syncLoading === true && status === STATUSES.SUCCESS && updateTaskData && updateTaskData.message) {
        if (updateTaskData.message === "Success") {
            toast.success(updateTaskData.message)
        } else {
            toast(updateTaskData.message)
        }
        setSyncLoading(false)
        setTimeout(() => {
            if (urlKey && urlKey === "task") {
                navigate('/task')
            } else if (urlKey === "reported-task") {
                navigate('/reported-task')
            } else {
                navigate('/my-task')
            }
        }, 2000);
    }

    // const openActiveUploadModal = (e) => {
    //     e.preventDefault()
    //     setActiveUploadModal(true)
    // }

    const closeActiveUploadModal = () => {
        setActiveStoryModal(false)
        if (urlKey && urlKey === "task") {
            navigate('/task')
        } else if (urlKey === "reported-task") {
            navigate('/reported-task')
        } else {
            navigate('/my-task')
        }

    }

    const onFileChange = (event) => {
        // Update the state      
        // if(file.type === "image/png" || file.type === 'image/jpeg' || file.type === "image/jpg" || file.type === "text/csv" || file.type === "application/zip"){
        let file = event.target.files[0]
        //let array = []
        if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === "application/pdf" || file.type === "application/zip") {
            // setFileErr("")
            if (formValues.file.length === 0) {
                setFormValues({ ...formValues, file: file });
            }
        } else {
            // setFileErr("You can only upload pdf and doc files")
        }
    }

    const activeLink = (params) => {
        setSyncLoading(true)
        setEpicId("")
        setActiveStoryModal(false)
        setTimeout(() => {
            setSyncLoading(false)
            navigate(`/task/edit/${params.row.id}`)
        }, 2000);

    }

    return (
        <div className="task">
            <Sidebar />
            <div className="taskContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>Add Task</h3>
                    {/* <button onClick={() => navigate(-1)} className="btn btn-add-new">Back</button> */}
                    <Tooltip title="Back">
                        <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
                    </Tooltip>
                </div>
                <ToastContainer />
                {syncLoading === true && <LoaderForm />}
                {status === `${STATUSES.LOADING}_taskStoryList` && <LoaderForm />}
                <form>
                    <div className="add-new-user-form">
                        <InputSelectField
                            label={'Project'}
                            placeholder="Select"
                            defaultValue={formValues.project}
                            name={"project"}
                            onChange={handleChange}
                            optionList={mainProjectList && mainProjectList.data && mainProjectList.data.rows.length > 0 ? mainProjectList.data.rows : []}
                            optionLevel={['project_name']}
                            optionValue={"id"}
                            errStatus={errStatus}
                            formErr={formErr.projectErr}
                        />
                        {projectDomain.length > 0 &&
                            <InputSelectValueField
                                label={'Project Domain'}
                                placeholder="Select"
                                defaultValue={projectDomainId}
                                name={"domain_id"}
                                onChange={handleDomainChange}
                                optionList={projectDomain && projectDomain.length > 0 ? projectDomain : []}
                                optionLevel={['name']}
                                optionValue={"id"}
                            // errStatus={errStatus}
                            // formErr={formErr.projectErr}
                            />
                        }

                        <InputSelectValueField
                            label={'Task Type'}
                            placeholder="Select"
                            defaultValue={selectedTaskTypeOption}
                            name={"task_type"}
                            onChange={taskTypeChange}
                            optionList={taskTypeList && taskTypeList.data && taskTypeList.data.rows.length > 0 ? taskTypeList.data.rows : []}
                            optionLevel={['issue_name']}
                            optionValue={"id"}
                            errStatus={errStatus}
                            formErr={formErr.task_typeErr}
                        />
                        {(activeStoryField === true) &&
                            <InputSelectValueField
                                label={'Related Epic'}
                                placeholder="Select"
                                defaultValue={selectedOption}
                                name={"epic_id"}
                                onChange={setSelectedOption}
                                optionList={taskStoryList && taskStoryList.length > 0 ? taskStoryList : []}
                                optionLevel={['summary', 'ticket_key']}
                                optionValue={"id"}
                                errStatus={errStatus}
                                formErr={formErr.task_typeErr}
                            />

                        }
                        {activeResourceField === true && selectedTaskTypeOption !== null &&
                            <InputMultiSelectField
                                label={'Team Members'}
                                isObject={true}
                                onRemove={handleTeamChange}
                                onSelect={handleTeamChange}
                                optionLevel={["display_name"]}
                                defaultValue={formValues.team_members}
                                optionList={resourceProject_list}
                            />

                        }
                        {activeEpicField === true &&
                            <InputFields
                                label={'Upload File(PDF/Docx)'}
                                name="file"
                                type="file"
                                placeholder="Upload File"
                                onChange={onFileChange}
                            // formErr={fileErr}
                            // errStatus={errStatus}
                            />

                        }

                        {/* {activeEpicField === true &&
                            <div className="formInput">
                                <label >
                                    Upload Docx/PDX
                                </label>
                                <InputButton onSubmit={openActiveUploadModal} label={'open'} />
                            </div>

                        } */}
                        {/* <div className="formInput">
                            <label >
                                Sprint
                            </label>
                            <select name="sprint" value={formValues.sprint} onChange={handleChange}>
                                <option>Select</option>

                                {sprintList && sprintList.data && sprintList.data.rows.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.sprint_name}</option>

                                })}
                            </select> 
                            {errStatus === true && formValues && formValues.sprint === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.sprintErr}</p>} 
                        </div>*/}

                        <InputSelectField
                            label={'Task Status'}
                            placeholder="Select"
                            defaultValue={formValues.t_status}
                            name={"t_status"}
                            onChange={handleChange}
                            optionList={workflowStatusList || []}
                            //optionList={jireStatusList && jireStatusList.length > 0 ? jireStatusList : []}
                            optionLevel={['name']}
                            optionValue={"id"}
                            errStatus={errStatus}
                            formErr={formErr.t_statusErr}
                        />

                        <InputSelectField
                            label={'Priority'}
                            placeholder="Select"
                            defaultValue={formValues.priority}
                            name={"priority"}
                            onChange={handleChange}
                            optionList={LocalStorageDataService && LocalStorageDataService.priority_list()}
                            optionLevel={['option']}
                            optionValue={"option"}
                        // errStatus={errStatus}
                        // formErr={formErr.assigneeErr}
                        />
                        <InputFields
                            label={'reporter'}
                            name="reporter"
                            defaultValue={formValues.reporter}
                            type="text"
                            placeholder="Reporter"
                            onChange={handleChange}
                            disabled={true}
                            errStatus={errStatus}
                            formValues={formValues}
                            formErr={formErr.reporterErr}
                        />

                        {/* <div className="formInput">
                            <FormControl fullWidth>
                                <FormLabel id="demo-row-radio-buttons-group-label">Is Jira Issue</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="is_jira_issue"
                                    value={formValues.is_jira_issue}
                                    onChange={(e) => { handleChange(e) }}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Active" />
                                    <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                </RadioGroup>
                                {errStatus === true && formValues && formValues.is_jira_issue === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.is_jira_issueErr}</p>}
                            </FormControl>
                        </div> */}
                        {activeEpicField === true ?
                            <InputFields
                                label={'Assignee'}
                                name="assignee"
                                defaultValue={formValues.assignee}
                                type="text"
                                placeholder="Assignee"
                                onChange={handleChange}
                                disabled={true}
                                errStatus={errStatus}
                                formErr={formErr.assigneeErr}
                            />
                            :
                            <InputSelectField
                                label={'Assignee'}
                                placeholder="Select"
                                defaultValue={formValues.assignee}
                                name={"assignee"}
                                onChange={handleChange}
                                optionList={data && data.data && data.data.rows ? data.data.rows : []}
                                //errStatus={errStatus}
                                //formErr={formErr.assigneeErr}
                                optionLevel={['display_name', 'email']}
                                optionValue={"id"}
                            />
                        }

                        <InputTextareaField
                            label={'Summary'}
                            defaultValue={formValues.summary}
                            name="summary"
                            onChange={handleChange}
                            placeholder="Add Summary"
                            rows="3"
                            errStatus={errStatus}
                            formErr={formErr.summaryErr}
                        />

                        <InputTextareaField
                            label={'Task Description'}
                            defaultValue={formValues.t_description}
                            name="t_description"
                            onChange={handleChange}
                            placeholder="Add Description"
                            rows="3"
                        //errStatus={errStatus}
                        //formErr={formErr}
                        />

                    </div>
                    {syncLoading === false && <InputButton onSubmit={submitData} label={CONSTANTS.COMMON_BUTTONS.SUBMIT} />}
                </form>
                <TaskModal
                    openModal={activeStoryModal}
                    setOpenModal={closeActiveUploadModal}
                    title={"Upload Resume"}
                    formtype="data-grid"
                    list={taskList && taskList.data && taskList.data.rows ? taskList.data.rows : []}
                    columns={[
                        {
                            field: "issue_type", headerName: "", width: 50,
                            renderCell: (params) => {
                                return (
                                    <div onClick={() => activeLink(params)} className="cellWithStatus">
                                        {params.row.issue_type && params.row.issue_type.icon_url ?
                                            <img src={params.row.issue_type.icon_url} alt="" />

                                            :
                                            params.row.issue_type_icon ?
                                                <img src={params.row.issue_type_icon} alt="" />

                                                :
                                                <div></div>
                                        }
                                    </div>
                                );
                            }
                        },

                        {
                            field: "ticket_key", headerName: "RTMS Key", width: 200,
                            renderCell: (params) => {
                                return (
                                    <div onClick={() => activeLink(params)} className="cellWithStatus">
                                        {params.row.ticket_key}
                                    </div>
                                )
                            }
                        },
                        {
                            field: "summary", headerName: "summary", width: 300,
                            renderCell: (params) => {
                                return (
                                    <div onClick={() => activeLink(params)} className="cellWithStatus">
                                        {params.row.summary}
                                    </div>
                                )
                            }
                        }]}
                />
                <TaskModal
                    openModal={activeUploadModal}
                    setOpenModal={setActiveUploadModal}
                    title={"Upload Resume"}
                    formtype="form-data"
                    formDataJson={[
                        {
                            label: 'Select EPIC/JOB',
                            name: "epic_id",
                            disabled: true,
                            fieldType: "InputSelectField",
                            defaultValue: selectedOption,
                            optionList: taskStoryList && taskStoryList.length > 0 ? taskStoryList : [],
                            optionLevel: ['summary', 'ticket_key'],
                            optionValue: "id",
                            onChange: setSelectedOption
                        },
                        {
                            label: 'Upload JD(PDF/ZIP/CSV/Docx)',
                            name: "file",
                            type: "file",
                            fieldType: "InputFileFields",
                            placeholder: "Upload File",
                            onChange: onFileChange,
                            formErr: { formErr }
                        }

                    ]}
                    formButton={[{
                        label: 'Submit',
                        name: "button",
                        fieldType: "InputButton",
                        placeholder: "Upload File",
                        onSubmit: resumeUpload
                    }]}
                />
            </div>
        </div>
    )
}
