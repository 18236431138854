import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getCallLogDetailById,
  getCallLogDetails,
  getCommentId,
  getTaskHistory,
  getZoomMeetingDetails,
  postCommentCreate,
  resourceProjectList,
  taskAssigneeUpdateById,
  taskStatusUpdate,
  taskViewById,
  getTeamMemberList,
  createRemindStory,
  getEmailLogsDetails
} from "../../redux/features/taskSlice";
import { Tab, Tabs, Avatar, Autocomplete, TextField, Box, Tooltip, Button, Menu, MenuItem, Checkbox } from "@mui/material";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import moment from "moment";
import { createAttachment } from "../../redux/features/masterSlice";
import { resourceListForTaskEdit } from "../../redux/features/resourceSlice";
import { CKEditor } from "ckeditor4-react";
import { Mail, AttachFile, Download, Close, ZoomOutMap, ZoomInMap, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, Phone, InsertInvitation, } from "@mui/icons-material";
import CallingComponent from "../../components/common-function/CallingComponent";
import EmailTemplet from "../../components/email-template/EmailTemplate";
import { toast, ToastContainer } from "react-toastify";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import { PhoneCalling } from "../phone-calling/PhoneCalling";
import IconButton from "@mui/material/IconButton";
import TaskBreadCrumb from "../../components/breadcrumb/TaskBreadCrumb";
import ReactAudioPlayer from "react-audio-player";
import { RemindModal } from "./RemindMeModal";
import { DefaultDataGrid } from "../../components/common-function/DefaultDataGrid";
import { ContentParser } from "./ContentParser";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import AddCandidatePhoneNumber from "../../components/add-candidate-phone-no/AddCandidatePhoneNumber";

const domain_data = LocalStorageDataService.get_domain_data()


function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function firstLetterWord(str) {
  let result = "";
  // Traverse the string.
  let v = true;
  for (let i = 0; i < str.length; i++) {
    // If it is space, set v as true.
    if (str[i] === " ") {
      v = true;
    }

    // Else check if v is true or not.
    // If true, copy character in output
    // string and set v as false.
    else if (str[i] !== " " && v === true) {
      result += str[i];
      v = false;
    }
  }
  return result;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: firstLetterWord(name),
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
const { REACT_APP_API_BASEURL } = process.env;
const formErrData = Object.freeze({
  dateErr: " Date  is required",
  timeErr: "Time is required",
  commentErr: "Comment is required",
  default_time_in_minsErr: "Default Time  is required"
});
// const docs = [
//   { uri: 'https://stage-tm.mightcode.com/api/' + "recruitment/resume/download/7478" }
// ];

export const StoryTaskNew = ({ epicId, hideDiv, activeDivs, setActiveClass, activeClass, activeCallDivs, setActiveCallDivs, showAddPhone, setShowAddPhone }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [file, setFile] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selected, setSelected] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [memberLoader, setMemberLoader] = useState(true);
  const [, setMember_List] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedMemberLoader, setSelectedMemberLoader] = useState(false);
  const [assignee_name_default, setAssignee_name_default] = useState("");
  const [commentActive, setCommentActive] = useState(false);
  const [comment, setComment] = useState("");
  const [multipleEmail, setMultipleEmail] = useState({})


  const [defaultActivemail, setdefaultActivemail] = useState(false);
  //const [ setTeamMembersStatus] = useState(false)
  const [showEmailModel, setShowEmailModel] = useState(false);

  //start calling related states
  const [showAddCandidatePhoneBtn, setShowAddCandidatePhoneBtn] = useState(false);
  const [reload, setReload] = useState(false);
  const [updateCandidateContactNumber, setUpdateCandidateContactNumber] = useState(false)

  const [defaultActivecall, setdefaultActivecall] = useState(false);
  const [agentCustomerCallObj, setAgentCustomerCallObj] = useState({
    agent_name: "",
    candidate_name: "",
    agent_phone_no: "",
    candidate_phone_no: "",
    call_status: null,
  });
  const [callId, setCallId] = useState("");
  const [callStatus, setCallStatus] = useState(null);
  const [callApiLoading, setCallApiLoading] = useState(false);
  const [isCallInitiated, setIsCallInitiated] = useState(false);
  const [btnStyle, setBtnStyle] = useState({
    marginRight: "10px",
    backgroundColor: "green",
    color: "#fff",
    paddingBottom: 0,
    paddingTop: 3,
    borderRadius: 5,
  });
  const [callDisconnect, setCallDisconnect] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState({ destination_number: "", story_id: epicId, });
  //end calling related states

  const [activeRemindModal, setActiveRemindModal] = useState(false)
  const [startRemindDate, setStartRemindDate] = useState({ date: "", time: "", comment: '', default_time_in_mins: 10 });
  const [reminderLoading, setReminderLoading] = useState(false)
  const [formErr] = useState(formErrData)
  const [errStatus, setErrStatus] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [activeTab, setActiveTab] = useState(0);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [attachments, setAttachments] = useState([])
  const openmessage = Boolean(anchorEl1);
  const openphone = Boolean(anchorEl2);
  const dropdownClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const dropdownPhoneClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const dropdownClose = () => {
    setAnchorEl1(null);
  };
  const dropdownPhoneClose = () => {
    setAnchorEl2(null);
  };

  const [emailDetails, setEmailDetails] = useState({ email: "", });
  // file uploading states
  const uploadInputRef = useRef(null);

  const {
    teamMemberStatus,
    status,
    defaultLoader,
    taskData,
    memberList,
    commentById,
    zoomMeetingDetailList,
    callLogDetailList,
    taskHistoryList,
    teamMemberList,
    callDetailById,
    createReminder,
    emailLogDetailList
  } = useSelector((state) => state.task);
  const { create_Attachment } = useSelector((state) => state.master);
  //const { resourceListForTsk } = useSelector(state => state.resource)
  //const { call_disconnect } = useSelector(state => state.callingSlice)

  //const resource_data_list = resourceListForTsk && resourceListForTsk.data && resourceListForTsk.data.rows ? resourceListForTsk.data.rows : [];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setActiveTab(0);
      dispatch(getCommentId(epicId));
    }
    if (newValue === 1) {
      setActiveTab(1);
      dispatch(
        getTaskHistory(
          `{"filter":{"rtms_issue_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
        )
      );
    }

    if (newValue === 2) {
      setActiveTab(2);
      dispatch(
        getCallLogDetails(
          `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
        )
      );
    }
    if (newValue === 3) {
      setActiveTab(3)
      dispatch(getEmailLogsDetails(`{"filter":{"jira_rtms_issue":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))

    }
    if (newValue === 4) {
      setActiveTab(4);
      dispatch(
        getZoomMeetingDetails(
          `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
        )
      );
    }
  };

  if (activeTab === 0 && commentById && commentById.data) {
    setActiveTab(false);
  }
  if (activeTab === 1 && taskHistoryList && taskHistoryList.data) {
    setActiveTab(false);
  }
  if (activeTab === 2 && callLogDetailList && callLogDetailList.data) {
    setActiveTab(false);
  }
  if (activeTab === 3 && emailLogDetailList && emailLogDetailList.data) {
    setActiveTab(false)
  }
  if (activeTab === 4 && zoomMeetingDetailList && zoomMeetingDetailList.data) {
    setActiveTab(false);
  }

  const assignToMe = (e, type) => {
    e.preventDefault();
    let userId = LocalStorageDataService.userDetail().id;
    setLoading(true);
    if (type === "unassign") {
      setAssignee_name_default("");
      dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: 0, assign_to_me: 0 }));
    } else {
      setAssignee_name_default({
        key: userId,
        label: LocalStorageDataService.userDetail().display_name,
        value: userId,
        name: "assignee",
      });
      dispatch(
        taskAssigneeUpdateById({ issue_id: epicId, resource_id: userId, assign_to_me: 0 })
      );
    }
  };

  const changeCallActiveStatus = () => {
    let statusIs = formValues && formValues.status_name;
    if (statusIs.match("BACKLOG") || statusIs.match("backlog") || statusIs.match("Backlog")) {
      let filterStatus = statusData.filter((x) =>
        x.status_name === "In Progress" ? x : ""
      );
      if (filterStatus.length > 0) {
        let value = filterStatus[0].status_id;
        setLoading(true);
        const transition = filterStatus;
        dispatch(
          taskStatusUpdate({
            issue_id: epicId,
            from_status_id: formValues.status_id ? formValues.status_id : value,
            to_status_id: value,
            transition_id:
              transition.length > 0 ? transition[0].transition_id : null,
          })
        );
      }
    }
  };

  const changeStatus = (evt) => {
    if (evt) {
      const { name, value } = evt.target || evt;

      if (name === "status_name") {
        setLoading(true);
        const transition = statusData.filter((x) =>
          x.status_id.toString() === value ? x : ""
        );
        dispatch(
          taskStatusUpdate({
            issue_id: epicId,
            from_status_id: formValues.status_id ? formValues.status_id : value,
            to_status_id: value,
            transition_id:
              transition.length > 0 ? transition[0].transition_id : null,
          })
        );
      }
      if (name === "assignee") {
        setLoading(true);
        dispatch(
          taskAssigneeUpdateById({ issue_id: epicId, resource_id: value, assign_to_me: 0 })
        );
      }
      if (name === "lables") {
        setSelected(value);
        setFormValues({ ...formValues, labels: value });
      }
    }
  };

  useEffect(() => {
    if (epicId && activeDivs === `story_${epicId}`) {
      setApiLoading(true);
      dispatch(taskViewById(epicId));
    }
    dispatch(resourceListForTaskEdit());
    setSelectedMember([]);
  }, [activeDivs]);

  if (memberLoader === true && memberList && memberList.data) {
    let array = [];
    memberList.data.rows.map((e) => {
      return array.push({
        display_name: e.resource.display_name,
        assignee_id: e.resource_id,
        jira_rtms_issue_id: e.jira_rtms_issue_id,
        actual_id: e.id,
      });
    });
    setMember_List(array);
    setMemberLoader(false);
    setApiLoading(false);
  }

  if (loading === true && file && file[0].name) {
    setFile(null);
    setLoading(false);
    setApiLoading(true);
    dispatch(taskViewById(epicId));
  }

  if (loading === true && status === `${STATUSES.SUCCESS}_taskStatusData`) {
    setLoading(false);
    setApiLoading(true);
    dispatch(taskViewById(epicId));
  }
  if (loading === true && status === `${STATUSES.SUCCESS}_taskAssigneeUpdate`) {
    setLoading(false);
    setApiLoading(true);
    dispatch(taskViewById(epicId));
  }
  if (loading === "comment_true" && status === `${STATUSES.SUCCESS}_createComment`) {
    setComment("");
    setLoading(false);
    setActiveTab(0);
    dispatch(getCommentId(epicId));
  }
  if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskHistoryList`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_commentById`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_zoomMeetingDetailList`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_callLogDetailList`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_emailLogDetailList`) {
    setApiLoading(false)
  }
  if (selectedMemberLoader === true && teamMemberStatus === `${STATUSES.SUCCESS}_teamMemberList`) {
    let array = [];
    if (teamMemberList && teamMemberList.data && teamMemberList.data.rows && teamMemberList.data.rows.length > 0) {
      teamMemberList.data.rows.map((e) => {
        if (e.status === true) {
          array.push({
            label: e.resource.display_name,
            value: e.resource.id,
          });
        }
        return array;
      });
    }
    setSelectedMemberLoader(false);
    setSelectedMember(array);
  }
  if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskData`) {
    let data = taskData.data;
    if (taskData.status === 400) {
      toast.error(taskData.message);
    }
    setApiLoading(false);
    setFormValues(data);
    if (data && data.attachments && data.attachments.length > 0) {
      if (attachments.length === 0) {
        let attachments = []
        data.attachments.map(e => {
          return attachments.push({ uri: REACT_APP_API_BASEURL.replace("api/", "api") + e.api_url })
        })
        setAttachments(attachments)

      }

    }
    if (data && data.status_data) {
      setStatusData(data.status_data);
    }
    if (data && data.assignee) {
      setAssignee_name_default({
        label: data.assignee.display_name,
        value: data.assignee.id,
      });
    }
    if (data && data.epic_id) {
      setSelectedMember([]);
      setTimeout(() => {
        setSelectedMemberLoader(true);
      }, 500);
      dispatch(
        getTeamMemberList(
          `{"filter":{"jira_rtms_issue_id":{"value":${data.epic_id}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
        )
      );
    }
    if (data && data.candidate_info) {
      const get_candidate_name = data && data.candidate_info && data.candidate_info.length > 0 && data.candidate_info.map((ele) => {
        return ele.candidate_name;
      });

      setAgentCustomerCallObj({
        ...agentCustomerCallObj,
        agent_name: data && data.assignee && data.assignee.name,
        agent_phone_no: data && data.assignee && data.assignee.cug_ph_no,
        candidate_name: get_candidate_name[0],
        candidate_phone_no: phoneDetails.destination_number,
        call_status: callStatus,
      });
    }

    if (data && data.candidate_phone && data.candidate_phone.length > 0) {
      setdefaultActivecall(false);
      if (activeCallDivs && activeCallDivs !== false) {
        setPhoneDetails({
          ...phoneDetails,
          destination_number: data.candidate_phone[0].phone_number,
        });
      }
    }

    if (data && data.candidate_phone && data.candidate_phone.length === 0) {
      setPhoneDetails({
        destination_number: "",
        story_id: ""
      });
      setShowAddCandidatePhoneBtn(true)
      setdefaultActivecall(false)
    }

    if (data && data.candidate_phone && data.candidate_phone.length === 1) {
      setdefaultActivecall(true);
      setPhoneDetails({
        ...phoneDetails,
        destination_number: data.candidate_phone[0].phone_number,
      });
    }

    if (data && data.candidate_email && data.candidate_email.length === 1) {
      setdefaultActivemail(true);
      setEmailDetails({
        ...emailDetails,
        email: [data.candidate_email[0].email],
      });
    }

    if (data && data.project) {
      // setTeamMembersStatus(true)
      dispatch(
        resourceProjectList(
          `{"filter":{"project":{"value":${data.project.id},"operation":"eq"}}, "no_limit":True}`
        )
      );
    }

    dispatch(getCommentId(epicId));
  }


  const inputHandler = (evt) => {
    var newContent = evt.editor.getData();
    setComment(newContent);
    // Define your onSubmit function here
    // ...
    // for example, setData() here
  };

  const editCommentChanges = (name) => {
    setCommentActive(false);
    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");

    // might be a more efficient way to do this. just querying for anchors
    // and filtering out the ones that don't have a <sup> child
    const anchors = [...doc.querySelectorAll("a")];

    let data = {
      jira_issue_id: formValues.ticket_jira_key,
      rtms_issue: epicId,
      comment_url: anchors && anchors.length > 0 ? anchors[0].innerText : "",
      is_jira_attachment: false,
      body: comment,
      created_resource: LocalStorageDataService.userDetail().id,
      status: true,
      created_by: LocalStorageDataService.userDetail().id,
    };
    setLoading("comment_true");
    dispatch(postCommentCreate(data));
    //dispatch(taskUpdateById({ id: taskId, addvalues: { [name]: value } }))
  };


  if (callApiLoading === true && status === `${STATUSES.SUCCESS}_callDetailById`) {
    setCallApiLoading(false);
    const callDetailData = callDetailById && callDetailById.data && callDetailById.data.rows[0];
    callDetailData && setCallStatus(callDetailData.status);
    if (callDisconnect === true || (callDetailData && (callDetailData.status === 1 ||
      callDetailData.status === 2 || callDetailData.status === 4 || callDetailData.status === 5 ||
      callDetailData.status === 7 || callDetailData.status === 8 || callDetailData.status === 9))) {
      setBtnStyle({ backgroundColor: "green", border: "1px solid green" });
      setCallApiLoading(false);
      setCallDisconnect(false);
      // setPhoneDetails({ destination_number: "", story_id: epicId });

      setCallId((prev) => { return ""; });
      setIsCallInitiated(false);
      setCallStatus((prev) => { return null; });
      setActiveClass(false)

    } else {
      setTimeout(() => {
        dispatch(
          getCallLogDetailById(
            `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"},"id":{"value":${callId}, "operation":"eq"}}}`
          )
        );
        setCallApiLoading(true);
      }, 4000);
    }
    setAgentCustomerCallObj({
      ...agentCustomerCallObj,
      call_status: callDetailData && callDetailData.status,
    });
  }

  useEffect(() => {
    if (isCallInitiated === true && callId !== "") {
      dispatch(
        getCallLogDetailById(
          `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"},"id":{"value":${callId}, "operation":"eq"}}}`
        )
      );
      setCallApiLoading(true);
    }
  }, [callId]);

  const changeEmail = (evt) => {
    if (evt) {
      setEmailDetails({ ...emailDetails, 'email': evt });
      setShowEmailModel(true);
    } else {
      setEmailDetails({ email: "", });
    }
  };

  const calculateDays = (startDate) => {
    if (new Date() === new Date(startDate)) {
      return "Toady";
    }
    const diffInMs = new Date() - new Date(startDate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return parseInt(diffInDays) + "  days ago";
  };
  const disabledAssigne = () => {
    let statusIs = false;
    if (selectedMember && selectedMember.length > 0) {
      selectedMember.map((e) => {
        if (e.value === LocalStorageDataService.userDetail().id) {
          statusIs = true;
        }
        return statusIs;
      });
    }
    return statusIs;
  };

  const [show, setShow] = useState(false);

  // attach file code here
  const onFileChange = (event) => {
    let file = event.target.files;
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("id", `${epicId}`);
    formData.append("upload_type", `task`);
    formData.append("display_name", file[0].name);

    setLoading("upload_attachment");
    dispatch(createAttachment(formData));
  };

  if (loading === "upload_attachment" && create_Attachment && create_Attachment.status) {
    setLoading(false);
    if (create_Attachment.status === 200) {
      toast.success(create_Attachment.message);
      setApiLoading(true);
      dispatch(taskViewById(epicId));
    } else {
      toast.error(create_Attachment.message);
    }
  }
  const fileDownload = (url) => {
    let ApIURL = REACT_APP_API_BASEURL + url;
    window.open(ApIURL.replace("api//", "api/"));
  };
  const columnsCall = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      filterable: false,
      hideable: false,
    },
    {
      field: "direction",
      headerName: "Call Type",
      width: 150,
      type: "singleSelect",
      valueOptions: [
        { value: 0, label: "In-bound" },
        { value: 1, label: "Out-bound" },
      ],
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.direction === 0 ? "In-bound" : "Out-bound"}
          </div>
        );
      },
    },
    {
      field: "destination_number",
      headerName: "Destination Number",
      width: 200,
    },
    {
      field: "call_date",
      headerName: "Call Date",
      width: 150,
      type: "date",
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {moment(params.row.call_date).format("DD-MM-YYYY")}
          </div>
        );
      },
    },

    {
      field: "call_duration",
      headerName: "Call Duration(in seconds)",
      width: 260,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.call_duration && params.row.call_duration}
          </div>
        );
      },
    },

    {
      field: "recording_url",
      headerName: "Call Recording Link",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {formValues &&
              formValues.assignee_id ===
              LocalStorageDataService.userDetail().id ? (
              <ReactAudioPlayer
                src={params.row.recording_url}
                autoPlay={false}
                controls
                style={{ height: "30px" }}
              />
            ) : (
              params.row.recording_url
            )}
          </div>
        );
      },
    },
  ];
  const columnsZoom = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      filterable: false,
      hideable: false,
    },
    {
      field: "agenda",
      headerName: "Agenda",
      //sortable: false,
      width: 300,
    },
    {
      field: "topic",
      headerName: "Topic",
      //sortable: false,
      width: 200,
    },
    {
      field: "start_time",
      headerName: "Start Date",
      //sortable: false,
      width: 100,
      type: "date",
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {moment(params.row.start_time).format("DD-MM-YYYY HH:SS")}
          </div>
        );
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      //sortable: false,
      width: 100,
    },
    {
      field: "join_url",
      headerName: "Zoom Url",
      //sortable: false,
      width: 400,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            <p>
              Join Url :
              {formValues &&
                formValues.assignee_id ===
                LocalStorageDataService.userDetail().id ? (
                <a
                  href={params.row.join_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {params.row.join_url}
                </a>
              ) : (
                params.row.join_url
              )}
            </p>
            <p>Meeting Id :{params.row.zoom_meeting_id}</p>
            <p>Password :{params.row.password}</p>
          </div>
        );
      },
    },
    {
      field: "recording_details",
      headerName: "Recording",
      //sortable: false,
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.recording_details && params.row.recording_url && (
              <p>
                Url :
                <ReactAudioPlayer
                  src={params.row.recording_url}
                  autoPlay={false}
                  controls
                  style={{ height: "30px" }}
                />
              </p>
            )}
            {params.row.recording_details && params.row.recording_passcode && (
              <p>Passcode :{params.row.recording_passcode}</p>
            )}
          </div>
        );
      },
    },
  ];
  const columnsEmail = [
    { field: 'id', headerName: 'ID', width: 90, filterable: false, hideable: false },
    {
      field: 'from_resource',
      headerName: 'From',
      width: 200,

    },
    {
      field: 'to_resource',
      headerName: 'To',
      width: 200,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {/* {params.row.to_resource && JSON.stringify(params.row.to_resource)} */}
            {params.row.to_resource && params.row.to_resource.length > 0 && params.row.to_resource.map((e, index) => `${index ? ', ' : ''}${(e).trim()}`)}

          </div>
        );
      }
    },
    {
      field: 'cc',
      headerName: 'CC',
      width: 200,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.cc && params.row.cc.length > 0 && params.row.cc.map((e, index) => e && `${index ? ', ' : ''}${(e).trim()}`)}
          </div>
        );
      }
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 200,
    },
    {
      field: 'body',
      headerName: 'Body',
      width: 200,
    },
    {
      field: "email_sent_status", headerName: "Email Sent Status", width: 200,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.email_sent_status}`}>
            {params.row.email_sent_status === true ? 'Active' : 'Inactive'}
          </div>
        );
      }
    },
  ];

  const setMultipleEmailOption = (email) => {
    if (multipleEmail[email]) {
      delete multipleEmail[email]
      setMultipleEmail({ ...multipleEmail })
    } else {
      setMultipleEmail({ ...multipleEmail, [email]: email })
    }
  }

  const closeEmailModel = () => {
    setShowEmailModel(false)
    setMultipleEmail({})
    // setEmailDetails({ email: "" })
  }

  const changeStartRemindDate = (evt, type) => {
    setStartRemindDate({ ...startRemindDate, [type]: evt })
  }

  const closeReminderModal = () => {
    setReminderLoading(false)
    setActiveRemindModal(false)
    setStartRemindDate({ date: "", time: "", comment: '', default_time_in_mins: 10 })
  }
  const onSubmitReminder = () => {
    if (startRemindDate.comment && startRemindDate.default_time_in_mins && startRemindDate.time && startRemindDate.date) {
      setErrStatus(false)
      let data = {
        "resource": formValues.assignee_id,
        "comment": startRemindDate.comment,
        "notification_date": moment(startRemindDate.date).format('YYYY-MM-DD'),
        "notification_time": moment(startRemindDate.time).format('HH:mm:ss'),
        "default_time_in_mins": startRemindDate.default_time_in_mins,
        "story": epicId,
        "epic": formValues.epic_id ? formValues.epic_id : formValues.job_id,
        "project": formValues.project_id,
        "is_viewed": false,
        "email_sent_status": 0,
        "no_of_time_email_sent": 0,
        "created_by": LocalStorageDataService.userDetail().id
      }
      dispatch(createRemindStory(data))
      setReminderLoading(true)
    } else {
      setErrStatus(true)
    }

  }

  if (status === `${STATUSES.SUCCESS}_createReminder` && reminderLoading === true) {
    if (createReminder.status === 200) {
      toast.success(createReminder.message)
      closeReminderModal()
    } else {
      toast.error(createReminder.message)
    }
    setReminderLoading(false)
  }
  let descriptionType = formValues && formValues.description && (formValues.description).match("{") ? ContentParser(JSON.parse(formValues.description)) : formValues && formValues.description

  //add candidate phone number section
  const addCandidatePhoneNumber = () => {
    setShowAddPhone(true)
  }

  const handleCloseAddCandidate = () => {
    setShowAddPhone(false)
    // setPhoneDetails({ destination_number: "", story_id: epicId })
  }

  const { updateCandidateContactNo, updatePhoneStatus } = useSelector(state => state.callingSlice)

  if (reload === 'submit' && updatePhoneStatus === `${STATUSES.SUCCESS}_updateCandidateContactNo` && updateCandidateContactNo && updateCandidateContactNo.message) {
    if (updateCandidateContactNo.status === 200) {

      let phone_no = updateCandidateContactNo && updateCandidateContactNo.data && updateCandidateContactNo.data.phone_number
      setUpdateCandidateContactNumber(true)
      setdefaultActivecall(true)
      setActiveCallDivs(`true_${epicId}`)
      setPhoneDetails({ destination_number: phone_no, story_id: epicId })
      handleCloseAddCandidate()
      // toast.success(updateCandidateContactNo.message)
    } else {
      toast.error(updateCandidateContactNo.message)
    }
    setReload(false)
  }

  return (
    <div className="datatable ss-datatable style-table">
      <ToastContainer />

      <div className="row">
        {
          isCallInitiated === true ? "" : <div className="col ss-top-icon" style={{ textAlign: "end" }}>
            <Tooltip title="Close">
              <Button
                style={{
                  marginTop: "3px",
                  backgroundColor: "rgb(201 201 201)",
                  color: "rgb(110 110 110)"
                }}
                onClick={() => hideDiv(false)}
              >
                <Close />
              </Button>
            </Tooltip>


            {!activeClass === true ? (
              <Tooltip title="Zoom Out">
                <Button
                  style={{
                    marginTop: "3px",
                    backgroundColor: "rgb(201 201 201)",
                    color: "rgb(110 110 110)"
                  }}
                  onClick={() => setActiveClass(!activeClass)}
                >
                  <ZoomOutMap />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Zoom In">
                <Button
                  style={{
                    marginTop: "3px",
                    backgroundColor: "rgb(201 201 201)",
                    color: "rgb(110 110 110)"
                  }}
                  onClick={() => setActiveClass(!activeClass)}
                >
                  <ZoomInMap />
                </Button>
              </Tooltip>
            )}

          </div>
        }


      </div>
      <div className="top-headings">
        {/* <h3>Story Task</h3> */}
        <div>
          {formValues &&
            formValues.project &&
            formValues.project.project_name ? (
            <TaskBreadCrumb formValues={formValues} />
          ) : (
            <p>Project</p>
          )}

          <button style={{ borderWidth: 0, backgroundColor: "#fff" }}>
            <h3 className="story-epic-summary">
              {formValues && formValues.summary}
            </h3>
          </button>
        </div>
        {apiLoading === true && <LoaderForm />}
        <div className="btn-section-top ss-section-top">
          {LocalStorageDataService.userDetail().agent_id && formValues && (formValues.assignee_id === LocalStorageDataService.userDetail().id) ?
            <div className="ss-button-group">

              {defaultActivecall === false && formValues && formValues.candidate_phone && formValues.candidate_phone.length > 0 && (
                <Button id="phone"
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                  disabled={isCallInitiated === true ? true : false}
                >
                  <div onClick={dropdownPhoneClick} className="phoneicon">

                    <Phone
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'message-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined} />
                    <KeyboardArrowDown />
                  </div>
                </Button>
              )}

              {defaultActivecall === false && showAddCandidatePhoneBtn === true && (formValues && formValues.candidate_phone && formValues.candidate_phone.length === 0 || updateCandidateContactNumber === true) &&
                <Tooltip title="Call">
                  <Button
                    style={{
                      marginTop: "3px",
                      marginLeft: "3px",
                      backgroundColor: "orangered",
                      color: "#fff",
                    }}
                    onClick={() => addCandidatePhoneNumber(formValues)}
                  >
                    <Phone /></Button>
                </Tooltip>
              }

              {activeCallDivs && activeCallDivs !== false && (phoneDetails.destination_number) && (
                <CallingComponent
                  activeCallDivs={activeCallDivs}
                  setActiveCallDivs={setActiveCallDivs}
                  setActiveClass={setActiveClass}
                  isCallInitiated={isCallInitiated}
                  setShow={setShow}
                  agentCustomerCallObj={agentCustomerCallObj}
                  setAgentCustomerCallObj={setAgentCustomerCallObj}
                  data={phoneDetails}
                  title=""
                  setCallId={setCallId}
                  setIsCallInitiated={setIsCallInitiated}
                  setCallStatus={setCallStatus}
                  // style={btnStyle}
                  setBtnStyle={setBtnStyle}
                  setPhoneDetails={setPhoneDetails}
                  story_id={epicId}
                  changeCallActiveStatus={changeCallActiveStatus}
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                />
              )}

              {defaultActivecall === true && (phoneDetails.destination_number) && (
                <CallingComponent
                  activeCallDivs={activeCallDivs}
                  setActiveCallDivs={setActiveCallDivs}
                  setActiveClass={setActiveClass}
                  isCallInitiated={isCallInitiated}
                  setShow={setShow}
                  agentCustomerCallObj={agentCustomerCallObj}
                  setAgentCustomerCallObj={setAgentCustomerCallObj}
                  data={phoneDetails}
                  title=""
                  setCallId={setCallId}
                  setIsCallInitiated={setIsCallInitiated}
                  setCallStatus={setCallStatus}
                  // style={btnStyle}
                  setBtnStyle={setBtnStyle}
                  setPhoneDetails={setPhoneDetails}
                  story_id={epicId}
                  changeCallActiveStatus={changeCallActiveStatus}
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                />
              )}

              <Menu
                anchorEl={anchorEl2}
                style={isCallInitiated === true ? { display: 'none' } : {}}

                className="MuiMenu-list-dropdown"
                id="message-menu"
                open={openphone}
                onClose={dropdownPhoneClose}
                //onClick={dropdownPhoneClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,

                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {
                  formValues.candidate_phone && formValues.candidate_phone.length && formValues.candidate_phone.map((option, i) => {
                    return (
                      <CallingComponent
                        setActiveClass={setActiveClass}
                        dropdownPhoneClose={dropdownPhoneClose}
                        dropdownPhoneCloseActive={true}
                        isCallInitiated={isCallInitiated}
                        aria-controls={open ? 'message-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        options={option}
                        data={{ destination_number: option.phone_number, story_id: epicId }}
                        title=""
                        setCallId={setCallId}
                        setIsCallInitiated={setIsCallInitiated}
                        setCallStatus={setCallStatus}
                        // style={btnStyle}
                        setShow={setShow}
                        agentCustomerCallObj={agentCustomerCallObj}
                        setAgentCustomerCallObj={setAgentCustomerCallObj}
                        setBtnStyle={setBtnStyle}
                        setPhoneDetails={setPhoneDetails}
                        story_id={epicId}
                        changeCallActiveStatus={changeCallActiveStatus}
                        style={{
                          marginTop: "3px",
                          height: "37px",
                          marginLeft: "3px",
                          backgroundColor: "#0052cc",
                          color: "#fff",
                        }}
                      />
                    )
                  })
                }
              </Menu>

              {defaultActivemail === false && formValues &&
                formValues.candidate_email &&
                <Button id="email"
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                >
                  <div className="phoneicon">
                    <Mail
                      onClick={dropdownClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'message-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined} />
                    <KeyboardArrowDown />
                  </div>
                </Button>
              }
              {emailDetails.email && defaultActivemail === true && (
                <Tooltip title={`Mail - ${emailDetails.email}`}>
                  <Button
                    onClick={() => setShowEmailModel(true)}
                    className=""
                    style={{
                      marginTop: "3px",
                      marginLeft: "3px",
                      backgroundColor: "#0052cc",
                      color: "#fff",
                    }}
                  >
                    <Mail />
                  </Button>
                </Tooltip>
              )}
              {showEmailModel && (
                <EmailTemplet
                  setShowEmailModel={closeEmailModel}
                  to_email={Object.keys(multipleEmail).length > 0 ? Object.values(multipleEmail) : emailDetails.email}
                  project_id={
                    formValues && formValues.project && formValues.project.id
                  }
                  data={formValues}
                  story_id={epicId}
                />
              )}


              <Menu
                anchorEl={anchorEl1}
                className="MuiMenu-list-dropdown"
                id="message-menu"
                open={openmessage}
                onClose={dropdownClose}
                //onClick={dropdownClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {
                  formValues.candidate_email && formValues.candidate_email.length && formValues.candidate_email.map((option, i) => {
                    return (
                      <MenuItem >
                        <span className="notification-head">
                          <Checkbox checked={multipleEmail[option.email] === option.email ? true : false} onChange={(evt) => { setMultipleEmailOption(option.email) }} />
                          <span className="ss-noti">< Mail onClick={() => { changeEmail(option.email) }} /></span>
                          <span onClick={() => changeEmail(option.email)}> {option.email}</span>
                        </span>
                      </MenuItem>
                    )
                  })
                }
              </Menu>



              <Tooltip title="Comment">
                <Button
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                >
                  <a
                    style={{ color: "#fff" }}
                    href='#ss-comment-scroll'
                    onClick={() => {
                      handleChange("tset", 0);
                      setShow(false);
                      setCommentActive(true);
                    }}
                  >
                    <InsertCommentIcon />
                  </a>
                </Button>
              </Tooltip>

              <Tooltip title="set reminder">
                <Button
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                  onClick={() => setActiveRemindModal(true)}
                ><InsertInvitation /></Button>
              </Tooltip>

            </div>
            :
            formValues && (formValues.assignee_id === LocalStorageDataService.userDetail().id) &&
            <div className="ss-button-group">
              <Tooltip title="Comment">
                <Button
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                >
                  <a
                    style={{ color: "#fff" }}
                    href='#ss-comment-scroll'
                    onClick={() => {
                      handleChange("tset", 0);
                      setShow(false);
                      setCommentActive(true);
                    }}
                  >
                    <InsertCommentIcon />
                  </a>
                </Button>
              </Tooltip>
              <Tooltip title="set reminder">
                <Button
                  style={{
                    marginTop: "3px",
                    height: "37px",
                    marginLeft: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                  }}
                  onClick={() => setActiveRemindModal(true)}
                ><InsertInvitation /></Button>
              </Tooltip>

            </div>
          }

        </div>
      </div>

      <div className="edit-details-row ss-detail-row">
        <Tooltip title="Open Right Side Details">
          <span style={{ float: "right" }}>
            <IconButton className="ss-arrowicon"
              onClick={() => {
                setShow(!show);
              }}
              aria-label="delete"
            >
              {!show === true ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconButton>
          </span>
        </Tooltip>
        <div
          className={`edit-details-col-left ${show ? "show-hide-left-side-colum2" : ""
            }`}
        >
          <div className="left-description">
            {domain_data === true && formValues && formValues.show_resume === true ? '' : <h6>Description</h6>}
            {domain_data === false ?
              <p style={{ marginBottom: 20 }}>
                {" "}
                <span dangerouslySetInnerHTML={{ __html: formValues && descriptionType }} />
              </p>
              //:
              // formValues && formValues.description ?
              //   <p style={{ marginBottom: 20 }}>
              //     {" "}
              //     <span dangerouslySetInnerHTML={{ __html: formValues && descriptionType }} />
              //   </p>
              :
              formValues.show_resume === true ?
                <p style={{ marginBottom: 20 }}>
                  {attachments.length > 0 &&
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={attachments}
                      style={{ height: 800, width: "100%" }}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: true,
                          retainURLParams: true,
                        },
                        // csvDelimiter: ",", // "," as default,
                        // pdfZoom: {
                        //   defaultZoom: 1.1, // 1 as default,
                        //   zoomJump: 0.2, // 0.1 as default,
                        // },
                      }}
                    />
                  }
                </p>
                :
                formValues && formValues.description &&
                <p style={{ marginBottom: 20 }}>
                  {" "}
                  <span dangerouslySetInnerHTML={{ __html: formValues && descriptionType }} />
                </p>
            }
            {/* {formValues?.description ? <RichTextEditor data={JSON.parse(formValues.description )} /> : ""} */}
          </div>

          {formValues && formValues.assignee_id ===
            LocalStorageDataService.userDetail().id && (
              <h4 style={{ display: "flex" }}>
                Attachments(
                {formValues &&
                  formValues.attachments &&
                  formValues.attachments.length}
                )
                <div className="">
                  <div className="file-attchment-icon-btn">
                    <input
                      id="fileUpload"
                      ref={uploadInputRef}
                      type="file"
                      multiple
                      // accept='application/pdf, image/png,image/jpeg'
                      style={{ display: "none" }}
                      onChange={onFileChange}
                      name="file"
                    />
                    <Tooltip title="Add Attachment">
                      <span style={{ cursor: "pointer" }}>
                        <AttachFile
                          className={`attach_icon`}
                          onClick={() =>
                            uploadInputRef.current &&
                            uploadInputRef.current.click()
                          }
                        />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </h4>
            )}
          {formValues && formValues.assignee_id ===
            LocalStorageDataService.userDetail().id && (
              <div className="attchment-all-top-list">
                {formValues &&
                  formValues.attachments &&
                  formValues.attachments.length > 0 ? (
                  formValues.attachments.map((item, i) => {
                    return (
                      <div key={i} className="attchment-name-image">
                        <Tooltip title={item.file_name}>
                          <div className="attchment-file-name">
                            {item.file_name}
                          </div>
                        </Tooltip>
                        <div className="action-attechment-btn">
                          <Download
                            className="download-icon"
                            onClick={() => fileDownload(item.api_url)}
                          />
                          {/* <DeleteOutline className="delete-icon"  /> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No Attachment</p>
                )}
              </div>
            )}

          {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && <h4>Activity:</h4>}
          {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && (
            <div className="left-description">
              <div className="epik-tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab label="Comments" />
                  <Tab label="History" />
                  <Tab label="Call Log" />
                  <Tab label="Email Log" />
                  <Tab label="Zoom Meeting Log" />
                </Tabs>

                <TabPanel
                  value={value}
                  index={0}
                  className="custom-loader-tabs"
                  id="ss-comment-scroll"
                >
                  {activeTab === 0 && <LoaderForm />}
                  <div className="bottom-comments-row">
                    <div className="bottom-commrnt-col-left">
                      <span style={{ textTransform: "capitalize" }}>
                        <Avatar
                          {...stringAvatar(
                            LocalStorageDataService.userDetail().display_name
                          )}
                        />
                      </span>
                    </div>
                    {commentActive === false && (
                      <div className="bottom-commrnt-col-right">
                        <input
                          type="text"
                          className="comment-single-text-box"
                          placeholder="Add a Comment..."
                          onClick={() => setCommentActive(true)}
                        />
                        {/* <p style={{ marginBottom: 20 }} ><span dangerouslySetInnerHTML={{ __html: formValues.comment }} /></p> */}
                      </div>
                    )}
                    {commentActive === true && (
                      <div className="bottom-commrnt-col-right">
                        <CKEditor
                          name="comment"
                          initData={comment}
                          config={{
                            toolbar: [
                              ["Source"],
                              ["Styles", "Format", "Font", "FontSize"],
                              ["Bold", "Italic"],
                              ["Undo", "Redo"],
                              ["EasyImageUpload"],
                            ],
                          }}
                          onChange={(e) => inputHandler(e)}
                        />
                        <button
                          onClick={() => editCommentChanges("comment")}
                          className="save-btn"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setCommentActive(false);
                            setComment("");
                          }}
                          className="cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>

                  {commentById && commentById.data && commentById.data.rows && commentById.data.rows.length > 0 &&
                    commentById.data.rows.map((item, index) => {
                      return (
                        <div className="bottom-comments-row">
                          <div className="bottom-commrnt-col-left">
                            {item.created_resource &&
                              item.created_resource.display_name && (
                                <span style={{ textTransform: "capitalize" }}>
                                  <Avatar
                                    {...stringAvatar(
                                      item.created_resource.display_name
                                    )}
                                  />
                                </span>
                              )}
                          </div>
                          <div className="bottom-commrnt-col-right">
                            {item.created_resource && item.created_resource.display_name && (
                              <h6 style={{ textTransform: "capitalize" }}>
                                {item.created_resource.display_name}
                                {/* {item.rtms_issue && item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee &&
                                                                    <span>{item.rtms_issue.assignee && calculateDays(item.rtms_issue.assignee.created_on)}</span>
                                                                } */}
                              </h6>
                            )}
                            {/* <a href={item.comment_url}>Commit Link : {item.comment_url}</a> */}
                            <p>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.body && item.body,
                                }}
                              />
                            </p>
                            <p>
                              Comment Date :{" "}
                              {item.rtms_issue &&
                                moment(item.rtms_issue.created_on).format(
                                  "YYYY-MM-DD"
                                )}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </TabPanel>
                <TabPanel value={value} index={1} className="custom-loader-tabs"  >
                  {activeTab === 1 && <LoaderForm />}
                  {taskHistoryList && taskHistoryList.data && taskHistoryList.data.rows &&
                    taskHistoryList.data.rows.map((item, index) => {
                      return (
                        <div key={index} className="bottom-comments-row">
                          <div className="bottom-commrnt-col-left">
                            {item.rtms_issue &&
                              item.rtms_issue.assignee &&
                              item.rtms_issue.assignee.display_name && (
                                <span>
                                  <Avatar
                                    {...stringAvatar(
                                      item.rtms_issue.assignee.display_name
                                    )}
                                  />
                                </span>
                              )}
                          </div>
                          <div className="bottom-commrnt-col-right">
                            {item.rtms_issue &&
                              item.rtms_issue.assignee &&
                              item.rtms_issue.assignee.display_name && (
                                <h6>
                                  {item.rtms_issue.assignee.display_name}{" "}
                                  <span>
                                    {item.updated_on &&
                                      calculateDays(
                                        moment(item.updated_on).format(
                                          "YYYY-MM-DD"
                                        )
                                      )}
                                  </span>
                                </h6>
                              )}
                            <p>
                              Changed :{" "}
                              {item.new_value ? `Description - ${item.new_value}`
                                : <item className="new_assignee"></item> ? `Assignee - ${item.new_assignee}`
                                  : item.new_status ? `Status - ${item.new_status}` : `${item.new_data}`}
                            </p>
                            <div className="row-assignnee-history">
                              {/* <div className="col-assignee-history-1">
                                                                    <h5 className="assignee">Ravi Kant</h5>
                                                                </div> */}

                              <div className="col-assignee-history-2">
                                <ArrowRightAltIcon />
                              </div>

                              <div className="col-assignee-history-1">
                                {item.updated_by &&
                                  item.updated_by &&
                                  item.updated_by.display_name && (
                                    <h5 className="assign">
                                      {item.updated_by.display_name}
                                    </h5>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </TabPanel>
                <TabPanel value={value} index={2} className="custom-loader-tabs">
                  {/* {activeTab === 2 && <LoaderForm />} */}
                  <p className="related-stories-head">Call Log</p>
                  <DefaultDataGrid
                    style={{ marginTop: "20px", height: "unset" }}
                    list={(callLogDetailList && callLogDetailList.data && callLogDetailList.data.rows) || []}
                    count={(callLogDetailList && callLogDetailList.data && callLogDetailList.data.count) || 0}
                    columns={columnsCall}
                    defaultApi={getCallLogDetails}
                    loading={defaultLoader}
                    defaultFilter={{ "filter": { "story_id": { "value": epicId, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                  />

                </TabPanel>
                <TabPanel value={value} index={3} className="custom-loader-tabs">
                  {/* {activeTab === 3 && <LoaderForm />} */}
                  <p className="related-stories-head">Email Log</p>
                  <DefaultDataGrid
                    style={{ marginTop: "20px", height: "unset" }}
                    list={(emailLogDetailList && emailLogDetailList.data && emailLogDetailList.data.rows) || []}
                    count={(emailLogDetailList && emailLogDetailList.data && emailLogDetailList.data.count) || 0}
                    columns={columnsEmail}
                    defaultApi={getEmailLogsDetails}
                    loading={defaultLoader}
                    defaultFilter={{ "filter": { "jira_rtms_issue": { "value": epicId, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                  />
                </TabPanel>

                <TabPanel value={value} index={4} className="custom-loader-tabs">
                  {activeTab === 4 && <LoaderForm />}
                  <p className="related-stories-head">Zoom Meeting Log</p>
                  <DefaultDataGrid
                    style={{ marginTop: "20px", height: "unset" }}
                    list={(zoomMeetingDetailList && zoomMeetingDetailList.data && zoomMeetingDetailList.data.rows) || []}
                    count={(zoomMeetingDetailList && zoomMeetingDetailList.data && zoomMeetingDetailList.data.count) || 0}
                    columns={columnsZoom}
                    defaultApi={getZoomMeetingDetails}
                    loading={defaultLoader}
                    defaultFilter={{ "filter": { "story_id": { "value": epicId, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                  />

                </TabPanel>

              </div>
            </div>
          )}
        </div>

        <div className={`edit-details-col-right  ${show ? "show-hide-right-side-colum1 ss-task-summry-right" : ""}`} >
          {isCallInitiated && (
            <PhoneCalling
              callDetail={agentCustomerCallObj}
              callId={callId}
              callStatus={callStatus}
              isCallInitiated={isCallInitiated}
              setCallDisconnect={setCallDisconnect}
            />
          )}

          <select
            name="status_name"
            disabled={
              formValues &&
                formValues.assignee_id === LocalStorageDataService.userDetail().id
                ? false
                : true
            }
            value={
              formValues && formValues.status_id ? formValues.status_id : ""
            }
            onChange={changeStatus}
            className="select-status-ticket"
          >
            <option>SELECT</option>
            {statusData &&
              statusData.length > 0 &&
              statusData.map((item, index) => {
                return (
                  <option key={index} value={item.status_id}>
                    {item.status_name}
                  </option>
                );
              })}
          </select>
          {/* <p style={{marginTop:'3%'}}><a href='#ss-comment-scroll' style={{padding:'12px', backgroundColor:'#0052cc', color:'#fff', borderRadius:'4px'}} onClick={()=>{handleChange("tset", 0); setShow(false); setCommentActive(true);}}>ADD COMMENT</a></p> */}

          <div className="right-side-details">
            {/* <h4>Create page for edit task</h4> */}
            <div className="right-and-left-name-lable-first">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Assignee</h6>
              <div>
                <Autocomplete
                  disabled={
                    formValues &&
                      formValues.assignee_id ===
                      LocalStorageDataService.userDetail().id
                      ? false
                      : true
                  }
                  className="custom-select-name"
                  sx={{ width: 300 }}
                  autoHighlight
                  value={assignee_name_default}
                  renderOption={(props, option) => (
                    <Box
                      key={props.id}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <span className="name-and-short">
                        <Avatar
                          style={{
                            marginRight: "10px",
                            textTransform: "uppercase",
                            height: "30px",
                            width: "30px",
                            fontSize: "16px",
                          }}
                          {...stringAvatar(option.label)}
                        />
                        {"   "}{" "}
                        <span
                          className="short-cut-full-name"
                          style={{
                            fontSize: "16px",
                            textTransform: "capitalize",
                          }}
                        >
                          {option.label}
                        </span>
                      </span>
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    changeStatus(newValue);
                  }}
                  options={
                    selectedMember && selectedMember.length > 0
                      ? selectedMember.map((option, index) => {
                        return {
                          key: index,
                          label: option.label,
                          value: option.value,
                          name: "assignee",
                        };
                        //return { key: option.id, value: option.id, label: option.name + "(" + option.email + ")", name: "assignee" }
                      })
                      : [
                        {
                          label: "Not Available",
                          value: "",
                          name: "assignee",
                        },
                      ]
                  }
                  renderInput={(params) => (<TextField {...params} label={assignee_name_default === "" ? "Unassigned" : "Assigned"} />
                  )}
                />
                {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && (
                  <p style={{ color: "blue" }} onClick={(e) => assignToMe(e, "unassign")} >
                    <u>Unassigned</u>
                  </p>
                )}

                {/* {assignee_name_default && LocalStorageDataService.userDetail().id === formValues && formValues.assignee_id && <p style={{ color: 'blue' }} onClick={(e) => assignToMe(e, 'unassign')} ><u>Unassigned</u></p>} */}
                {formValues && !formValues.assignee_id && disabledAssigne() === true && (
                  <p style={{ color: "blue" }} onClick={(e) => assignToMe(e, "assign")} >
                    <u>Assign to me</u>
                  </p>
                )}

                {/* {checkAssignedfield() === false && assignee_name_default === "" && <p style={{ color: 'blue' }} onClick={(e) => assignToMe(e, 'assign')} ><u>Assign to me</u></p>} */}
              </div>
            </div>
            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Reporter</h6>
              <input
                type="text"
                value={
                  formValues && formValues.reported_by
                    ? formValues.reported_by.display_name
                    : ""
                }
                disabled={true}
              />
            </div>

            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Label</h6>
              <Select
                isMulti
                isDisabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                defaultValue={selected}
                width="100%"
                options={LocalStorageDataService.labels_list().map((e, i) => {
                  return { label: e, value: e, name: "lables", key: i };
                })}
              />
            </div>

            {/* <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Story Point</h6>
              <input
                type="text"
                disabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                value={formValues && formValues.story_point ? formValues.story_point : ""}
              />
            </div>

            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Sprint</h6>
              <input
                type="text"
                disabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                value={formValues && formValues.sprint ? formValues.sprint : ""}
              />
            </div>

            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Priority</h6>
              <Select
                isDisabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                defaultValue={formValues && formValues.priority ? formValues.priority : ""}
                onChange={changeStatus}
                width="300px"
                options={LocalStorageDataService.priority_list().map((e, i) => {
                  return { label: e, value: e, name: "priority", key: i };
                })}
              />
            </div> */}
          </div>
        </div>
      </div>
      <RemindModal
        openModal={activeRemindModal}
        setOpenModal={closeReminderModal}
        title={"Set Reminder"}
        formtype="form-data"
        setStartRemindDate={changeStartRemindDate}
        startRemindDate={startRemindDate}
        onSubmit={onSubmitReminder}
        loading={reminderLoading}
        formErr={formErr}
        errSatus={errStatus}
      />
      {
        showAddPhone === true &&
        <AddCandidatePhoneNumber
          handleClose={handleCloseAddCandidate}
          showPhone={showAddPhone}
          storyId={epicId}
          setReload={setReload}
        />
      }

    </div >
  );
};
