import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const attandanceList = createAsyncThunk("attendance/list", async (filterQuery) => {
    const res = await DataService.attandance_list(filterQuery);
    return res.data;
}
);

export const attandanceDetailById = createAsyncThunk("attendance-details/id", async (id) => {
    const res = await DataService.attandance_detail_by_id(id);
    return res.data;
});

export const attandanceRegulizationByDate = createAsyncThunk("attendance-regulization/date", async (data) => {
    const res = await DataService.attandance_regulization_by_date(data);
    return res.data;
});

export const createAttandanceRegulization = createAsyncThunk("attendance-regulization/create", async (data) => {
    const res = await DataService.attandance_regulization_create(data);
    return res.data;
});

export const attandanceReport = createAsyncThunk("attendance-report/list", async (filterQuery) => {
    const res = await DataService.attandance_report_list(filterQuery);
    return res.data;
});

export const monthlyAttandanceReport = createAsyncThunk("month-attendance-report/list", async (filterQuery) => {
    const res = await DataService.monthly_attandance_report_list(filterQuery);
    return res.data;
});

export const updateAttandanceRegulization = createAsyncThunk("attendance-regulization/update", async (data) => {
    let id = data.id
    let update_data = {
        corrected_punch_in_datetime: data.corrected_punch_in_datetime,
        corrected_punch_out_datetime: data.corrected_punch_out_datetime,
        created_by: data.created_by,
        remark: data.remark,
        resource: data.resource,
        resource_attendance: data.resource_attendance,
        status: data.status,
        reporting_head_remark: data.reporting_head_remark,
        approved_rejected_by: data.approved_rejected_by,
        approved_rejected_on: data.approved_rejected_on,
        request_type: data.request_type
    };
    const res = await DataService.attandance_regulization_update(id, update_data);
    return res.data;
});

export const attandanceRegulizationById = createAsyncThunk("attendance-regulization/id", async (id) => {
    const res = await DataService.attandance_regulization_by_id(id);
    return res.data;
});
export const teamAttandanceListByManager = createAsyncThunk("team-attendance-regulization/id", async (id) => {
    const res = await DataService.team_regular_list(id);
    return res.data;
});

export const getConsolidateMonthlyList = createAsyncThunk("consolidate-month/list", async (id) => {
    const res = await DataService.get_consolidate_monthly_list(id);
    return res.data;
});

export const getResumeUploadReportList = createAsyncThunk("upload-report/list", async (filter) => {
    const res = await DataService.get_resume_upload_report_list(filter);
    return res.data;
});

export const getResumeUploadReportLink = createAsyncThunk("upload-report/download", async (filter) => {
    const res = await DataService.get_resume_upload_report_list(filter);
    return res.data;
});

export const getResumeDownloadLink = createAsyncThunk("resume-link/download", async (id) => {
    const res = await DataService.get_download_url_resume(id);
    return res.data;
});

const calenderSlice = createSlice({
    name: "calenderData",
    initialState: {
        attendanceData: [],
        attendaceDetailsById: [],
        attendaceReportsList: [],
        attandanceRegulizationList: [],
        createRegulization: [],
        updateRegulization: [],
        regulizationById: [],
        monthlyAttandanceReportList: [],
        teamRegularList: [],
        consolidateMonthlyList: [],
        consolidateHeader: [],
        status: STATUSES.IDLE,
        error: '',
        dataLoader: STATUSES.IDLE,
        resumeUploadReportList: [],
        resumeUploadDownloadLink: [],
        resumeDownloadLink: []
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(attandanceList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(attandanceList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.attendanceData = action.payload;
            })
            .addCase(attandanceList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// attendace details by id 
            .addCase(attandanceDetailById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(attandanceDetailById.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.attendaceDetailsById = action.payload;
            })
            .addCase(attandanceDetailById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// attendace reports 
            .addCase(attandanceReport.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(attandanceReport.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_attendaceReportsList`;
                    state.attendaceReportsList = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(attandanceReport.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// attendace Regulization by List 
            .addCase(attandanceRegulizationByDate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(attandanceRegulizationByDate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_attandanceRegulizationList`;
                state.attandanceRegulizationList = action.payload;
            })
            .addCase(attandanceRegulizationByDate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            .addCase(createAttandanceRegulization.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createRegulization`;
                state.createRegulization = action.payload;
            })
            .addCase(createAttandanceRegulization.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createRegulization`
                state.error = action.error
            })

            .addCase(attandanceRegulizationById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_regulizationById`;
                state.regulizationById = action.payload;
            })
            .addCase(attandanceRegulizationById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            .addCase(updateAttandanceRegulization.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_updateRegulization`;
                state.updateRegulization = action.payload;
            })
            .addCase(updateAttandanceRegulization.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_updateRegulization`
                state.error = action.error
            })

            /// team attendace list 
            .addCase(teamAttandanceListByManager.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(teamAttandanceListByManager.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_teamRegularList`;
                state.teamRegularList = action.payload;
            })
            .addCase(teamAttandanceListByManager.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_teamRegularList`
                state.error = action.error
            })

            ///Month wise attendace reports 
            .addCase(monthlyAttandanceReport.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(monthlyAttandanceReport.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_monthlyAttandanceReportList`;
                    state.dataLoader = STATUSES.SUCCESS;
                    state.monthlyAttandanceReportList = action.payload;

                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(monthlyAttandanceReport.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///Consolidate Month wise  reports 
            .addCase(getConsolidateMonthlyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getConsolidateMonthlyList.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_consolidateMonthlyList`;
                    let headerArray2 = []
                    let data = action.payload;
                    if (data && data.data.headers && data.data.headers.length > 0) {
                        data.data.headers.map(e => {
                            let field_name = e === 'Employee Code' ? 'emp_code' :
                                e === "Employee Name" ? "display_name" :
                                    e === "Total Day" ? "total_day" :
                                        e === "Total Weekend" ? "total_weekend" :
                                            e === "Total Holiday" ? "total_holiday" :
                                                e === "Actual Working Day" ? "actual_working_day" :
                                                    e === "Present Days" ? "present_days" : e;
                            let field_width = e === 'Employee Code' ? 200 :
                                e === "Employee Name" ? 210 :
                                    e === "Total Day" ? 120 :
                                        e === "Total Weekend" ? 150 :
                                            e === "Total Holiday" ? 160 :
                                                e === "Actual Working Day" ? 200 :
                                                    e === "Present Days" ? 150 : 130;
                            let filter_status = e === 'Employee Code' ? true :
                                e === "Employee Name" ? true :
                                    e === "Total Day" ? false :
                                        e === "Total Weekend" ? false :
                                            e === "Total Holiday" ? false :
                                                e === "Actual Working Day" ? false :
                                                    e === "Present Days" ? false : false;
                            headerArray2.push({ field: field_name, headerName: e, width: field_width, filterable: filter_status })
                            return headerArray2
                        })
                    }
                    state.consolidateMonthlyList = action.payload;
                    if (headerArray2.length > 0) {
                        state.consolidateHeader = headerArray2
                    }

                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }

            })
            .addCase(getConsolidateMonthlyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get resume upload report list
            .addCase(getResumeUploadReportList.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_resumeUploadReportList`;
            })
            .addCase(getResumeUploadReportList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resumeUploadReportList`;
                state.resumeUploadReportList = action.payload;
            })
            .addCase(getResumeUploadReportList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_resumeUploadReportList`
                state.error = action.error
            })

            /// get resume upload download link
            .addCase(getResumeUploadReportLink.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_resumeUploadReportList`;
            })
            .addCase(getResumeUploadReportLink.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resumeUploadDownloadLink`;
                state.resumeUploadDownloadLink = action.payload;
            })
            .addCase(getResumeUploadReportLink.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_getResumeUploadDownloadLink`
                state.error = action.error
            })
            /// get resume  download link
            .addCase(getResumeDownloadLink.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_resumeUploadReportList`;
            })
            .addCase(getResumeDownloadLink.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resumeDownloadLink`;
                state.resumeDownloadLink = action.payload;
            })
            .addCase(getResumeDownloadLink.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_getResumeUploadDownloadLink`
                state.error = action.error
            })
    },
});


export default calenderSlice.reducer;