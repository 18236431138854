import { DialogContent, Dialog, DialogTitle, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import "./task.scss";
import ReactDatePicker from 'react-datepicker';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';


export const RemindModal = ({ openModal, setOpenModal, title, setStartRemindDate, startRemindDate, onSubmit, loading, formErr, errSatus }) => {
    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={openModal}
            onClose={setOpenModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Button
                edge="start"
                color="inherit"
                onClick={setOpenModal}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Close />
            </Button>
            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{title}</DialogTitle>
            <DialogContent style={{ height: '350px' }}>
                <form>
                    <div className="add-new-user-form">
                        <div className="formInput">
                            <label >
                                Date
                            </label>
                            <ReactDatePicker
                                name="date"
                                className="form-control form-control-sm"
                                type="text"
                                size="sm"
                                placeholder=""
                                selected={startRemindDate.date}
                                minDate={new Date()}
                                value={startRemindDate.date}
                                onChange={(evt) => setStartRemindDate(evt, 'date')}
                                dateFormat="dd/MM/yyyy"
                            />
                            {errSatus === true && startRemindDate && startRemindDate.date === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.dateErr}</p>}

                        </div>
                        <div className="formInput">
                            <label >
                                Time
                            </label>
                            <ReactDatePicker
                                name="date"
                                className="form-control form-control-sm"
                                type="text"
                                size="sm"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                placeholder=""
                                selected={startRemindDate.time}
                                minDate={new Date()}
                                value={startRemindDate.time}
                                onChange={(evt) => setStartRemindDate(evt, 'time')}
                                dateFormat="HH:mm:ss"
                            />
                            {errSatus === true && startRemindDate && startRemindDate.time === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.timeErr}</p>}

                        </div>
                        <div className="formInput">
                            <label >
                                Default Time In Min
                            </label>
                            <select
                                name={"default_time_in_mins"}
                                onChange={(evt) => setStartRemindDate(evt.target.value, 'default_time_in_mins')}
                                value={startRemindDate.default_time_in_mins}
                            >
                                <option>Select</option>
                                {GetLocalstorage.get_default_time_in_mins().map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                            {errSatus === true && startRemindDate && startRemindDate.date === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.default_time_in_minsErr}</p>}

                        </div>
                        <div className="formInput">
                            <label >
                                Comment
                            </label>
                            <textarea
                                name="comment"
                                className="form-control form-control-sm"
                                type="text"
                                minLength={3}
                                placeholder="comment here..."
                                value={startRemindDate.comment}
                                onChange={(evt) => setStartRemindDate(evt.target.value, 'comment')}
                            />
                            {errSatus === true && startRemindDate && startRemindDate.comment === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.commentErr}</p>}

                        </div>
                    </div>

                </form>
                {loading === false &&
                    <Button style={{ minWidth: '15%' }} className="submit-modal" onClick={() => onSubmit()} >Set</Button>
                }
            </DialogContent>
        </Dialog>
    )
}