import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { DefaultsettingColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Add, ArrowBack, Close } from "@mui/icons-material";
import TablePaginationActions from "../../components/TablePaginationActions";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { InputFields, InputRadioGroupFields, InputSelectField } from "../../components/common-function/InputFields";
import CONSTANTS from "../../components/constant/constantComponents";
import { defaultsettingCreate, defaultsettingEdit, defaultsettingUpdate, getDefaultsettingList } from "../../redux/features/projectSlice";
import { departmentDomainOptionList, departmentOptionList, projectTypeOptionList, workflowOptionList } from "../../redux/features/optionsSlice";
import Select from 'react-select';
import Filter from "../../components/Filter";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";

const initialFormData = Object.freeze({
    setting_name: "",
    setting_key: "",
    department: "",
    domain: "",
    project_type: "",
    workflow: "",
    status: "",
});

const formErrData = Object.freeze({
    nameErr: "Setting name is required",
    departmentErr: "Department is required",
    domainErr: "Domain is required",
    project_keyErr: "Project key is required",
    workflowErr: "Workflow is required",
    statusErr: "Status is required"
});

const DefaultSetting = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, defaultsetting_List, defaultsetting_Create, defaultsetting_Edit, defaultsetting_Update } = useSelector(state => state.projects)
    const { projectTypes, departments, workflows, departmentDomain } = useSelector(state => state.dropdownOptions)

    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [defaultValue, setDefaultValue] = useState({})

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])

    useEffect(() => {
        dispatch(getDefaultsettingList(filterQuery))
        dispatch(projectTypeOptionList())
        dispatch(departmentOptionList())
        dispatch(workflowOptionList())
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);;
        setErrStatus(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_defaultsetting_Update` && defaultsetting_Update && defaultsetting_Update.message) {
        if (defaultsetting_Update.status === 200) {
            toast.success(defaultsetting_Update.message)
            dispatch(getDefaultsettingList(filterQuery))
            handleClose()
        } else {
            toast.error(defaultsetting_Update.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_defaultsetting_Create` && defaultsetting_Create && defaultsetting_Create.message) {
        if (defaultsetting_Create.status === 200) {
            toast.success(defaultsetting_Create.message)
            dispatch(getDefaultsettingList(filterQuery))
            handleClose()
        } else {
            toast.error(defaultsetting_Create.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === "department") {
            setFormValues({ ...formValues, [name]: value })
            dispatch(departmentDomainOptionList(
                {
                    "filter": {
                        "status": { "value": "True", "operation": "eq" },
                        "department_id": { "value": evt.value, "operation": "eq" }
                    }, "no_limit": "True"
                }))
        }
        else {
            setFormValues({ ...formValues, [name]: value })
        }
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.setting_name === "" || formValues.project_type === "" || formValues.department === "" || formValues.domain === "" || formValues.workflow === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.level_name !== "" && formValues.project_type !== "" && formValues.department !== "" && formValues.domain !== "" && formValues.workflow !== "" && formValues.status !== "") {
            const ces_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = ces_user.user && ces_user.user.data && ces_user.user.data.id
            let addvalues = ({
                ...formValues,
                project_type: formValues.project_type && formValues.project_type.value ? formValues.project_type.value : formValues.project_type,
                department: formValues.department && formValues.department.value ? formValues.department.value : formValues.department,
                domain: formValues.domain && formValues.domain.value ? formValues.domain.value : formValues.domain,
                workflow: formValues.workflow && formValues.workflow.value ? formValues.workflow.value : formValues.workflow,
            })
            setErrStatus(false)
            setReload("submit")
            if (ID) {
                addvalues['updated_by'] = userId
                dispatch(defaultsettingUpdate({ ID, addvalues }))
            } else {
                addvalues['created_by'] = userId
                dispatch(defaultsettingCreate(addvalues))
            }
        }
    }

    const selectValueProjectType = (id) => {
        let data = projectTypes && projectTypes;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.project_type_name, name: "project_type" })
        })
        return newObject
    }

    const selectValueDepartment = (id) => {
        let data = departments && departments;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name, name: "department" })
        })
        return newObject
    }

    const selectValueDepartmentDomain = (id) => {
        let data = departmentDomain && departmentDomain;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.domain.id === id) && (newObject = { value: x.domain.id, label: x.domain.name, name: "domain" })
        })
        return newObject
    }

    const selectValueWorkFlow = (id) => {
        let data = workflows && workflows;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name, name: "workflow" })
        })
        return newObject
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && defaultsetting_Edit && defaultsetting_Edit.data) {
        let editData = defaultsetting_Edit.data
        if (editData && editData.department_id) {
            dispatch(departmentDomainOptionList({
                "filter": {
                    "status": { "value": "True", "operation": "eq" },
                    "department_id": { "value": editData.department_id, "operation": "eq" }
                }, "no_limit": "True"
            }))
        }
        setFormValues({
            ...formValues,
            setting_name: editData.setting_name,
            setting_key: editData.setting_key,
            project_type: selectValueProjectType(editData.project_type_id),
            department: selectValueDepartment(editData.department_id),
            domain: selectValueDepartmentDomain(editData.domain_id),
            workflow: selectValueWorkFlow(editData.workflow_id),
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(defaultsettingEdit(id))
        setReload('edit_data')
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getDefaultsettingList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getDefaultsettingList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(defaultsetting_List && defaultsetting_List.data && defaultsetting_List.data.rows && defaultsetting_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getDefaultsettingList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }

    const actionColumn = [
        {
            field: "project_type_id", headerName: "Project Type", width: 200, renderCell: (params) => {
                return (<div>{params.row.project_type} </div>)
            },
        },
        {
            field: "department_id", headerName: "Department", width: 175, renderCell: (params) => {
                return (<div>{params.row.department} </div>)
            },
        },
        {
            field: "domain_id", headerName: "Domain", width: 175, sortable: false, filterable: false, renderCell: (params) => {
                return (<div>{params.row.domain} </div>);
            },
        },
        {
            field: "workflow_id", headerName: "Workflow", width: 180, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.workflow ? params.row.workflow : ""} >
                        <div>{params.row.workflow} </div>
                    </Tooltip>);
            },
        },
        {
            field: "status", headerName: "Status", width: 100, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action", headerName: "Action", width: "90", hideable: false, filterable: false, sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="default-setting"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={false} />
                    </div>
                );
            },
        },
    ];

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            fieldName === "project_type_id" ?
                <Filter options={projectTypes} onChange={handleFilterChange} defaultValue={defaultValue} />
                : fieldName === "department_id" ?
                    <Filter options={departments} onChange={handleFilterChange} defaultValue={defaultValue} />
                    : fieldName === "workflow_id" ?
                        <Filter options={workflows} onChange={handleFilterChange} defaultValue={defaultValue} />
                        : ""
        );
    }

    const columns = React.useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "setting_name" || col.field === "status") {
                return col;
            }
            return {
                ...col,
                filterOperators: getGridSingleSelectOperators()
                    .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? CustomFilterInputValue
                            : undefined
                    }))
            };
        }),
        [actionColumn]
    );

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "is" ? 'eq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getDefaultsettingList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getDefaultsettingList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Default Setting List</h3>
                        <div>
                            <Tooltip title="Add New Default Setting">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={defaultsetting_List && defaultsetting_List.data && defaultsetting_List.data.rows ? defaultsetting_List.data.rows : []}
                        columns={DefaultsettingColumns.concat(columns)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add New'} Default Setting</DialogTitle>

                    <DialogContent style={{ height: "450px" }}>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Setting Name"}
                                    name="setting_name"
                                    defaultValue={formValues.setting_name}
                                    type="text"
                                    placeholder="Setting Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />
                                <InputSelectField
                                    label={'Project Type'}
                                    placeholder="Select Project Type"
                                    defaultValue={formValues.project_type}
                                    name={"project_type"}
                                    onChange={handleChange}
                                    optionList={projectTypes ? projectTypes : []}
                                    optionLevel={['project_type_name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.project_keyErr}
                                />
                                <InputSelectField
                                    label={'Department'}
                                    placeholder="Select Department"
                                    defaultValue={formValues.department}
                                    name={"department"}
                                    onChange={handleChange}
                                    optionList={departments ? departments : []}
                                    optionLevel={['name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.departmentErr}
                                />
                                <div className="formInput">
                                    <label>Domain</label>
                                    <Select
                                        className="select"
                                        placeholder="Select Domain"
                                        defaultValue={formValues.domain}
                                        name={"domain"}
                                        onChange={handleChange}
                                        options={
                                            departmentDomain && departmentDomain.map(e => ({ id: e.domain.id, label: e.domain.name, name: "domain", value: e.domain.id }))
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.domain === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.domainErr}</p>}

                                </div>
                                <InputSelectField
                                    label={'Workflow'}
                                    placeholder="Select Workflow"
                                    defaultValue={formValues.workflow}
                                    name={"workflow"}
                                    onChange={handleChange}
                                    optionList={workflows ? workflows : []}
                                    optionLevel={['name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.workflowErr}
                                />
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit" ? true : false}>
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    )
}
export default DefaultSetting