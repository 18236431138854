import React from 'react'
import './Workflows.scss'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from "react"
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"


import { workFlowList, statusList, workflowStatusList } from "../../redux/features/workflowSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import Button from '../../components/button/Button';
import BasicLayout from '../../components/layout/BasicLayout';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import CustomButton from '../../components/button/CustomButton';
import LoaderForm from '../../components/common-function/LoaderForm';
import moment from 'moment';
const { REACT_APP_ENV } = process.env;

const WorkflowListComponent = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const { workflows, count, status, status_List  } = useSelector(state => state.workflow)

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [params, setParams] = useState({})
  const [setFieldName] = useState("")
  const [setDefaultValue] = useState("")

  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);
  const [openAssignment, setOpenAssignment] = useState(false)
  const [updatingAssignment, setUpdatingAssignment] = useState(false);

  const statusParam = { filter: { is_jira_status: { value: "False", operation: "eq" } }, "no_limit": "True" }

  const [workFlowData, setworkFlowData] = useState({})
  const [workflowData, setWorkflowData] = useState({})

  const [editFormData, setEditFormData] = useState({})
  const [relatedStatusOption, setRelatedStatusOption] = useState([])
  const [status_RelatedStatusMapping, setStatus_RelatedStatusMapping] = useState([])
  const [finalStatusMapping, setFinalStatusMapping] = useState([])
  const [formData, setFormData] = useState({ ...workFlowData } || {})

  const [ setRelatedFields] = useState(false)
  const whereClause = { ...params, "order_by": { "column": "-id" }, "skip": skip, "limit": pageSize || 25 }

  const workflowColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Workflow Name",
      width: 275,
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 380,
      hideable: true,
      filterable: true,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,

      type: 'singleSelect',
      valueOptions: [
        { label: "Active", value: "True" },
        { label: "Inactive", value: "False" },
      ],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`} >
            <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
          </div>
        );
      },
    },
  ];


  const modalDetail = {
    title: "Workflows Details",
    editModalTitle: "Edit Workflow",
    addModalTitle: "Add Workflow",
    formInputs: [
      { id: 1, name: "name", label: "Workflow Name", placeholder: "Enter Workflow Name", type: "text", required: true, disabled: false },
      {
        id: 12, name: "status", label: "Status", placeholder: "Enter For Date", type: "select",
        options: [
          { id: 1, label: "Active", value: true },
          { id: 2, label: "Inactive", value: false },
        ],
        required: true, disabled: false
      },
      { id: 3, name: "description", label: "Description", placeholder: "Enter description", type: "textarea", required: false, disabled: false },
    ],
  }


  const onSubmit = async (data) => {
    const finalData = workflowData.id ? { ...workflowData, ...data, updated_by: userId } : { ...data, created_by: userId }
    const created_updated_by = workflowData.id ? "updated_by" : "created_by"
    // return;
    const res = await dataService.save_workflow({
      id: finalData.id,
      name: data.name,
      code: finalData.id ? finalData.code : data.name.replace(/ /g,"-").toLowerCase()  ,
      description: finalData.description,
      status: finalData.status,
      [created_updated_by]: userId
    });
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      onCloseModal();
      dispatch(workFlowList(whereClause))
    }
    else {
      toast.error(res.data.message)
    }
  }


  // Edit Shift
  const handleEdit = (data) => {
    setWorkflowData(
      {
        ...workflowData,
        id: data.id,
        name: data.name,
        code: data.code,
        description: data.description,
        status: data.status,
        created_by: userId
      });
    setUpdating(true)
    setEditable(true);
    setOpen(true);
  }

  // Delete Workflow
  const deleteWorkflow = async (id) => {
    const res = await dataService.delete_workflow(id)
    if (res && res.data && res.data.status === 200) {
      toast.success(res.data.message)
      dispatch(workFlowList(whereClause))
    } else {
      toast.error(res.data.message)
    }
  }

  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  AddHoliday Modal
  const onAddWorkflow = () => {
    setWorkflowData({});
    setOpen(true)
    setEditable(true);
  }

  //  Close Modal
  const onCloseModal = () => {
    setWorkflowData({});
    setUpdating(false);
    setOpen(false)
    setEditable(false);
  }

    //  AddWorkflow Modal
    // const onAddWorkflowAssignment = () => {
    //   setOpenAssignment(true)
    //   // setEditableAssignment(true);
    // }
  
    //  Close Modal
    const onCloseModalAssignment = () => {
      setOpenAssignment(false)
      setUpdatingAssignment(false);
      // setEditableAssignment(false);
      setEditFormData({});
      setworkFlowData({});
    }

    const handleChange = (evt) => {
      let eventValue = {}
      if (evt.type && evt.type === "date") {
        eventValue = { name: evt.name, type: evt.type, value: evt.format ? moment(evt.value).format(evt.format) : evt.value }
      }
      const { name, value, type, checked } = (evt && evt.target) ? evt.target : eventValue;
      const finalValue = type === "checkbox" ? checked : type === "select" ? value.value : value
      if (name === "workflow_status") {
        setStatus_RelatedStatusMapping(
          finalValue.map((option, index) => {
            return ({
              from_status: option,
              relatedStatusOptions: finalValue.filter((e, indx) => {
                if (index !== indx) { return e }
              })
            })
          })
        )
        setFinalStatusMapping(
          finalValue.map((option, index) => {
            return ({
              from_status: option.id,
              related_status: []
            })
          })
        )
        setRelatedStatusOption(finalValue);
      }
      setFormData(updatingAssignment ? { ...workFlowData, ...formData, [name]: finalValue } : { ...formData, [name]: finalValue })
    }
  
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const finalData = workFlowData.id ? { ...workFlowData, ...formData } : formData
      const created_updated_by = workFlowData.id ? "updated_by" : "created_by"
      const postData = {
        id: finalData.id,
        "workflow": finalData.workflow?.value || editFormData.workflow_id,
        workflow_status: finalData?.workflow_status ? finalData.workflow_status.map(e => { return e.id }) : editFormData.workflow_status,
        status_mapping: finalStatusMapping.map(e=>({
          "workflow": e.workflow,
          "workflow_status": e.workflow_status,
          "is_initial":finalData.default_status?.value===e.workflow_status,
          "related_status":e.related_status,
          "created_by": e.created_by
        })) ,
        "status": (finalData?.status?.value === true || finalData?.status?.value === false) ? finalData.status.value : editFormData.status,
        [created_updated_by]: userId
      };
      if (!postData?.workflow_status || !postData?.workflow) {
        toast.error("Enter the mandatory details!")
        return;
      }
  
      // return;
      const res = await dataService.assign_project_workflow(postData);
      if (res.data && res.data.status === 200) {
        toast.success(res.data.message)
        dispatch(workflowStatusList(whereClause))
        onCloseModalAssignment();
      }
      else {
        toast.error(res.data.message)
      }
      setFormData({})
    }

  
  const onView = (data) => {
         navigate("/project-workflows-mapping",{state:{...data, is_editing:false, is_viewing:true}})
  }
  
  const onAdditionalAction = async (data, key) => {
    if (key === "mapping") {
      navigate("/edit-project-workflows-mapping",{state:{...data, is_editing:true}})
    }
  }

  useEffect(() => {
    dispatch(workFlowList(whereClause))
  }, [skip, params])

  useEffect(() => {
    dispatch(statusList(statusParam))
    dispatch(workflowStatusList(whereClause))
  }, [])

  return (
    <BasicLayout>

      <div className="top-headings">
        <h3 >All Workflows </h3>
        <div>
          <Button title={" "}
            preAddIcon className="btn-add-new "
            onClick={() => navigate("/project-workflows-mapping",{state:{is_editing:false}})}
            tooltip="Add Workflow Mapping"
          />
          <Button title={" "}
            preAddIcon className="btn-add-new "
            onClick={onAddWorkflow}
            tooltip="Add Workflow"
          />
        </div>
      </div>

      <ToastContainer />
      {status === STATUSES.LOADING && <LoaderForm />}
  {
    !openAssignment ?
    <>
      <>
        {
          <DynamicTable
            moduleName="holidays"
            tableColumns={workflowColumns}
            tableRows={workflows || []}
            currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
            setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
            setFieldName={setFieldName} setDefaultValue={setDefaultValue}
            paginationMode={"server"}
            rowCount={count}
            loading={status === STATUSES.LOADING}
            columnVisibilityModel={{
              id: false,
            }}
            onViewDetail={onView}
            onEdit={handleEdit}
            deleteRow={deleteWorkflow}
            onAdditionalAction={onAdditionalAction}
            additionalAction={[{ id: 1, key: "mapping", title: "Edit Mapping" }]}
          />
        }
      </>
      {
        <RegularizeModal
          modalDetail={modalDetail}
          open={open}
          onOpen={onOpenModal}
          onClose={onCloseModal}
          onSubmit={onSubmit}
          hideBackdrop
          fullWidth
          maxWidth
          updating={updating}
          editData={workflowData}
          editable={editable}
          formLoading={formLoading}
          setFormLoading={setFormLoading}
          additionalActions={modalDetail.additionalActions}
        />
      }
    </>
    :
        <div>
          <div>
            <div>
              <form className="workflow-form" style={{ marginTop: "0", paddingBottom: "50px" }} onSubmit={handleSubmit}>
                <div className="formInput" >
                  <label className='label-heading'>
                    {"Workflow*"}
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={"Select"}
                    required
                    isSearchable={true}
                    name={"workflow"}
                     options={workflows.length && workflows.map(e => ({ id: e.id, label: e.name, value: e.id, name: "workflow" }))}
                    onChange={(evt) => { handleChange({ target: { name: "workflow", value: evt } }) }}
                  />

                </div>


                <div className="formInput" >
                  <label className='label-heading'>
                    {"Workflow Status"}
                  </label>

                  <Multiselect
                    isObject={true}
                    onRemove={(evt) => { handleChange({ target: { name: "workflow_status", value: evt } }) }}
                    onSelect={(evt) => { handleChange({ target: { name: "workflow_status", value: evt } }) }}
                    displayValue={"label"}
                    // required
                    placeholder={"Select Workflow-Status"}
                    selectedValues={workFlowData?.workflow_status}
                    options={status_List && status_List.map(e => {
                      if (!e.is_jira_project) {
                        return { id: e.id, label: e.name, value: e.id, name: "workflow_status" }
                      }
                    })}

                  />

                </div>

                <div className="formInput" >
                  <label className='label-heading'>
                    {"Default Workflow Status*"}
                  </label>
                  <Select
                    className="basic-single"
                    placeholder="Select"
                    isSearchable={true}
                    required
                    name={"default_status"}
                    defaultValue={ workFlowData.default_status || "" }
                    options={relatedStatusOption.length && relatedStatusOption || {}}
                    onChange={(evt) => { handleChange({ target: { name: "default_status", value: evt } }) }}
                  />

                </div>

                <div className="formInput" >
                  <label className='label-heading'>
                    {"Status*"}
                  </label>
                  <Select
                    className="basic-single"
                    placeholder="Select"
                    isSearchable={true}
                    required
                    name={"status"}
                    defaultValue={
                      (workFlowData?.status === true || workFlowData?.status === false) ?
                        { label: workFlowData?.status ? "Active" : "Inactive", value: workFlowData?.status } : ""}
                    options={
                      [
                        { id: 1, name: "Active", label: "Active", value: true },
                        { id: 2, name: "Inactive", label: "Inactive", value: false }
                      ]
                    }
                    onChange={(evt) => { setRelatedFields(true); handleChange({ target: { name: "status", value: evt } }) }}
                  />

                </div>

                <div className="formInput" ></div>

                <div className="formInput" ></div>
                <>
                  <div className="formInput" >
                    <label className='label-heading'>
                      {"From Status"}
                    </label>
                  </div>
                  <div className="formInput" >
                    <label className='label-heading'>
                      {"Related Status"}
                    </label>
                  </div>
                  {
                    // relatedFields &&
                      status_RelatedStatusMapping && status_RelatedStatusMapping.length &&
                    status_RelatedStatusMapping.map((element, index) => {
                      return(
                        <React.Fragment key={index}>
                              <div className="formInput">
                              <Select
                                  className="basic-single"
                                  placeholder="Select"
                                  disabled
                                  required
                                  name={"status"}
                                  defaultValue={{ label: element.from_status.label }}
                                  options={
                                    [
                                      { label: element.from_status.label },
                                    ]
                                  }
                                />
                              </div>
                              <div className="formInput" >
      
                                <Multiselect
                                  isObject={true}
                                  displayValue={"label"}
                                  placeholder={"Select Related-Status"}
                                  options={element.relatedStatusOptions}
                                />
      
                              </div>
                            </React.Fragment>

                      )
                    })
                  }
                </>


                <CustomButton
                  loading={false}
                  type={"submit"}
                />
              </form>
            </div>
          </div>
        </div>
  }
    </BasicLayout>
  )
}

export default WorkflowListComponent
