import Navbar from "../../../components/navbar/Navbar"
import Sidebar from "../../../components/sidebar/Sidebar"
import "../master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Add, ArrowBack, Close } from "@mui/icons-material";
import UcActionComponent from "../../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../../components/TablePaginationActions";
import { InputFields, InputRadioGroupFields, InputTextareaField, ViewField } from "../../../components/common-function/InputFields";
import LoaderForm from "../../../components/common-function/LoaderForm";
import { STATUSES } from "../../../redux/common-status/constant";
import CONSTANTS from "../../../components/constant/constantComponents";
import DeleteDialogModal from "../../../components/common-function/DeleteDialogModal";
import { getSourceTypeList, sourceTypeArchive, sourceTypeCreate, sourceTypeEdit, sourceTypeUpdate } from "../../../redux/features/marketingMasterSlice";
import { sourceTypeColumns } from "../../../components/datatable-coulms/tableCoulmns";
import LocalStorageDataService from "../../../components/common-function/GetLocalstorage";
import CustomNoRowsOverlay from "../../../components/CustomEmptyOverlay";

const user = LocalStorageDataService.userDetail();
const userId = user && user.id

const initialFormData = Object.freeze({
    source_type_name: "",
    description: "",
    status: "",
});

const formErrData = Object.freeze({
    descriptionErr: "Description is required",
    sourceType_nameErr: "Source type name is required",
    statusErr: "Status is required"
});

const SourceType = () => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, sourceType_List, sourceType_Create, sourceType_Edit, sourceType_Update, sourceType_Archive } = useSelector(state => state.marketingMaster)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [activeViewMode, setActiveViewMode] = useState(false)

    useEffect(() => {
        dispatch(getSourceTypeList(filterQuery))
    }, [dispatch])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false)
        setActiveViewMode(false)
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_sourceType_Update` && sourceType_Update && sourceType_Update.message) {
        if (sourceType_Update.status === 200) {
            toast.success(sourceType_Update.message)
            dispatch(getSourceTypeList(filterQuery))
            handleClose()
        } else {
            toast.error(sourceType_Update.message)
        }
        setReload(false)
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_sourceType_Create` && sourceType_Create && sourceType_Create.message) {
        if (sourceType_Create.status === 200) {
            toast.success(sourceType_Create.message)
            dispatch(getSourceTypeList(filterQuery))
            handleClose()
        } else {
            toast.error(sourceType_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_sourceType_Archive` && sourceType_Archive && sourceType_Archive.message) {
        if (sourceType_Archive.status === 200) {
            toast.success(sourceType_Archive.message)
            dispatch(getSourceTypeList(filterQuery))
        } else {
            toast.error(sourceType_Archive.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.source_type_name === "" || formValues.description === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.source_type_name !== "" && formValues.description !== "" && formValues.status !== "") {
            let addvalues = { ...formValues }
            setErrStatus(false)
            setReload('submit')
            if (ID) {
                addvalues['updated_by'] = userId
                dispatch(sourceTypeUpdate({ ID, addvalues }))
            } else {
                addvalues['created_by'] = userId
                dispatch(sourceTypeCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && sourceType_Edit && sourceType_Edit.data) {
        let editData = sourceType_Edit.data
        setFormValues({
            source_type_name: editData.source_type_name,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    if (reload === 'view_data' && status === `${STATUSES.SUCCESS}` && sourceType_Edit && sourceType_Edit.data) {
        let editData = sourceType_Edit.data
        setFormValues({
            source_type_name: editData.source_type_name,
            description: editData.description,
            status: editData.status
        })
        setActiveViewMode(true)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(sourceTypeEdit(id))
        setReload('edit_data')
    }

    const handleViewDetail = (id) => {
        dispatch(sourceTypeEdit(id))
        setReload('view_data')
        setActiveViewMode(true)
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        dispatch(sourceTypeArchive(id))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getSourceTypeList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getSourceTypeList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(sourceType_List && sourceType_List.data && sourceType_List.data.rows && sourceType_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getSourceTypeList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }

    // action columns
    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="source-type"
                            rowid={params.row.id}
                            addMoreData={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={handleViewDetail}
                            deleteButton={handleArchive} />
                    </div>
                );
            },
        },
    ];

    //filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getSourceTypeList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getSourceTypeList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    {/* heading */}
                    <div className="top-headings">
                        <h3>Source Type List</h3>
                        <div>
                            <Tooltip title={"Add  New Source Type"}>
                                <button onClick={handleOpen} className="btn-add-new"><Add /></button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {/* data grid (table) */}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={sourceType_List && sourceType_List.data && sourceType_List.data.rows ? sourceType_List.data.rows : []}
                        columns={sourceTypeColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
                {/* start add/edit/view popup */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                        {ID ? 'Edit' : activeViewMode === true ? "View" : 'Add New'} Source Type
                    </DialogTitle>
                    {/* view form*/}
                    {activeViewMode === true ?
                        <DialogContent>
                            <div>
                                <form className="regulazation-form">
                                    <div style={{ marginBottom: "50px" }}>
                                        <ViewField
                                            label={'Source Type Name'}
                                            key={'source_type_name'}
                                            type={1}
                                            value={formValues.source_type_name}
                                        />
                                        <ViewField
                                            label={'Description'}
                                            key={'editor'}
                                            fieldType={'editor'}
                                            type={1}
                                            value={formValues.description}
                                        />
                                        <ViewField
                                            label={'Status'}
                                            key={'status'}
                                            type={1}
                                            value={formValues.status}
                                        />
                                    </div>
                                    {/* <br />
                                    <br /> */}
                                </form>
                            </div>
                        </DialogContent>
                        :
                        // add/edit form
                        <DialogContent>
                            <form>
                                <div className="add-new-user-form">
                                    <div className='formInput' style={{ flexBasis: "100%" }}>
                                        <InputFields
                                            label={"Source Type Name"}
                                            name="source_type_name"
                                            defaultValue={formValues.source_type_name}
                                            type="text"
                                            placeholder="Source Type Name"
                                            onChange={handleChange}
                                            errStatus={errStatus}
                                            formErr={formErr.sourceType_nameErr}
                                        />
                                    </div>

                                    <InputTextareaField
                                        label={"Description"}
                                        defaultValue={formValues.description}
                                        name="description"
                                        onChange={handleChange}
                                        rows="3"
                                        placeholder="Description"
                                        errStatus={errStatus}
                                        formErr={formErr.descriptionErr}
                                    />

                                    <InputRadioGroupFields
                                        label={"Status"}
                                        name="status"
                                        defaultValue={formValues.status}
                                        type="text"
                                        placeholder="Status"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.statusErr}
                                    />

                                </div>
                                <button className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                    disabled={reload === "submit" ? true : false} onClick={submitData}>
                                    {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                                </button>
                            </form>
                        </DialogContent>
                    }
                </Dialog>
                {/* delete popup */}
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive source type'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default SourceType