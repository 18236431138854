import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { history } from './history';

export { PrivateRoute };

const { REACT_APP_ENV } = process.env;

function PrivateRoute({ children, path }) {

  const location = useLocation()
  const [menuData, setMenuData] = useState([])

  const localStorageVar = localStorage.getItem('menuData');
  const localMenuData = localStorageVar && JSON.parse(localStorageVar);

  if (menuData && menuData.length === 0) {
    if (localMenuData && localMenuData.data && localMenuData.data.rows) {
      setMenuData(localMenuData?.data?.rows)
    }
  }

  const auth = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? true : false;
  // return auth ? children : <Navigate to="/" state={{ from: history.location }} />;

  return auth ? (location.pathname === '/dashboard' || location.pathname === '/my-task') ? children
    : menuData && menuData.find(ele => ele.menu === path)
      ? children
      : <Navigate replace to={"/access-denied"} />
    : <Navigate to="/" state={{ from: history.location }} />;
}