import React, { useEffect, useState } from 'react'
import "./login.scss";
import Logo from "../../images/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, userlogin } from "../../redux/features/authSlice";
// import Button from '../../components/button/Button';
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from '../../components/common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';

import { getAsigneRoleMenuList } from '../../redux/features/masterSlice';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
const user = LocalStorageDataService.userDetail();
const tmsRole = user.tms_role_id
const tmsRole_key = user.tms_role && user.tms_role.role_key

const roleWiseFilter = tmsRole ? `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRole},"operation": "eq"}},"no_limit":True}` : ""

const Login = () => {
  const { REACT_APP_ENV } = process.env;

  const dispatch = useDispatch();
  const { authData, status, token, authLoggedIn, error, loginError } = useSelector((state) => state.authUser)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState('');
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const [afterUserApiLoader, setAfterUserApiLoader] = useState(false)

  const validate = (email) => {
    const errors = {}

    if (!email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Invalid email address'
    } else {
      errors.email = true
    }

    return errors
  }

  const HandleLogin = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password
    }

    if (!email) {
      setFormErrors({ 'emailErr': ' Email is Required' })

    } else
      if (!password) {
        setFormErrors({ 'passwordErr': 'Password is Required' })

      } else
        if (!email && !password) {
          setFormErrors({ 'Err': 'Email and Password is Required' })

        } else
          if (email && password) {
            setFormErrors('');
            const isEmail = validate(email)
            if (isEmail.email === true) {
              setLoader(true)
              dispatch(login(data, navigate));
            }
            else {
              setFormErrors({ 'emailErr': 'Invalid email address' })
            }
          }
  }

  if (loader === true && token && token.token) {
    setLoader(false)
    setAfterUserApiLoader(true)
    dispatch(userlogin(token.token));
  }

  if (authLoggedIn === false && error && error.status !== 200 && afterUserApiLoader === true) {
    setAfterUserApiLoader(false)
    toast.error(error.message)
  }

  if (authLoggedIn === true && authData) {
    roleWiseFilter && dispatch(getAsigneRoleMenuList(roleWiseFilter))
    if (authData.data && authData.data.tms_role && authData.data.tms_role.role_key.match('agent')) {
      let filter = { "filter": { "status": { "value": "True", "operation": "eq" }, "role_id": { "value": authData.data.tms_role_id, "operation": "eq" } }, "no_limit": "True" }
      let tokens = token.token
      dispatch(getAsigneRoleMenuList({ tokens, filter }))
      navigate("/my-task");
      window.location.reload();
    }
    else {
      navigate("/dashboard");
      window.location.reload();
    }
  }

  if (status === STATUSES.FAILURE && authLoggedIn === false && error && error.message !== "Success" && loader === true) {
    toast.error(error.message)
    setLoader(false)
  }

  if (status === STATUSES.FAILURE && loginError && loginError.message && loginError.message !== "Success" && loader === true && error === "") {
    toast.error(loginError.message)
    setLoader(false)
  }
  useEffect(() => {
    if (localStorage.getItem(`${REACT_APP_ENV}_rtms`)) {
      if (tmsRole_key && tmsRole_key.match("agent")) {
        navigate("/my-task");
      } else {
        navigate('/dashboard')
      }
    }
  }, [])


  return (

    <div className="signin">
      <div className="sign-in-row">
        <div className="signin-col-1">
          <img src={Logo} alt={Logo} className="left-logo-icon" />
        </div>
        <div className="signin-col-2">
          <img src={Logo} alt={Logo} />
          <h4>Sign in</h4>
          <ToastContainer />
          <form onSubmit={HandleLogin}>
            <div className="formInput">
              <label >
                Email Address
              </label>
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {formErrors && formErrors.emailErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.emailErr}</p>}
            </div>


            <div className="formInput">
              <label >
                Password
              </label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {formErrors && formErrors.passwordErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.passwordErr}</p>}
              {formErrors && formErrors.Err && <p style={{ color: "#96332c", fontSize: 13 }} >{formErrors.Err}</p>}
              {/* {status === 'error' && <p style={{ color: "#96332c", fontSize: 13 }}>{error.message}</p>} */}
            </div>

            <p className="text-right"><Link to={"/forgot-password"} className="forgot-password-link">Forgot Password</Link></p>
            <button type="submit" className="btn btn-submit">Submit</button>
            {/* <Button 
              // loading={loader} 
              disabled={loader} 
              type={"submit"} 
              title={"Submit"}
            /> */}
            {loader && <LoaderForm />}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
