import "./report.scss"
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { FormControl, TablePagination, Tooltip } from "@mui/material"
import { ArrowBack } from "@mui/icons-material"
import { toast, ToastContainer } from "react-toastify"
import { DataGrid } from "@mui/x-data-grid"
import { useDispatch, useSelector } from "react-redux"
import { getAgentCallReport, getAgentWiseResource } from "../../redux/features/agentCallPerformanceReportSlice"
import { STATUSES } from "../../redux/common-status/constant"
import LoaderForm from "../../components/common-function/LoaderForm"
import moment from "moment"
import TablePaginationActions from "../../components/TablePaginationActions"
import Select from "react-select";
import { DateRangePicker } from 'rsuite';
import ShareModule from "../../components/common-function/ShareModule"
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay"
//import dataService from "../../redux/services/data.service"
import { TaskModal } from "../task/TaskModal"


export default function AgentCallPerformance() {
    const { REACT_APP_API_BASEURL } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { agentCallReportList, status, error, agentResourceList } = useSelector(state => state.agentCallPerformanceReport)
    const { data: list } = agentCallReportList

    let current_date_filter = { "value": [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], "operation": "range" }
    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [filterQuery, setFilterQuery] = useState({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": 25 })
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [loading, setLoading] = useState(true)
    const [downliadFile, setDownloadFile] = useState(false)
    const [selectAgent, setSelectAgent] = useState("")
    const [date, setDate] = useState([new Date(), new Date()])

    //form
    const [openSingle, setOpenSingle] = useState(false)
    const [callDetailList, setCallDetailList] = useState([])
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        dispatch(getAgentWiseResource(`{"order_by":{"column":"-id"}, "no_limit":True, "columns":["id", "display_name", "email"]}`))
    }, [])


    useEffect(() => {
        dispatch(getAgentCallReport(filterQuery))
    }, [filterQuery])

    if (loading === true && agentCallReportList) {
        if (agentCallReportList.status === 200) {
            //toast.success(callDetails.message)
        } else {
            toast.error(agentCallReportList.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
            setDownloadFile(false)
        }
    }, [error])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = filterQuery
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery({ ...filterQuery, ...newFilterData })
        // dispatch(getAgentCallReport(newFilterData))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = filterQuery
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery({ ...filterQuery, ...newFilterData })
        // dispatch(getAgentCallReport(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue
            const filterData = { "filter": { [filterModel.items[0].columnField]: { "value": filterModel.items[0].value, "operation": operatorValue } }, "skip": 0, "limit": limit }
            setFilterQuery(filterData)
            setCurrentPage(0)
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            // setPageSize(10)
            setFilterQuery({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": limit })
        }
    }

    const handleSort = (sort) => {
        if (sort.length) {
            setFilterQuery({
                ...filterQuery,
                "skip": 0, "limit": 10,
                order_by: {
                    "column": sort[0].sort === "desc" ?
                        '-' + sort[0].field : sort[0].field
                }
            })
        } else {
            // setFilterQuery({ "skip": 0, "limit": 10, "order_by": { "column": "-id" } })
            setFilterQuery({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": 10 })
        }
    }
    const updateDateTime = (evt) => {
        setDate(evt)
    }

    const openDrawer = (json, type) => {
        let filter = { "filter": { "call_date": { "value": json.date, "operation": "eq" }, "agent_name": { "value": json.resource_name, "operation": "eq" } } }
        if (type) {
            filter['filter']['direction'] = { "value": type, "operation": "eq" }
        }
        navigate('/all-call-details', { state: { filter } })
    }

    const handleClickPopover = async (json) => {
        //let filter = { "filter": { "call_date": { "value": json.date, "operation": "eq" } } }
        // const req = await dataService.get_call_log(JSON.stringify(filter))
        // const result = req.data;
        let totalInBound = [];
        //let totalOutBound = []
        // if (result.status === 200) {
        //     result.data && result.data.rows.map((e, i) => {
        //         return totalInBound.push({
        //             id:i,
        //             call_type:e.call_type,
        //             call_date:e.call_date,
        //             call_duration:e.call_duration
        //         })
        //     })
        // }
        json.job_details && json.job_details.map((e, i) => {
            totalInBound.push({
                id: i,
                outbound: e.total_durations_out_hrs,
                inbound:e.total_durations_in_hrs,
                call_date: json.date,
                call_duration: e.total_calls,
                title:e.summary
            })
        })
        setCallDetailList(totalInBound)
        setOpenModal(true);
    }

    const handleClose = () => {
        setCallDetailList([])
        setOpenModal(false);
    }



    const search = () => {
        let array = []
        selectAgent && selectAgent.length > 0 && selectAgent.map((o) => array.push(o.value));
        let filterCondition;

        let date_filter = date && { "value": [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')], "operation": "range" }

        if (date && date.length > 0 && selectAgent && selectAgent.length > 0) {
            filterCondition = {
                "filter": {
                    "call_date": date_filter,
                    "resource_id": { "value": array, "operation": "in" }
                }
            }
        } else if (date && date.length > 0) {
            filterCondition = {
                "filter": {
                    "call_date": date_filter,
                }
            }
        } else if (selectAgent && selectAgent.length > 0) {
            filterCondition = { "filter": { "resource_id": { "value": array, "operation": "in" } } }
        } else {
            filterCondition = { "filter": { "call_date": { "value": current_date_filter, "operation": "in" } }, "skip": 0, "limit": 10 }
        }
        filterCondition['skip'] = 0
        filterCondition['limit'] = limit
        // filterCondition['order_by'] = { "column": '-id' }
        setLoading(true)
        setFilterQuery(filterCondition)

    }

    const clear = () => {
        setSelectAgent("")
        setDate([new Date(), new Date()])
        setLoading(true)
        // setFilterQuery({ "skip": 0, "limit": 10, "order_by": { "column": "-id" } })
        setFilterQuery({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": 10 })
    }

    // const refresh = () => {
    //     setLoading(true)
    //     dispatch(getAgentCallReport(filterQuery))
    // }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
        delete filterQuery['for_download']
        delete filterQuery['no_limit']
        let newFilterData = filterQuery
        setFilterQuery(newFilterData)
        dispatch(getAgentCallReport(newFilterData))
    }

    if (downliadFile === true && agentCallReportList && agentCallReportList.data) {
        let api_url = agentCallReportList && agentCallReportList.data && agentCallReportList.data.download_api_url;
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
            }
        }
    }

    const downloadCsvFile = () => {
        setDownloadFile(true)
        let newFilter = filterQuery
        delete newFilter['skip']
        delete newFilter['limit']
        newFilter['for_download'] = "True"
        newFilter['no_limit'] = "True"
        dispatch(getAgentCallReport(newFilter))
    }

    const agentCallReportColumns = [
        { field: 'id', headerName: 'ID', width: 10, filterable: false },
        {
            field: 'resource_name', headerName: 'Agent Name', width: 190, filterable: false,
            renderCell: (params) => {
                return (
                    <div  style={{cursor:'pointer'}} onClick={() => handleClickPopover(params.row)} className="cellWithStatus hover-call">
                        {params.row.resource_name}
                    </div>
                );
            },
        },
        {
            field: "date", headerName: "Call Date", type: 'date', width: 160, filterable: false,
            renderCell: (params) => {
                return (
                    <div  style={{cursor:'pointer'}} onClick={() => handleClickPopover(params.row)} className="cellWithStatus hover-call">
                        {moment(params.row.date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        {
            field: 'total_durations_in_and_out_hrs',
            headerName: 'Total Call Duration',
            width: 260,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div style={{cursor:'pointer'}} onClick={() => { openDrawer(params.row) }} className="cellWithStatus hover-call">
                        {
                            (params.row.total_durations_in_and_out_percent) + " % " +
                            ("(" + params.row.total_durations_in_and_out_hrs + ")")
                        }
                    </div>
                );
            },
        },
        {
            field: 'total_durations_out_hrs',
            headerName: 'Total Out Bound Call Duration',
            width: 262,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div  style={{cursor:'pointer'}} onClick={() => { openDrawer(params.row, 1) }} className="cellWithStatus hover-call">
                        {
                            (params.row.total_durations_out_percent) + " % " +
                            ("(" + params.row.total_durations_out_hrs + ")")
                        }
                    </div>
                );
            },
        },
        {
            field: 'total_durations_in_hrs',
            headerName: 'Total In Bound Call Duration',
            width: 262,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div  style={{cursor:'pointer'}} onClick={() => { openDrawer(params.row, 2) }} className="cellWithStatus hover-call">
                        {
                            (params.row.total_durations_in_percent) + " % " +
                            ("(" + params.row.total_durations_in_hrs + ")")
                        }
                    </div>
                );
            },
        },
        {
            field: 'remaining_total_effective_call_durations_in_and_out_hrs',
            headerName: 'Total Remaining Call Duration',
            width: 260,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {
                            (params.row.remain_in_percent) + " % " +
                            ("(" + params.row.remaining_total_effective_call_durations_in_and_out_hrs + ")")
                        }
                    </div>
                );
            },
        },
        { field: 'total_jobs', headerName: 'Jobs Count', width: 150, filterable: false,
        renderCell: (params) => {
            return (
                <div   style={{cursor:'pointer'}} onClick={() => handleClickPopover(params.row)} className="cellWithStatus hover-call">
                    {params.row.total_jobs}
                </div>
            );
        }, },

    ]

    //  Close Modal
    const onCloseModal = () => {
        setOpenSingle(false)
    }

    let i = 0
    return (
        <div className="report">
            <Sidebar />
            <div className="reportContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Agent Call Performance Report</h3>
                        <Tooltip title="Back">
                            <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                        </Tooltip>
                    </div>

                    <div className="advance-search-input">
                        <div>
                            <FormControl >
                                <DateRangePicker
                                    format="dd-MM-yyyy"
                                    showMeridian
                                    value={date}
                                    onChange={(evt) => updateDateTime(evt)}
                                />
                            </FormControl>
                            <FormControl style={{ width: "250px", marginLeft: "10px" }}>
                                <Select
                                    placeholder="Select Agents"
                                    isMulti
                                    isClearable
                                    value={selectAgent}
                                    onChange={(evt) => { setSelectAgent(evt) }}
                                    options={agentResourceList && agentResourceList.data && agentResourceList.data.rows &&
                                        agentResourceList.data.rows.length > 0
                                        ? agentResourceList.data.rows.map(e => { return { value: e.id, label: e.display_name + "(" + e.email + ")" } })
                                        : []
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="search-btn-section-class">
                            <button
                                disabled={status === `${STATUSES.LOADING}_agentCallReportList`}
                                className="filter-btn-primary"
                                onClick={() => search()}
                                style={status === `${STATUSES.LOADING}_agentCallReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                Search
                            </button>
                            <button
                                disabled={status === `${STATUSES.LOADING}_agentCallReportList`}
                                className="filter-btn-primary"
                                onClick={() => clear()}
                                style={status === `${STATUSES.LOADING}_agentCallReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                Clear
                            </button>
                            {/* <button
                                disabled={status === `${STATUSES.LOADING}_agentCallReportList`}
                                className="filter-btn-primary"
                                onClick={() => refresh()}
                                style={status === `${STATUSES.LOADING}_agentCallReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                Refresh
                            </button> */}
                            <button
                                disabled={status === `${STATUSES.LOADING}_agentCallReportList`}
                                className="filter-btn-primary"
                                onClick={() => setOpenSingle(true)}
                                style={status === `${STATUSES.LOADING}_agentCallReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                Share
                            </button>
                            <button
                                disabled={status === `${STATUSES.LOADING}_agentCallReportList`}
                                className="filter-btn-primary"
                                onClick={() => downloadCsvFile()}
                                style={status === `${STATUSES.LOADING}_agentCallReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                Download as CSV
                            </button>
                        </div>
                    </div>

                    <ToastContainer />
                    {(loading === true || status === `${STATUSES.LOADING}_agentCallReportList`) && <LoaderForm />}
                    {list && list.last_synced && <p style={{ marginLeft: '82%', color: '#606860', fontWeight: 'bold' }}>Last Synced Date : {list.last_synced.last_updated_date} {list.last_synced.last_updated_time} </p>}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={list && list.rows ? list.rows : []}
                        columns={agentCallReportColumns}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        getRowId={(row) => row + i++}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay

                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                {/* {/ popup for share page /} */}
                <ShareModule
                    open={openSingle}
                    close={onCloseModal}
                    filter={filterQuery}
                    apiServiceName={getAgentCallReport}
                    dowunloadResponse={agentCallReportList}
                    moduleName="agentCallPerformance"
                    subject="Agent Call Performance Report"
                />

                <TaskModal
                    openModal={openModal}
                    setOpenModal={handleClose}
                    title={"View Details"}
                    formtype="data-grid"
                    list={callDetailList}
                    columns={[
                        { field: 'title', headerName: 'Title', width: 230, filterable: false },
                        { field: 'call_date', headerName: 'Call Date', width: 150, filterable: false },
                        { field: 'call_duration', headerName: 'Total Calls', width: 200, filterable: false },
                        { field: 'outbound', headerName: 'Out Bound', width: 150, filterable: false },
                        { field: 'inbound', headerName: 'In Bound', width: 150, filterable: false }
                    ]}
                />
            </div>
        </div>

    )
}
