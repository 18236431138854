import "./manageshift.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import api from "../../redux/APIS/api";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddShift = () => {

  const navigate=useNavigate()
 const editFormData=useLocation().state;
  const [formData, setFormData]=useState({...editFormData, "created_by": LocalStorageDataService.userDetail().id,} || {"created_by": LocalStorageDataService.userDetail().id,});
  const [loading, setLoading] = useState(false)

  const handleChange=(evt) => { 
      let {name, type, value, checked}=evt.target;
      
      value=type==="checkbox" ? checked :type==="time" ? value.length < 8 ? value + ":00" : value : value;
      
      setFormData({...formData, [name]:value})
      
    }
    
    const handleSubmit= async (evt) => { 
        setLoading(true)
        evt.preventDefault();
        const res=formData.id ? await api.put("/mst/shift/"+formData.id, {...formData, timing_display:"24Hours", created_by:LocalStorageDataService.userDetail().id }) : await api.post("/mst/shift", {...formData, timing_display:"24Hours", created_by:LocalStorageDataService.userDetail().id})
        
        if(res){
            if(res.data && res.data.data){
                toast.success(res.data.message) ;
                setTimeout(() => {
                    navigate("/all-shift", {replace:true})
                    setLoading(false)
                }, 3000);
            }
            else{
                toast.error(res.data.message)
                setLoading(false)
            }
        
      }
    }
  

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3> {formData.id ? "Edit Shift" :"Add new Shift"} </h3>
                    <Tooltip title="Back">
                    <Link to={"/all-shift"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                    
                </div>
                <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <div className="add-new-user-form">
                                {loading && <LoaderForm />}
                            <div className="formInput">
                                <label >
                                    Shift Name
                                </label>
                                <input
                                    name={"shift_name"}
                                    disabled={editFormData && editFormData.shift_name}
                                    defaultValue={formData.shift_name}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Shift Name"
                                />

                            </div>

                            <div className="formInput">
                                <label >
                                    Shift Key
                                </label>
                                <input
                                    name={"shift_key"}
                                    disabled={editFormData && editFormData.shift_name}
                                    defaultValue={formData.shift_key}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Shift Key"
                                />

                            </div>

                            <div className="formInput">
                                <label >
                                    From Time
                                </label>
                                
                                <input
                                    name="start_time"  
                                    defaultValue={formData.start_time}
                                    onChange={handleChange}
                                    type="time"
                                    placeholder="Shift Starts"
                                />

                            </div>

                            <div className="formInput">
                                <label >
                                    To Time
                                </label>
                                
                                <input
                                    name="end_time"  
                                    defaultValue={formData.end_time}
                                    onChange={handleChange}
                                    type="time"
                                    placeholder="To Time"
                                />

                            </div>
                            

                            <div className="formInput">
                                <label >
                                    Time Zone
                                </label>
                                <select 
                                name={"time_zone"} 
                                defaultValue={formData.time_zone}
                                onChange={handleChange}
                                >
                                    <option label="">Select</option>
                                    <option value={"ist"} label="">India Standard Time</option>
                                    <option value={"others"} label="">Others</option>
                                </select>

                            </div>

                            <div className="formInput">
                                <label >
                                    Description
                                </label>

                                <textarea rows="3" 
                                name={"description"}  
                                defaultValue={formData.description}
                                onChange={handleChange}
                                placeholder="Short Description"
                                >
                                </textarea>

                            </div>
                            <div className="formInput">
                                <label >
                                    Status
                                </label>
                                <select 
                                name={"status"} 
                                defaultValue={formData.status}
                                onChange={handleChange}
                                >
                                    <option value={true} label="">Active</option>
                                    <option value={false} label="">Inactive</option>
                                </select>

                            </div>
                        
                        </div>
                        
                        <button type="submit" disabled={loading} className="btn btn-submit">Submit</button>
                    </form>
            </div>
        </div>
    )
}

export default AddShift