import './emailTemplate.scss';
import { AttachFile, Close } from '@mui/icons-material'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Tooltip } from '@mui/material'
import { CKEditor } from 'ckeditor4-react';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getCategoryListEmail, sendEmail } from '../../redux/features/emailSlice';
// import Select from "react-select";
import LocalStorageDataService from "../common-function/GetLocalstorage";
import LoaderForm from '../common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';
import { ZoomCreate } from '../zoom-create/ZoomCreate';
import { createZoomMeeting } from '../../redux/features/zoomSlice';
// const { REACT_APP_COMPANY_HR_MAIL, REACT_APP_COMPANY_NAME } = process.env;
import CreatableSelect from 'react-select/creatable';
import { resourceListOption } from '../../redux/features/optionsSlice';
import { DatePicker } from 'rsuite';
import TimezoneSelect from 'react-timezone-select'
import moment from 'moment';
import isBefore from 'date-fns/isBefore';

const formErrData = Object.freeze({
    toErr: 'To is required',
    subjectErr: "Subject is required",
    bodyErr: "Body is required",
});

const EmailTemplet = ({ setShowEmailModel, to_email, project_id, data, story_id }) => {
    const initialFormData = Object.freeze({
        mail_type: "html",
        to: to_email || [],
        cc: [""],
        subject: "",
        body: "",
        from: "jobs@unicodesystems.in",
        epic_id: data && data.epic_id,
        story_id: story_id,
    });

    const { resources } = useSelector(state => state.dropdownOptions)
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    // const { status, error, send_email, categoryListEmail, emailTemplateMaster, subject, body } = useSelector(state => state.emailSlice)
    const { status, error, send_email } = useSelector(state => state.emailSlice)
    const { create_zoom_meeting, } = useSelector(state => state.zoomSlice)
    const [reload, setReload] = useState(false)
    const [validateErr, setValidateErr] = useState("")
    const [bodyData, setBodyData] = useState("")

    const [showSubmitBtn, setShowSubmitBtn] = useState(true)
    // const [emailTemplatesId, setEmailTemplatesId] = useState(0)
    // const [categoryId, setCategoryId] = useState(0)
    // const [subjectData, setSubjectData] = useState("")

    const [showZoomModel, setShowZoomModel] = useState(false)
    // file uploading states
    const MAX_COUNT = 10;
    const uploadInputRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileErr, setFileErr] = useState("")
    const [copyBodyData, setCopyBodyData] = useState(false);
    const [blankBody, setBlankBody] = useState(false)
    const [ccEmail, setCCEmail] = useState([""])
    const [showInterviewSchedule, setShowInterviewSchedule] = useState(false)
    const [selectedTimezone, setSelectedTimezone] = useState({})
    const [dateAndTime, setDateAndTime] = useState("")
    const [selectInterviewer, setSelectInterviewer] = useState("")

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length > MAX_COUNT) {
                    setErrStatus(true)
                    setFileErr(`You can only add a maximum of ${MAX_COUNT} files`)
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)
    }

    const onFileChange = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        handleUploadFiles(chosenFiles);
    }

    function deleteFile(e) {
        const s = uploadedFiles.filter((item, index) => index !== e);
        setUploadedFiles(s);
    }

    if (copyBodyData === false && create_zoom_meeting && create_zoom_meeting.message) {
        setCopyBodyData(true)
        setBodyData("")
        if (create_zoom_meeting.status === 200) {
            if (create_zoom_meeting && create_zoom_meeting.data) {
                setBlankBody(true)
                let body_data = bodyData + "<br/>" + create_zoom_meeting.data.html_zoom_details
                setBodyData(body_data)
            }
        } else {
            toast.error(create_zoom_meeting.message)
        }
    }

    useEffect(() => {
        dispatch(getCategoryListEmail(project_id));
        dispatch(resourceListOption())
    }, [])

    const handleClose = () => {
        setOpen(false);
        setFormValues(initialFormData);
        setBlankBody(false)
        setBodyData("")
        // setSubjectData("")
        setShowEmailModel(false)
        let ID = '--1';
        dispatch(createZoomMeeting({ ID }))
    }

    if (reload === "submit" && send_email && send_email.message) {
        setReload(false)
        if (send_email.status === 200) {
            toast.success(send_email.message)
            handleClose()
        } else {
            toast.error(send_email.message)
        }
        setShowSubmitBtn(true)
    }

    // const replaceBodyFunction = (body, array) => {
    //     let replacedBodyData = body;
    //     array.forEach((ele) => {
    //         replacedBodyData = replacedBodyData.replaceAll(ele.name, ele.var_name)
    //     })
    //     dispatch(updateBodyData(replacedBodyData))

    //     setBodyData((prevState) => {

    //         return replacedBodyData;
    //     });
    //     // setBodyData(replacedBodyData)
    //     setReload(false)
    // }

    // const categoryChange = (evt) => {
    //     if (evt) {
    //         const { value } = evt.target || evt;
    //         setCategoryId(value)
    //         dispatch(getEmailTemplateMaster(value))
    //     } else {
    //         setCategoryId(0)
    //         setEmailTemplatesId(0)
    //         setFormValues(initialFormData);
    //         dispatch(updateBodyData(""))
    //         dispatch(updateSubject(""))
    //     }
    // }

    // const changeEmailTempalte = (evt) => {
    //     setReload(true)
    //     if (evt) {
    //         const { value } = evt.target || evt;
    //         setEmailTemplatesId(value)
    //         const filterEmailTemplate = emailTemplateMaster && emailTemplateMaster.data && emailTemplateMaster.data.rows.filter((ele) => ele.id === value)
    //         const candidate_name = data.candidate_info.map((ele) => {
    //             return (ele.candidate_name)
    //         })

    //         replaceBodyFunction(filterEmailTemplate[0].body, [
    //             { name: '##CANDIDATE_NAME##', var_name: candidate_name[0] },
    //             { name: '##JD_TITLE##', var_name: filterEmailTemplate[0].subject },
    //             { name: '##RESOURCE_NAME##', var_name: loginUserName },
    //             { name: '##COMPANY_NAME##', var_name: REACT_APP_COMPANY_NAME },
    //             { name: '##COMPANY_HR_MAIL##', var_name: REACT_APP_COMPANY_HR_MAIL },
    //             { name: '##INETRVIEW_DATE##', var_name: "INETRVIEW_DATE", },
    //             { name: '##INETRVIEW_TIME##', var_name: "INETRVIEW_TIME", },
    //             { name: '##INETRVIEW_ZOOM_LINK##', var_name: "INETRVIEW_ZOOM_LINK" }
    //         ])
    //         dispatch(updateSubject(filterEmailTemplate[0].subject))
    //     } else {
    //         setEmailTemplatesId(0)
    //         setFormValues(initialFormData);
    //         dispatch(updateBodyData(""))
    //         dispatch(updateSubject(""))
    //     }
    // }

    const inputHandler = (evt) => {
        var newContent = evt.editor.getData();
        setBodyData(newContent)
    }
    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === "to") {
            var array = value.split(",");
            setFormValues({ ...formValues, [name]: array });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (formValues.to === "" || formValues.to[0] === "" || bodyData === "" || formValues.subject === "") {
            setErrStatus(true)
        }
        const candidate_id = data && data.candidate_info && data.candidate_info.length > 0 && data.candidate_info.map((ele) => {
            return (ele.id)
        })
        if (formValues.to !== "" && formValues.subject !== "" && bodyData !== "") {
            let addvalues = { ...formValues, body: bodyData, cc: ccEmail }
            if (showInterviewSchedule === true) {
                let date = moment(new Date(dateAndTime)).format('YYYY-MM-DD')
                let time = moment(new Date(dateAndTime)).format('HH:mm A')
                addvalues['candidate_id'] = candidate_id[0]
                addvalues['interviewer_id'] = selectInterviewer
                addvalues['is_interview_schedule'] = true
                addvalues['date'] = date
                addvalues['time'] = time
                addvalues['time_stemp'] = selectedTimezone
            }

            const formData = new FormData();
            uploadedFiles.forEach((file, i) => {
                formData.append(`file`, file, file.name);
            });

            formData.append("data", JSON.stringify(addvalues));
            setErrStatus(false)
            setValidateErr("")
            setShowSubmitBtn(false)
            dispatch(sendEmail(formData))
            setReload("submit")
        }
    }

    if (reload === "submit" && status === STATUSES.FAILURE && error && error.message) {
        setReload(false)
        toast.error(error.message);
        setShowSubmitBtn(true)
    }
    const handleChangeOnCCEmail = (evt) => {
        let mailCCArray = []
        if (evt.length > 0) {
            evt.map(e => {
                return mailCCArray.push(e.value)
            })
            setCCEmail(mailCCArray)
        } else {
            setCCEmail([""])
        }
    }

    const handleChangeOnInterviwer = (evt) => {
        let interviewArr = []
        if (evt.length > 0) {
            evt.map(e => {
                return interviewArr.push(e.value)
            })
            setSelectInterviewer(interviewArr)
        } else {
            setSelectInterviewer("")
        }
    }

    const handleCheckbox = (evt) => {
        let { name, type, value, checked } = evt.target || evt;
        value = type === "checkbox" ? checked : value;
        if (name === 'is_interview_schedule' && checked === true) {
            setShowInterviewSchedule(true)
        } else {
            setShowInterviewSchedule(false)
        }
    }

    const updateDateTime = (evt) => {
        setDateAndTime(evt)
    }

    return (
        <div>
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{
                    '& .MuiDialog-paper': { zIndex: "0" }
                }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>Mail ({data && data.summary})

                    <Tooltip title="Create Zoom Meeting">
                        <button onClick={() => setShowZoomModel(true)} className="btn-add-zoom">Create Zoom Meeting</button>
                    </Tooltip>
                    {showZoomModel && <ZoomCreate title={data && data.summary} setShowZoomModel={setShowZoomModel} story_id={story_id} />
                    }

                </DialogTitle>
                {(reload === "submit") && <LoaderForm />}
                <DialogContent>
                    <form>
                        <div className="add-new-user-form">

                            {/* <div className="formInput">
                                <label >
                                    Category
                                </label>
                                <Select
                                    className="task-dropdown"
                                    classNamePrefix="select"
                                    placeholder="Select Category"
                                    isSearchable={true}
                                    isClearable
                                    options={
                                        categoryListEmail && categoryListEmail.data && categoryListEmail.data.template_masters.map((option) => {
                                            return { label: option.template_type_name, value: option.id, name: "category" }
                                        })
                                    }
                                    onChange={categoryChange}
                                />
                            </div> */}
                            {/* {
                                categoryId > 0 &&
                                <div className="formInput">
                                    <label >
                                        Email Template
                                    </label>
                                    <Select
                                        className="task-dropdown"
                                        classNamePrefix="select"
                                        placeholder="Select Template"
                                        isSearchable={true}
                                        isClearable
                                        options={
                                            emailTemplateMaster && emailTemplateMaster.data && emailTemplateMaster.data.rows.map((option) => {
                                                return { label: option.template_key, value: option.id, name: "email_templates" }
                                            })
                                        }
                                        onChange={changeEmailTempalte}
                                    />
                                </div>
                            } */}

                        </div>
                        {/* {
                            emailTemplatesId > 0 &&
                            <> */}
                        <div className="add-new-user-form">
                            <div className="formInput">
                                <label >
                                    To
                                </label>
                                <input
                                    name="to"
                                    defaultValue={to_email}
                                    type="text"
                                    placeholder="To"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && (formValues.to === "" || formValues.to[0] === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.toErr}</p>}
                                {errStatus === true && validateErr && validateErr.to !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.to}</p>}

                            </div>
                            <div className="formInput">
                                <label >
                                    CC
                                </label>
                                <CreatableSelect
                                    className="select"
                                    classNamePrefix="select"
                                    placeholder="Select CC"
                                    isSearchable={true}
                                    isClearable
                                    isMulti
                                    name="cc"
                                    defaultValue={formValues.cc}
                                    options={
                                        resources && resources.length && resources.map((option, i) => {
                                            return ({ label: option.email, value: option.email, name: "cc", key: i })
                                        })
                                    }
                                    onChange={handleChangeOnCCEmail}
                                />
                            </div>
                        </div>
                        <div className="add-new-user-form">
                            <div className="formInput" style={{ flexBasis: "100%" }}>
                                <label >
                                    Subject
                                </label>
                                <input
                                    name="subject"
                                    defaultValue={formValues.subject}
                                    type="text"
                                    placeholder="Subject"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && formValues.subject === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.subjectErr}</p>}
                            </div>
                        </div>
                        <div className="add-new-user-form">
                            <div className="formInputDescription">
                                <label >
                                    Body
                                </label>
                                {
                                    (bodyData && blankBody === true) &&
                                    <CKEditor
                                        name={"body"}
                                        initData={bodyData}
                                        onChange={(e) => inputHandler(e)}
                                        config={LocalStorageDataService.ckEditor_config_toolbar()}
                                    />
                                }
                                {
                                    (blankBody === false || !bodyData) &&
                                    <CKEditor
                                        name={"body"}
                                        onChange={(e) => inputHandler(e)}
                                        config={LocalStorageDataService.ckEditor_config_toolbar()}
                                    />
                                }
                                {errStatus === true && formValues && bodyData === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.bodyErr}</p>}
                            </div>
                            <div className='add-new-user-form'>
                                <div className="uploaded-files-list">
                                    {uploadedFiles.length > 0 && uploadedFiles.map((file, i) => {
                                        return <div key={i}>
                                            <ul>
                                                <li>
                                                    {file.name}
                                                    <button onClick={() => deleteFile(i)}>
                                                        <Close />
                                                    </button>
                                                </li>
                                            </ul>

                                        </div>
                                    })
                                    }
                                </div>

                                <div className="file-attchment-icon-btn">
                                    <input
                                        id='fileUpload'
                                        ref={uploadInputRef}
                                        type='file'
                                        multiple
                                        accept='application/pdf, image/png,image/jpeg,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                        style={{ display: "none" }}
                                        onChange={onFileChange}
                                        name="file"
                                    />

                                    <Tooltip title="Attach File">
                                        <span>
                                            <AttachFile
                                                className={`attach_con`}
                                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                                {errStatus === true && fileErr && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                            </div>
                        </div>

                        {/* Schedule Interview section start */}

                        {/* <div className='add-new-user-form'>
                            <div className="formInputCheckBox">
                                <Checkbox
                                    className="checkbox"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    name={"is_interview_schedule"}
                                    defaultChecked={formValues.is_interview_schedule}
                                    onChange={handleCheckbox}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                />
                                <label>
                                    Schedule Interview
                                    <b style={{ fontSize: "11px" }}>(Check the checkbox,if you want to schedule Interview) </b>
                                </label>
                            </div>
                        </div> */}
                        {
                            showInterviewSchedule === true ?
                                <div className='' style={{ height: "210px" }}>
                                    <div className='add-new-user-form'>
                                        <h4> Schedule Interview</h4>
                                    </div>
                                    <div className='add-new-user-form row'>
                                        <div className='col' style={{ width: '33.33%' }}>
                                            <div className="formInput">
                                                <label>Select Interviwer</label>
                                                <CreatableSelect
                                                    className="select"
                                                    classNamePrefix="select"
                                                    placeholder="Select Interviwer"
                                                    isSearchable={true}
                                                    isClearable
                                                    isMulti
                                                    name="interviewer_id"
                                                    defaultValue={selectInterviewer}
                                                    options={
                                                        resources && resources.length && resources.map((option, i) => {
                                                            return ({ label: `${option.name} (${option.email})`, value: option.id, name: "interviewer_id", key: i })
                                                        })
                                                    }
                                                    onChange={handleChangeOnInterviwer}
                                                />
                                            </div>

                                        </div>
                                        <div className='col' style={{ width: '33.33%' }}>
                                            <div className="datepicker-input-custom">
                                                <label>Date and Time</label><br />
                                                <FormControl>
                                                    <DatePicker
                                                        className='select'
                                                        format="dd-MM-yyyy HH:mm"
                                                        showMeridian
                                                        // value={dateAndTime}
                                                        onChange={(evt) => updateDateTime(evt)}
                                                        onClean={(evt) => { updateDateTime([]) }}
                                                        disabledDate={date => isBefore(date, new Date())}
                                                        placement="topStart"
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='col' style={{ width: '33.33%' }}>
                                            <div className="formInput">
                                                <label>Time Zone</label>
                                                <TimezoneSelect
                                                    value={selectedTimezone}
                                                    onChange={setSelectedTimezone}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div> : ""
                        }
                        {
                            showSubmitBtn && <DialogActions>
                                <button className="submit-modal-email" onClick={onSubmit}>Send</button>
                            </DialogActions>
                        }
                        {/* </>
                        } */}
                    </form>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default EmailTemplet