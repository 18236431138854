import * as React from 'react';
import { ArrowUpward } from '@mui/icons-material'
import TableCell from '@mui/material/TableCell';
import MoreVertIcon from '@mui/icons-material/MoreVert';


export const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
}

export const CustomTableHeader = ({ headerColumn }) => {
    return headerColumn.map((item, index) =>
        <TableCell style={item.style} key={generateKey(index)}>
            {item.label}
            {item.label === 'Date' && item.action && item.showIcon === true && <ArrowUpward onClick={() => item.action('arrow')} style={{ fontSize: "14px", cursor: "pointer" }} />}
            {item.action && item.showIcon === true && <MoreVertIcon onClick={() => item.action(item.key)} style={{ float: "right", fontSize: "20px", cursor: "pointer" }} />}
        </TableCell>

    )
}


export const CustomTableBody = ({ headerBody }) => {
    return headerBody.map((item, index) =>
        <TableCell onClick={() => item.action && item.action(item.key, item.details)} style={item.style} key={index} >{item.label}</TableCell>

    )
}